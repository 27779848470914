// Search
.search {
    height: $header_height;
    position: relative;
    float: left;
    background: $search_header-background;
    color: $color-white;

    @media #{$xs-min} {
        width: 100%;
    }

    @media #{$sm-min} {
        width: $search_header-width;
    }
}

.search__field {
    $_this: &;
    width: calc(100% - #{$header_height});
    height: $header_height;
    padding: 0 10px;
    float: left;
    font-size: em(12px);
    line-height: 40px;
    font-weight: bold;
    text-transform: uppercase;

    &,
    &::placeholder {
        color: $color-white;
    }
}

.search__button {
    width: $header_height;
    height: $header_height;
    float: left;

    .search__button-icon {
        font-size: 20px;
    }
}
