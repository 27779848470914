// Headings
.heading__2 {
    width: 100%;
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 1em;

    .content-container & {
        color: $content_color-heading-2;
    }
}

.heading__3 {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;

    .content-container &,
    .newsletter__container & {
        color: $content_color-heading-3;
    }

    .-section & {
        margin-bottom: vr(.5);
    }

    .footer & {
        color: $footer_color-heading;
    }
}

.heading__4 {
    width: 100%;
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;

    .-section & {
        margin-bottom: vr(.2);
    }
}

.heading__5,
.heading__6 {
    @extend .heading__4;
}
