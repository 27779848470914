// General
body,
html {
    height: auto;
}

body {
    @include sans-serif('Open Sans');
    overflow-y: scroll;
    background-color: $base_background;
    color: $base_color;
    font-size: $base_font-size;
    line-height: $base_line-height;
    –webkit-overflow-scrolling: touch;

    @each $key, $value in $grid {
        $min-width: map-get($value, breakpoint) + (2 * $grid_gutter);

        @media (min-width: $min-width) {

            &::after {
                display: none;
                content: '#{$key}-device';
            }
        }
    }
}

@include placeholder($color-gray);

a {
    color: $base_color;
    text-decoration: none;
    border-bottom: 1px solid;

    &:focus,
    &:hover {
        cursor: pointer;
        border-bottom: none;
    }
}

.content-container {

    @media #{$xs-min} {

        &.-section {
            padding: ($section_indentation / 6);

            .page__nas-tym & {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    @media #{$sm-min} {

        &.-section {
            padding: ($section_indentation / 4) ($section_indentation / 2);
        }
    }

    @media #{$md-min} {

        &.-section {
            padding: ($section_indentation / 4) $section_indentation;
        }
    }

    @media #{$lg-min} {

        &.-section {
            padding: ($section_indentation / 2) $section_indentation;
        }
    }

    @media #{$xs-max} {

        &.-mobile-fullwidth,
        &.-xs-fullwidth {
            width: 97%;
        }
    }

    @media #{$sm-max} {

        &.-mobile-fullwidth,
        &.-sm-fullwidth {
            width: 96%;
        }
    }

    &.-filter {
        transition: padding $base_transition-duration-in $base_transition-timing;
    }

    + .newsletter__container {
        padding-top: 20px;
    }
}

.content {
    width: 100%;
    position: relative;
    z-index: $zindex_content;
    overflow: hidden;
    background-color: $color-white;
}

.sticky {
    position: sticky;
    z-index: $zindex_sticky;

    &::before,
    &::after {
        display: table;
        content: '';
    }
}
