// Google maps
.map {
    @extend %loader-image !optional;
    height: 500px;
    background-color: #faf8f9;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10%;
    background-clip: content-box;
    border: 3px solid $color-light-gray;
}
