// Collapse content
.collapse-container {
    position: relative;

    &.-active {
        height: $header_height;
        overflow: hidden;

        .collapse__content {
            visibility: hidden;
            opacity: 0;
        }
    }

    .collapse__content {
        visibility: visible;
        opacity: 1;
    }
}

.collapse__trigger {
    width: $button_height;
    height: $button_height;
    position: absolute;
    right: 20px;

    .-active & {
        width: 90px;
        height: auto;
    }

    .filter__content & {
        width: 100%;
        max-width: 280px;
        padding-right: $form_input-height + 20px;
        right: auto;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.collapse__button {
    @extend %image_arrow-bottom-#{$collapse_color-arrow};
    @include hide-text;
    width: $button_height;
    height: $button_height;
    position: absolute;
    top: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
    transition: background-color $base_transition-duration-in $base_transition-timing, transform $base_transition-duration-in $base_transition-timing;
    border-radius: 50%;

    .-active & {
        transform: rotate(0);
        transition: transform $base_transition-duration-in $base_transition-timing;
    }

    &:focus,
    &:hover {
        background-color: $color-light-gray;
        transition: background-color $base_transition-duration-in $base_transition-timing;

        .navigation__content & {background-color: $color-white;}
    }
}

.collapse__text {
    display: none;
    font-size: em(14px);
    line-height: $button_height;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;

    .-active & {

        &,
        &.-closed {
            display: block;
        }

        &.-opened {
            display: none;
        }
    }

    &.-opened {
        display: block;
    }

    &.-closed {
        display: none;
    }
}
