// Forms
//
//      file: components/forms.scss
//
// Form styles
//
// **`Rounded`** variant extends **`bordered`**, so you don't nedd use **`-bordered -rounded`** classes at the same time.
//
// Markup:
// <form>
//  <fieldset>
//     <legend>Name and surname:</legend>
//     <div class="row">
//         <label>Name</label>
//         <input type="text" class="form__item" placeholder="Name" />
//     </div>
//     <div class="row">
//         <label>Surname</label>
//         <input type="text" class="form__item" placeholder="Surname" />
//     </div>
//  </fieldset>
//  <fieldset>
//     <legend>Street and city:</legend>
//     <div class="row">
//         <label>Street</label>
//         <input type="text" class="form__item" placeholder="Street" />
//     </div>
//     <div class="row">
//         <label>City</label>
//         <input type="text" class="form__item" placeholder="City" />
//     </div>
//  </fieldset>
// </form>
//
// styleguide 4.2

// Form grid
//
// Markup:
// <form>
//  <fieldset>
//     <legend>Name and surname:</legend>
//     <div class="row">
//         <label class="col-xs-12 col-sm-5 col-md-7">Name</label>
//         <input type="text" class="col-xs-12 col-sm-7 col-md-5 form__item" placeholder="Name" />
//     </div>
//     <div class="row">
//         <label class="col-xs-12 col-md-3">Middlename</label>
//         <input type="text" class="col-xs-12 col-md-3 form__item" placeholder="Middlename" />
//         <label class="col-xs-12 col-md-2 col-md-offset-1">Surname</label>
//         <input type="text" class="col-xs-12 col-md-3 form__item" placeholder="Surname" />
//     </div>
//  </fieldset>
//  <fieldset>
//     <legend>Street and city:</legend>
//     <div class="row">
//         <label class="col-xs-6 col-md-3">Street</label>
//         <input type="text" class="col-xs-6 col-md-3 form__item" placeholder="Street" />
//         <label class="col-xs-6 col-md-3">City</label>
//         <input type="text" class="col-xs-6 col-md-3 form__item" placeholder="City" />
//     </div>
//  </fieldset>
// </form>
//
// styleguide 4.2.1

// Form inline
//
// Markup:
// <form class="form__inline">
//  <fieldset>
//     <legend>Name and surname:</legend>
//     <div class="row">
//         <label>Name</label>
//         <input type="text" class="form__item" placeholder="Name" />
//     </div>
//     <div class="row">
//         <label>Middlename</label>
//         <input type="text" class="form__item" placeholder="Middlename" />
//         <label>Surname</label>
//         <input type="text" class="form__item" placeholder="Surname" />
//     </div>
//  </fieldset>
//  <fieldset>
//     <legend>Street and city:</legend>
//     <div class="row">
//         <label>Street</label>
//         <input type="text" class="form__item" placeholder="Street" />
//         <label>City</label>
//         <input type="text" class="form__item" placeholder="City" />
//         <label>Street</label>
//         <input type="text" class="form__item" placeholder="Street" />
//         <label>Phone</label>
//         <input type="text" class="form__item" placeholder="Phone" />
//         <label>E-mail</label>
//         <input type="text" class="form__item" placeholder="E-mail" />
//     </div>
//  </fieldset>
// </form>
//
// styleguide 4.2.2

// Form variants
//
// .-bordered   - bordered form variant
// .-hovered    - hovered form row variant
// .-rounded    - rounded form variant
//
// Markup:
// <form class="{{modifier_class}}">
//  <div class="row">
//     <label class="col-xs-3">Input</label>
//     <div class="col-xs-9">
//         <input type="text" class="form__item" />
//     </div>
//  </div>
//  <div class="row">
//     <label class="col-xs-3">Select</label>
//     <div class="col-xs-9">
//         <select class="form__item {{modifier_class}}">
//             <option value="brisket">brisket</option>
//             <option value="alcatra">alcatra</option>
//             <option value="tongue">tongue</option>
//         </select>
//     </div>
//  </div>
//  <div class="row">
//     <label class="col-xs-3">Textarea</label>
//     <div class="col-xs-9">
//         <textarea class="form__item {{modifier_class}}">Brisket alcatra tongue pig beef ribs sausage hamburger cupim pancetta beef boudin. Corned beef ribeye drumstick pork loin, ground round hamburger leberkas porchetta meatloaf meatball.</textarea>
//     </div>
//  </div>
// </form>
//
// styleguide 4.2.3

// Form items variants
//
// Variants are defined in base variable: `$base_variants` (defaults are: `success`, `info`, `warning` and `danger`)
//
// .-success   - success style variant ($color-green)
// .-info      - info style variant ($color-blue)
// .-warning   - warning style variant ($color-yellow)
// .-danger    - danger style variant ($color-red)
// .-valid     - valid style variant on focus state ($color-blue)
// .-required  - required style variant on focus state ($color-red)
//
// Markup:
// <form>
//  <div class="row">
//     <label class="col-xs-3">Input</label>
//     <div class="col-xs-9">
//         <input type="text" class="form__item {{modifier_class}}" />
//     </div>
//  </div>
//  <div class="row">
//     <label class="col-xs-3">Select</label>
//     <div class="col-xs-9">
//         <select class="form__item {{modifier_class}}">
//             <option value="brisket">brisket</option>
//             <option value="alcatra">alcatra</option>
//             <option value="tongue">tongue</option>
//         </select>
//     </div>
//  </div>
//  <div class="row">
//     <label class="col-xs-3">Textarea</label>
//     <div class="col-xs-9">
//         <textarea class="form__item {{modifier_class}}">Brisket alcatra tongue pig beef ribs sausage hamburger cupim pancetta beef boudin. Corned beef ribeye drumstick pork loin, ground round hamburger leberkas porchetta meatloaf meatball.</textarea>
//     </div>
//  </div>
// </form>
//
// styleguide 4.2.4

// Disabled form items
//
// Markup:
// <form>
//  <div class="row">
//     <label class="col-xs-3">Input</label>
//     <div class="col-xs-9">
//         <input type="text" class="form__item" disabled />
//     </div>
//  </div>
//  <div class="row">
//     <label class="col-xs-3">Select</label>
//     <div class="col-xs-9">
//         <select class="form__item" disabled>
//             <option value="brisket">brisket</option>
//             <option value="alcatra">alcatra</option>
//             <option value="tongue">tongue</option>
//         </select>
//     </div>
//  </div>
//  <div class="row">
//     <label class="col-xs-3">Textarea</label>
//     <div class="col-xs-9">
//         <textarea class="form__item" disabled>Brisket alcatra tongue pig beef ribs sausage hamburger cupim pancetta beef boudin. Corned beef ribeye drumstick pork loin, ground round hamburger leberkas porchetta meatloaf meatball.</textarea>
//     </div>
//  </div>
// </form>
//
// styleguide 4.2.5

// Readonly form items
//
// Markup:
// <form>
//  <div class="row">
//     <label class="col-xs-3">Input</label>
//     <div class="col-xs-9">
//         <input type="text" class="form__item" readonly />
//     </div>
//  </div>
//  <div class="row">
//     <label class="col-xs-3">Select</label>
//     <div class="col-xs-9">
//         <select class="form__item" readonly>
//             <option value="brisket">brisket</option>
//             <option value="alcatra">alcatra</option>
//             <option value="tongue">tongue</option>
//         </select>
//     </div>
//  </div>
//  <div class="row">
//     <label class="col-xs-3">Textarea</label>
//     <div class="col-xs-9">
//         <textarea class="form__item" readonly>Brisket alcatra tongue pig beef ribs sausage hamburger cupim pancetta beef boudin. Corned beef ribeye drumstick pork loin, ground round hamburger leberkas porchetta meatloaf meatball.</textarea>
//     </div>
//  </div>
// </form>
//
// styleguide 4.2.6

// Form description
//
// Markup:
// <form>
//  <div class="row">
//     <label class="col-xs-3">Input <span class="form__description">form description</span></label>
//     <div class="col-xs-9">
//         <input type="text" class="form__item" />
//         <div class="form__description">
//              <strong>Form description</strong><br />
//              Brisket alcatra tongue pig beef ribs sausage hamburger cupim pancetta beef boudin. Corned beef ribeye drumstick pork loin, ground round hamburger leberkas porchetta meatloaf meatball.
//         </div>
//     </div>
//  </div>
// </form>
//
// styleguide 4.2.7

// Default variables
$form_background: $base_background !default;
$form_background-hover: darken($color_white, 5%) !default;
$form_fieldset-padding-vertical: floor($grid_gutter / 2) !default;
$form_fieldset-padding-horizontal: floor($grid_gutter / 2) !default;
$form_input-height: if(unitless($base_line-height), ($base_font-size * $base_line-height * 2), ($base_line-height * 2)) !default;
$form_input-padding-vertical: ($form_input-height / 4) !default;
$form_input-padding-horizontal: floor($grid_gutter / 2) !default;
$form_input-background: $base_background !default;
$form_input-background-hover: darken($color_white, 5%) !default;
$form_input-background-disabled: $color-black-20 !default;
$form_input-background-disabled-hover: $form_input-background-disabled !default;
$form_input-color: $base_color !default;
$form_input-color-hover: darken($base_color,4%) !default;
$form_input-color-disabled: $color_black-50 !default;
$form_input-color-disabled-hover: $form_input-color-disabled !default;
$form_select-multiple-min-height: if(unitless($base_line-height), ($base_font-size * $base_line-height * 10), ($base_line-height * 10)) !default;
$form_textarea-min-height: if(unitless($base_line-height), ($base_font-size * $base_line-height * 10), ($base_line-height * 10)) !default;
$form_border-color: $color-black-10 !default;
$form_border-color-focus: darken($form_border-color,4%) !default;
$form_border-color-valid: $color-blue !default;
$form_border-color-required: $color-red !default;
$form_label-color: $base_color !default;
$form_fieldset-border-color: darken($form_border-color,8%) !default;
$form_legend-background: $form_background !default;
$form_legend-color: $form_input-color !default;
$form_legend-hover-color: $form_input-color-hover !default;
$form_description-color: $color_black_70 !default;

@mixin form() {

    @if $form_trigger == true {

        form {
            @include clearfix;
            width: 100%;
            background-color: $form_background;

            &.-bordered {
                padding: floor($grid_gutter / 2);
                border: 1px solid $form_border-color;
            }

            &.-hovered {
                padding-right: floor($grid_gutter / 2);
                padding-left: floor($grid_gutter / 2);

                .#{$grid_row-name},
                .form__row {
                    padding-top: floor($grid_gutter / 2);
                    padding-bottom: floor($grid_gutter / 2);

                    &:hover {
                        background-color: $form_background-hover;
                    }
                }
            }

            &.-rounded {
                @extend .-bordered;
                border-radius: $base_border-radius;
            }

            .row {padding: floor($grid_gutter / 2);}
        }

        fieldset {
            @include clearfix;
            padding: $form_fieldset-padding-vertical $form_fieldset-padding-horizontal;
            border: 1px solid $form_fieldset-border-color;

            @if $base_border-radius > 0 {border-radius: $base_border-radius;}

            + fieldset {margin-top: ($grid_gutter / 2);}
        }

        legend {
            padding: 0 $form_fieldset-padding-horizontal;
            background-color: $form_legend-background;
            color: $form_legend-color;
            font-size: $base_font-size * 1.25;
            line-height: 1;
        }

        label {
            max-width: 100%;
            display: inline-block;
            color: $form_label-color;
            line-height: $form_input-height;
            font-weight: bold;
        }

        select {

            &:hover {cursor: pointer;}

            &[multiple] {min-height: $form_select-multiple-min-height;}

            &.form__item {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        textarea {
            min-height: $form_textarea-min-height;
            padding: $form_input-padding-vertical $form_input-padding-horizontal;
            overflow: hidden;
        }

        .form__item {
            @include _form-variant($form_input-background,$form_input-background-hover,$form_input-color,$form_input-color-hover);
            width: 100%;
            height: $form_input-height;
            margin: 0;
            padding: $form_input-padding-vertical $form_input-padding-horizontal;
            display: block;
            font-size: $base_font-size;
            line-height: $base_line-height;
            transition: border $base_transition-duration-out $base_transition-timing;
            border: 1px solid $form_border-color;
            @if $base_border-radius > 0 {border-radius: $base_border-radius;}

            &:focus {
                transition: border $base_transition-duration-in $base_transition-timing;
                border-color: $form_border-color-focus;
            }

            &.-valid:focus {border-color: $form_border-color-valid;}

            &.-required:focus {border-color: $form_border-color-required;}

            &[disabled] {
                @include _form-variant($form_input-background-disabled,$form_input-background-disabled-hover,$form_input-color-disabled,$form_input-color-disabled-hover);
                cursor: not-allowed;
                user-select: none; // IE9+
            }

            &[readonly] {@include _form-variant($form_input-background-disabled,$form_input-background-disabled-hover,$form_input-color,$form_input-color);}

            @each $key, $value in $base_variants {

                &.-#{$key} {@include _form-variant($value,darken($value,8%),darken($value,16%),darken($value,25%));}
            }
        }

        .form__description {
            padding-top: (($form_input-height - $base_line-height) / 2);
            color: $form_description-color;
            font-size: $base_font-size * .85;
        }

        .form__inline {

            &.form__bordered {padding: 0;}

            label {
                padding-right: floor($grid_gutter / 2);
                display: inline-block;
                vertical-align: middle;
            }

            .row {
                display: inline-block;
                vertical-align: middle;
            }

            .form__item {
                width: auto;
                max-width: 100%;
                margin-right: floor($grid_gutter / 2);
                display: inline-block;
            }
        }
    }
}

@mixin _form-variant(
    $background,
    $background-hover,
    $color,
    $color-hover
) {
    $error-message: 'Arguments are required, it needs to be a colors.';

    @if type-of($background) == color and type-of($background-hover) == color and type-of($color) == color and type-of($color-hover) == color {
        background-color: $background;
        color: $color;

        &:focus,
        &:hover,
        &:active,
        &.active {
            background-color: $background-hover;
            color: $color-hover;
        }
    } @else {
        @error #{$error-message};
    }
}

@include form;
