// Header
.header {
    width: 100%;
    z-index: $zindex_header;
}

.header__container {
    width: 100%;
    height: $header_height;
    top: 0;
    background: $section_background;
}

.header__content {
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media #{$sm-max} {
        width: 88%;
    }
}

.header__logo {
    height: $header_height;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: none;

    .navigation__container & {
        float: left;
        visibility: visible;
        opacity: 1;
    }

    @media #{$sm-min} {
        margin: 0 30px;
    }
}

.header__logo-image {
    max-width: 120px;
    max-height: $header_height;
}
