// Whisperer
.whisperer {
    @include viewport-unit(max-height, 75vh);
    width: 100%;
    height: 0;
    position: absolute;
    top: $header_height;
    left: 0;
    overflow-y: auto;
    background: $whisperer_background;

    &.-active {
        height: auto;
    }
}

.whisperer__list {
    list-style-type: none;

    li {

        + li {
            border-top: 1px solid lighten($whisperer_background, 4%);
        }
    }
}

.whisperer__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $whisperer_color;
    font-size: 12px;
    font-weight: bold;
    transition: background $base_transition-duration-in $base_transition-timing;
    border-bottom: none;

    &:focus,
    &:hover {
        background: lighten($whisperer_background, 8%);
        transition: background $base_transition-duration-out $base_transition-timing;
    }
}

.whisperer__image {
    width: $whisperer_item-width;
    height: $whisperer_item-height;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    text-align: center;

    img {
        width: 100%;
        max-width: $whisperer_item-width;
        max-height: $whisperer_item-height;
    }
}

.whisperer__title {
    width: calc(100% - #{$whisperer_item-width});
    padding: 5px;
    display: inline-flex;
}

.whisperer__empty-message {
    height: $whisperer_item-height;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}
