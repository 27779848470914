// Alerts
.alert {
    @include shape-times(2px, 20px, $color-white);
    width: 100%;
    border-radius: 2px;

    form & {
        width: calc(100% - 30px);
        margin-right: 15px;
        margin-left: 15px;
    }

    .alert__close {
        @include hide-text;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        transform: rotate(0);
        transition: transform .3s $base_transition-timing;

        &:focus,
        &:hover {
            transform: rotate(90deg);
            transition: transform .3s $base_transition-timing;
        }
    }
}
