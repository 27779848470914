.elevator {
    position: fixed;
    right: 8px;
    bottom: 8px;
    z-index: $zindex_elevator;
    opacity: 0;
    background-color: $color-red;
    cursor: pointer;
    border-radius: 5px;

    .v-icon {
        color: #f5f5f5;
        font-size: 48px;
    }
}
