// Shapes
//
//      file: miscellaneous/shape.scss
//
// CSS shapes mixins
//
// It's necessary to use in styles `include` mixin
//
// styleguide 6.1

// Shape cross
//
// You can define class name in variables: **`$shape-cross_name`**.
//
// Default class: **`.shape-cross`**
//
// **`$color`**: shape color
//
// **`$width`**: shape line width
//
// **`$size`**: shape size (if the parent element is square, it is good to use percentage size, otherwise fixed unit)
//
// **`use`**: @include shape-cross(5px, 20px, $base_color);
//
// Markup:
// <div class="row">
//  <div class="col-xs-3 col-md-1">
//      <div class="kss-shape-helper">
//          <div class="shape-cross"></div>
//      </div>
//  </div>
// </div>
//
// styleguide 6.1.1

// Default variables
$shape-cross_name: 'shape-cross' !default;
$shape-times_name: 'shape-times' !default;
$shape-triangle_name: 'shape-triangle' !default;

@mixin shape-cross(
    $width,
    $size,
    $color
) {
    $error-message: 'Arguments are required. Mixin -> shape_cross(width !required, size !required, color !required)';

    @if type-of($width) == number
    and type-of($size) == number
    and type-of($color) == color {

        .#{$shape-cross_name} {
            position: relative;

            &::after,
            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                content: '';
                background: $color;
                transform: translate(-50%, -50%);
            }

            &::after {
                width: $width;
                height: $size;
            }

            &::before {
                width: $size;
                height: $width;
            }
        }
    } @else {
        @error #{$error-message};
    }
}

// Shape times
//
// You can define class name in variables: **`$shape-times_name`**.
//
// Default class: **`.shape-times`**
//
// **`$color`**: shape color
//
// **`$width`**: shape line width
//
// **`$size`**: shape size (if the parent element is square, it is good to use percentage size, otherwise fixed unit)
//
// **`use`**: @include shape-times(5px, 20px, $base_color);
//
// Markup:
// <div class="row">
//  <div class="col-xs-3 col-md-1">
//      <div class="kss-shape-helper">
//          <div class="shape-times"></div>
//      </div>
//  </div>
// </div>
//
// styleguide 6.1.2

@mixin shape-times(
    $width,
    $size,
    $color
) {
    $error-message: 'Arguments are required. Mixin -> shape_times(width !required, size !required, color !required)';

    @if type-of($width) == number
    and type-of($size) == number
    and type-of($color) == color {

        .#{$shape-times_name} {
            position: relative;

            &::after,
            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                content: '';
                background: $color;
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &::after {
                width: $width;
                height: $size;
            }

            &::before {
                width: $size;
                height: $width;
            }
        }
    } @else {
        @error #{$error-message};
    }
}

// Shape triangle
//
// You can define class name in variables: **`$shape-triangle_name`**.
//
// Default class: **`.shape-triangle`**
//
// **`$direction`**: shape direction (allowed values: up | right | down | left)
//
// **`$color`**: shape color
//
// **`$size`**: shape size
//
// **`use`**: @include shape-triangle(up, 20px, $base_color);
//
// **`use`**: @include shape-triangle(right, 20px, $base_color);
//
// **`use`**: @include shape-triangle(down, 20px, $base_color);
//
// **`use`**: @include shape-triangle(left, 20px, $base_color);
//
// Markup:
// <div class="row">
//  <div class="col-xs-3 col-md-1">
//      <div class="kss-shape-helper">
//          <div class="shape-triangle -up"></div>
//      </div>
//  </div>
//  <div class="col-xs-3 col-md-1">
//      <div class="kss-shape-helper">
//          <div class="shape-triangle -right"></div>
//      </div>
//  </div>
//  <div class="col-xs-3 col-md-1">
//      <div class="kss-shape-helper">
//          <div class="shape-triangle -down"></div>
//      </div>
//  </div>
//  <div class="col-xs-3 col-md-1">
//      <div class="kss-shape-helper">
//          <div class="shape-triangle -left"></div>
//      </div>
//  </div>
// </div>
//
// styleguide 6.1.3

@mixin shape-triangle(
    $direction,
    $size,
    $color
) {
    $error-message: 'Arguments are required, possible direction values are: up|right|down|left. Mixin -> shape_triangle(direction !required, size !required, color !required)';

    @if type-of($direction) == string
    and type-of($size) == number
    and type-of($color) == color {

        .#{$shape-triangle_name} {
            width: 0;
            height: 0;

            &.-#{$direction} {
                @if ($direction == 'up') {
                    border-right: ($size / 2) solid transparent;
                    border-bottom: $size solid $color;
                    border-left: ($size / 2) solid transparent;
                } @else if ($direction == 'right') {
                    border-top: ($size / 2) solid transparent;
                    border-bottom: ($size / 2) solid transparent;
                    border-left: $size solid $color;
                } @else if ($direction == 'down') {
                    border-top: $size solid $color;
                    border-right: ($size / 2) solid transparent;
                    border-left: ($size / 2) solid transparent;
                } @else if ($direction == 'left') {
                    border-top: ($size / 2) solid transparent;
                    border-right: $size solid $color;
                    border-bottom: ($size / 2) solid transparent;
                }
            }
        }
    } @else {
        @error #{$error-message};
    }
}
