// Keyframes
@keyframes load {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// Catalog
@keyframes catalog {
    0% {
        opacity: 0;
        transform: translateY(10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes catalog-pulse {
    0% {
        opacity: 0;
    }

    33% {
        opacity: 1;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// Modal
@keyframes modal-content-fade-in {
    0% {
        transform: translate(-50%, -200px);
    }

    100% {
        transform: translate(-50%, 0);
    }
}

@keyframes modal-fade-in {
    0% {
        display: none;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

// Wallop 3rd party plugin
@keyframes fadeOut {
    100% {
        visibility: hidden;
        opacity: 0;
    }
}
