@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
form .required,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
button,
input,
select,
textarea,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  background: transparent;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  outline: 0;
  border: none; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

body {
  line-height: 1; }

html {
  text-size-adjust: 100%; }

ol,
ul {
  list-style: none;
  list-style-image: none; }

* {
  box-sizing: border-box; }

*::before,
*::after {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

body {
  overflow-y: scroll; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent;
  text-decoration: underline; }
  a:focus {
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    outline: 0; }
  a:active {
    outline: 0; }

img {
  -ms-interpolation-mode: bicubic; }

audio:not([controls]) {
  height: 0;
  display: none; }

svg:not(:root) {
  overflow: hidden; }

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit; }

button,
input,
select,
textarea {
  vertical-align: middle;
  border: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  appearance: button; }

button[disabled],
input[disabled] {
  cursor: default; }

input[readonly] {
  cursor: not-allowed; }

input[type="search"] {
  appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="submit"] {
  appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }
  table th {
    text-align: left; }

strong {
  font-weight: bold; }

sup, form .required {
  vertical-align: super;
  font-size: 75%; }

sub {
  vertical-align: sub;
  font-size: 75%; }

._clearfix::before, ._clearfix::after {
  display: table;
  content: ''; }

._clearfix::after {
  clear: both; }

._hide {
  display: none !important; }

._show {
  display: block !important; }

._flex {
  display: flex !important; }

._in {
  display: inline !important; }

._inb {
  display: inline-block !important; }

._vis {
  visibility: visible !important; }

._invis {
  visibility: hidden !important; }

._fl {
  float: left !important; }

._fr {
  float: right !important; }

._fn {
  float: none !important; }

._cl {
  clear: left !important; }

._cr {
  clear: right !important; }

._cb {
  clear: both !important; }

._cn {
  clear: none !important; }

._al {
  text-align: left !important; }

._ar {
  text-align: right !important; }

._ac {
  text-align: center !important; }

._aj {
  text-align: justify !important; }

._color-green, .color-green {
  color: #1aad12 !important; }

._color-blue, .color-blue {
  color: #14467e !important; }

._color-yellow, .color-yellow {
  color: #ffc800 !important; }

._color-red, .color-red {
  color: #d4161c !important; }

._color-white, .color-white {
  color: #fff !important; }

._color-black, .color-black {
  color: #000 !important; }

._background-green {
  background-color: #1aad12 !important; }

._background-blue {
  background-color: #14467e !important; }

._background-yellow {
  background-color: #ffc800 !important; }

._background-red {
  background-color: #d4161c !important; }

._background-white {
  background-color: #fff !important; }

._background-black {
  background-color: #000 !important; }

.content-container {
  margin-right: auto;
  margin-left: auto; }
  .content-container::before, .content-container::after {
    display: table;
    content: ''; }
  .content-container::after {
    clear: both; }

.row {
  display: flex;
  flex-flow: row wrap; }
  .row:first-child {
    padding-top: 0; }
  .row.-no-gutter {
    margin-right: 0;
    margin-left: 0;
    padding-top: 0; }
    .row.-no-gutter [class*='col-'] {
      padding-right: 0;
      padding-left: 0; }
  .row .flex-items-center {
    justify-content: center; }
  .row .flex-items-right {
    margin-left: auto;
    justify-content: flex-end;
    align-self: flex-end; }
  .row .flex-items-top {
    align-items: flex-start; }
  .row .flex-items-middle {
    align-items: center; }
  .row .flex-items-bottom {
    align-items: flex-end; }
  .row .flex-items-stretch {
    align-items: stretch; }
  .row .flex-items-space-between {
    justify-content: space-between; }
  .row .flex-items-space-around {
    justify-content: space-around; }
  .row .flex-content-top {
    align-content: flex-start; }
  .row .flex-content-middle {
    align-content: center; }
  .row .flex-content-bottom {
    align-content: flex-end; }
  .row .flex-content-stretch {
    align-content: stretch; }
  .row .flex-content-space-between {
    align-content: space-between; }
  .row .flex-content-space-around {
    align-content: space-around; }
  .row .flex-row {
    flex-direction: row; }
  .row .flex-row-reverse {
    flex-direction: row-reverse; }
  .row .flex-column {
    flex-direction: column; }
    .row .flex-column [class*='col-'] {
      flex-basis: auto; }
      .row .flex-column [class*='col-']:first-child {
        padding-top: 0; }
  .row .flex-column-reverse {
    flex-direction: column-reverse; }
    .row .flex-column-reverse [class*='col-']:first-child {
      padding-bottom: 0; }
  .row .flex-equal-height [class*='col-'] {
    display: flex; }

[class*='col-'] {
  min-height: 1px;
  position: relative;
  flex: 0 0 auto; }

.col {
  flex: 1 1 0; }
  .col .flex-top {
    align-self: flex-start; }
  .col .flex-middle {
    align-self: center; }
  .col .flex-bottom {
    align-self: flex-end; }
  .col .flex-first {
    order: -1; }
  .col .flex-last {
    order: 1; }

@media (min-width: 300px) {
  .col-xs-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-xs-offset-1 {
    margin-left: 8.33333%; }
  .col-xs-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-xs-offset-2 {
    margin-left: 16.66667%; }
  .col-xs-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-xs-offset-3 {
    margin-left: 25%; }
  .col-xs-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-xs-offset-4 {
    margin-left: 33.33333%; }
  .col-xs-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-xs-offset-5 {
    margin-left: 41.66667%; }
  .col-xs-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-xs-offset-6 {
    margin-left: 50%; }
  .col-xs-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-xs-offset-7 {
    margin-left: 58.33333%; }
  .col-xs-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-xs-offset-8 {
    margin-left: 66.66667%; }
  .col-xs-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-xs-offset-9 {
    margin-left: 75%; }
  .col-xs-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-xs-offset-10 {
    margin-left: 83.33333%; }
  .col-xs-11 {
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .col-xs-offset-11 {
    margin-left: 91.66667%; }
  .col-xs-12 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-xs-offset-12 {
    margin-left: 100%; }
  .content-container {
    width: 288px; }
  .col-xs-visible {
    display: block; }
  .col-xs-hidden {
    display: none; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-1 {
    left: 8.33333%; }
  .col-xs-pull-1 {
    right: 8.33333%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-2 {
    left: 16.66667%; }
  .col-xs-pull-2 {
    right: 16.66667%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-3 {
    left: 25%; }
  .col-xs-pull-3 {
    right: 25%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-4 {
    left: 33.33333%; }
  .col-xs-pull-4 {
    right: 33.33333%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-5 {
    left: 41.66667%; }
  .col-xs-pull-5 {
    right: 41.66667%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-6 {
    left: 50%; }
  .col-xs-pull-6 {
    right: 50%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-7 {
    left: 58.33333%; }
  .col-xs-pull-7 {
    right: 58.33333%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-8 {
    left: 66.66667%; }
  .col-xs-pull-8 {
    right: 66.66667%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-9 {
    left: 75%; }
  .col-xs-pull-9 {
    right: 75%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-10 {
    left: 83.33333%; }
  .col-xs-pull-10 {
    right: 83.33333%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-11 {
    left: 91.66667%; }
  .col-xs-pull-11 {
    right: 91.66667%; } }

@media (min-width: 300px) and (max-width: 599px) {
  .col-xs-push-12 {
    left: 100%; }
  .col-xs-pull-12 {
    right: 100%; } }

@media (min-width: 600px) {
  .col-sm-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-11 {
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-12 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .content-container {
    width: 576px; }
  .col-sm-visible {
    display: block; }
  .col-sm-hidden {
    display: none; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-1 {
    left: 8.33333%; }
  .col-sm-pull-1 {
    right: 8.33333%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-2 {
    left: 16.66667%; }
  .col-sm-pull-2 {
    right: 16.66667%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-pull-3 {
    right: 25%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-4 {
    left: 33.33333%; }
  .col-sm-pull-4 {
    right: 33.33333%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-5 {
    left: 41.66667%; }
  .col-sm-pull-5 {
    right: 41.66667%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-pull-6 {
    right: 50%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-7 {
    left: 58.33333%; }
  .col-sm-pull-7 {
    right: 58.33333%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-8 {
    left: 66.66667%; }
  .col-sm-pull-8 {
    right: 66.66667%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-pull-9 {
    right: 75%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-10 {
    left: 83.33333%; }
  .col-sm-pull-10 {
    right: 83.33333%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-11 {
    left: 91.66667%; }
  .col-sm-pull-11 {
    right: 91.66667%; } }

@media (min-width: 600px) and (max-width: 899px) {
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-pull-12 {
    right: 100%; } }

@media (min-width: 900px) {
  .col-md-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-11 {
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-12 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .content-container {
    width: 864px; }
  .col-md-visible {
    display: block; }
  .col-md-hidden {
    display: none; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-1 {
    left: 8.33333%; }
  .col-md-pull-1 {
    right: 8.33333%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-2 {
    left: 16.66667%; }
  .col-md-pull-2 {
    right: 16.66667%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-3 {
    left: 25%; }
  .col-md-pull-3 {
    right: 25%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-4 {
    left: 33.33333%; }
  .col-md-pull-4 {
    right: 33.33333%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-5 {
    left: 41.66667%; }
  .col-md-pull-5 {
    right: 41.66667%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-6 {
    left: 50%; }
  .col-md-pull-6 {
    right: 50%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-7 {
    left: 58.33333%; }
  .col-md-pull-7 {
    right: 58.33333%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-8 {
    left: 66.66667%; }
  .col-md-pull-8 {
    right: 66.66667%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-9 {
    left: 75%; }
  .col-md-pull-9 {
    right: 75%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-10 {
    left: 83.33333%; }
  .col-md-pull-10 {
    right: 83.33333%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-11 {
    left: 91.66667%; }
  .col-md-pull-11 {
    right: 91.66667%; } }

@media (min-width: 900px) and (max-width: 1199px) {
  .col-md-push-12 {
    left: 100%; }
  .col-md-pull-12 {
    right: 100%; } }

@media (min-width: 1200px) {
  .col-lg-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-11 {
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-12 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .content-container {
    width: 1152px; }
  .col-lg-visible {
    display: block; }
  .col-lg-hidden {
    display: none; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-1 {
    left: 8.33333%; }
  .col-lg-pull-1 {
    right: 8.33333%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-2 {
    left: 16.66667%; }
  .col-lg-pull-2 {
    right: 16.66667%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-pull-3 {
    right: 25%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-4 {
    left: 33.33333%; }
  .col-lg-pull-4 {
    right: 33.33333%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-5 {
    left: 41.66667%; }
  .col-lg-pull-5 {
    right: 41.66667%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-pull-6 {
    right: 50%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-7 {
    left: 58.33333%; }
  .col-lg-pull-7 {
    right: 58.33333%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-8 {
    left: 66.66667%; }
  .col-lg-pull-8 {
    right: 66.66667%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-pull-9 {
    right: 75%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-10 {
    left: 83.33333%; }
  .col-lg-pull-10 {
    right: 83.33333%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-11 {
    left: 91.66667%; }
  .col-lg-pull-11 {
    right: 91.66667%; } }

@media (min-width: 1200px) and (max-width: 1499px) {
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-pull-12 {
    right: 100%; } }

@media (min-width: 1500px) {
  .col-xl-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-xl-offset-1 {
    margin-left: 8.33333%; }
  .col-xl-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-xl-offset-2 {
    margin-left: 16.66667%; }
  .col-xl-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-xl-offset-3 {
    margin-left: 25%; }
  .col-xl-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-xl-offset-4 {
    margin-left: 33.33333%; }
  .col-xl-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-xl-offset-5 {
    margin-left: 41.66667%; }
  .col-xl-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-xl-offset-6 {
    margin-left: 50%; }
  .col-xl-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-xl-offset-7 {
    margin-left: 58.33333%; }
  .col-xl-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-xl-offset-8 {
    margin-left: 66.66667%; }
  .col-xl-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-xl-offset-9 {
    margin-left: 75%; }
  .col-xl-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-xl-offset-10 {
    margin-left: 83.33333%; }
  .col-xl-11 {
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .col-xl-offset-11 {
    margin-left: 91.66667%; }
  .col-xl-12 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-xl-offset-12 {
    margin-left: 100%; }
  .content-container {
    width: 1440px; }
  .col-xl-visible {
    display: block; }
  .col-xl-hidden {
    display: none; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-1 {
    left: 8.33333%; }
  .col-xl-pull-1 {
    right: 8.33333%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-2 {
    left: 16.66667%; }
  .col-xl-pull-2 {
    right: 16.66667%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-3 {
    left: 25%; }
  .col-xl-pull-3 {
    right: 25%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-4 {
    left: 33.33333%; }
  .col-xl-pull-4 {
    right: 33.33333%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-5 {
    left: 41.66667%; }
  .col-xl-pull-5 {
    right: 41.66667%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-6 {
    left: 50%; }
  .col-xl-pull-6 {
    right: 50%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-7 {
    left: 58.33333%; }
  .col-xl-pull-7 {
    right: 58.33333%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-8 {
    left: 66.66667%; }
  .col-xl-pull-8 {
    right: 66.66667%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-9 {
    left: 75%; }
  .col-xl-pull-9 {
    right: 75%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-10 {
    left: 83.33333%; }
  .col-xl-pull-10 {
    right: 83.33333%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-11 {
    left: 91.66667%; }
  .col-xl-pull-11 {
    right: 91.66667%; } }

@media (min-width: 1500px) and (max-width: 1799px) {
  .col-xl-push-12 {
    left: 100%; }
  .col-xl-pull-12 {
    right: 100%; } }

@media (min-width: 1800px) {
  .col-xxl-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%; }
  .col-xxl-offset-1 {
    margin-left: 8.33333%; }
  .col-xxl-2 {
    max-width: 16.66667%;
    flex-basis: 16.66667%; }
  .col-xxl-offset-2 {
    margin-left: 16.66667%; }
  .col-xxl-3 {
    max-width: 25%;
    flex-basis: 25%; }
  .col-xxl-offset-3 {
    margin-left: 25%; }
  .col-xxl-4 {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .col-xxl-offset-4 {
    margin-left: 33.33333%; }
  .col-xxl-5 {
    max-width: 41.66667%;
    flex-basis: 41.66667%; }
  .col-xxl-offset-5 {
    margin-left: 41.66667%; }
  .col-xxl-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .col-xxl-offset-6 {
    margin-left: 50%; }
  .col-xxl-7 {
    max-width: 58.33333%;
    flex-basis: 58.33333%; }
  .col-xxl-offset-7 {
    margin-left: 58.33333%; }
  .col-xxl-8 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; }
  .col-xxl-offset-8 {
    margin-left: 66.66667%; }
  .col-xxl-9 {
    max-width: 75%;
    flex-basis: 75%; }
  .col-xxl-offset-9 {
    margin-left: 75%; }
  .col-xxl-10 {
    max-width: 83.33333%;
    flex-basis: 83.33333%; }
  .col-xxl-offset-10 {
    margin-left: 83.33333%; }
  .col-xxl-11 {
    max-width: 91.66667%;
    flex-basis: 91.66667%; }
  .col-xxl-offset-11 {
    margin-left: 91.66667%; }
  .col-xxl-12 {
    max-width: 100%;
    flex-basis: 100%; }
  .col-xxl-offset-12 {
    margin-left: 100%; }
  .content-container {
    width: 1600px; }
  .col-xxl-visible {
    display: block; }
  .col-xxl-hidden {
    display: none; } }

@media (min-width: 1800px) {
  .col-xxl-push-1 {
    left: 8.33333%; }
  .col-xxl-pull-1 {
    right: 8.33333%; } }

@media (min-width: 1800px) {
  .col-xxl-push-2 {
    left: 16.66667%; }
  .col-xxl-pull-2 {
    right: 16.66667%; } }

@media (min-width: 1800px) {
  .col-xxl-push-3 {
    left: 25%; }
  .col-xxl-pull-3 {
    right: 25%; } }

@media (min-width: 1800px) {
  .col-xxl-push-4 {
    left: 33.33333%; }
  .col-xxl-pull-4 {
    right: 33.33333%; } }

@media (min-width: 1800px) {
  .col-xxl-push-5 {
    left: 41.66667%; }
  .col-xxl-pull-5 {
    right: 41.66667%; } }

@media (min-width: 1800px) {
  .col-xxl-push-6 {
    left: 50%; }
  .col-xxl-pull-6 {
    right: 50%; } }

@media (min-width: 1800px) {
  .col-xxl-push-7 {
    left: 58.33333%; }
  .col-xxl-pull-7 {
    right: 58.33333%; } }

@media (min-width: 1800px) {
  .col-xxl-push-8 {
    left: 66.66667%; }
  .col-xxl-pull-8 {
    right: 66.66667%; } }

@media (min-width: 1800px) {
  .col-xxl-push-9 {
    left: 75%; }
  .col-xxl-pull-9 {
    right: 75%; } }

@media (min-width: 1800px) {
  .col-xxl-push-10 {
    left: 83.33333%; }
  .col-xxl-pull-10 {
    right: 83.33333%; } }

@media (min-width: 1800px) {
  .col-xxl-push-11 {
    left: 91.66667%; }
  .col-xxl-pull-11 {
    right: 91.66667%; } }

@media (min-width: 1800px) {
  .col-xxl-push-12 {
    left: 100%; }
  .col-xxl-pull-12 {
    right: 100%; } }

form {
  width: 100%;
  background-color: transparent; }
  form::before, form::after {
    display: table;
    content: ''; }
  form::after {
    clear: both; }
  form.-bordered, form.-rounded {
    padding: 0;
    border: 1px solid #c8c8c8; }
  form.-hovered {
    padding-right: 0;
    padding-left: 0; }
    form.-hovered .row,
    form.-hovered .form__row {
      padding-top: 0;
      padding-bottom: 0; }
      form.-hovered .row:hover,
      form.-hovered .form__row:hover {
        background-color: transparent; }
  form.-rounded {
    border-radius: 0; }
  form .row {
    padding: 0; }

fieldset {
  padding: 0 0;
  border: 1px solid #efefef; }
  fieldset::before, fieldset::after {
    display: table;
    content: ''; }
  fieldset::after {
    clear: both; }
  fieldset + fieldset {
    margin-top: 0; }

legend {
  padding: 0 0;
  background-color: transparent;
  color: #919191;
  font-size: 17.5px;
  line-height: 1; }

label {
  max-width: 100%;
  display: inline-block;
  color: #5c5a5a;
  line-height: 36px;
  font-weight: bold; }

select:hover {
  cursor: pointer; }

select[multiple] {
  min-height: 210px; }

select.form__item, .checkbox__content select.checkbox__label, .selectbox__content select {
  padding-top: 0;
  padding-bottom: 0; }

textarea {
  min-height: 36px;
  padding: 9px 9px;
  overflow: hidden; }

.form__item, .checkbox__content .checkbox__label, .selectbox__content select {
  background-color: #fff;
  color: #919191;
  width: 100%;
  height: 36px;
  margin: 0;
  padding: 9px 9px;
  display: block;
  font-size: 14px;
  line-height: 1.5;
  transition: border 0.05s ease-in-out;
  border: 1px solid #c8c8c8; }
  .form__item:focus, .checkbox__content .checkbox__label:focus, .selectbox__content select:focus, .form__item:hover, .checkbox__content .checkbox__label:hover, .selectbox__content select:hover, .form__item:active, .checkbox__content .checkbox__label:active, .selectbox__content select:active, .form__item.active, .checkbox__content .active.checkbox__label, .selectbox__content select.active {
    background-color: #fff;
    color: #919191; }
  .form__item:focus, .checkbox__content .checkbox__label:focus, .selectbox__content select:focus {
    transition: border 0.5s ease-in-out;
    border-color: #bebebe; }
  .form__item.-valid:focus, .checkbox__content .-valid.checkbox__label:focus, .selectbox__content select.-valid:focus {
    border-color: #14467e; }
  .form__item.-required:focus, .checkbox__content .-required.checkbox__label:focus, .selectbox__content select.-required:focus {
    border-color: #d4161c; }
  .form__item[disabled], .checkbox__content [disabled].checkbox__label, .selectbox__content select[disabled] {
    background-color: #cccccc;
    color: gray;
    cursor: not-allowed;
    user-select: none; }
    .form__item[disabled]:focus, .checkbox__content [disabled].checkbox__label:focus, .selectbox__content select[disabled]:focus, .form__item[disabled]:hover, .checkbox__content [disabled].checkbox__label:hover, .selectbox__content select[disabled]:hover, .form__item[disabled]:active, .checkbox__content [disabled].checkbox__label:active, .selectbox__content select[disabled]:active, .form__item[disabled].active, .checkbox__content [disabled].active.checkbox__label, .selectbox__content select[disabled].active {
      background-color: #cccccc;
      color: gray; }
  .form__item[readonly], .checkbox__content [readonly].checkbox__label, .selectbox__content select[readonly] {
    background-color: #cccccc;
    color: #919191; }
    .form__item[readonly]:focus, .checkbox__content [readonly].checkbox__label:focus, .selectbox__content select[readonly]:focus, .form__item[readonly]:hover, .checkbox__content [readonly].checkbox__label:hover, .selectbox__content select[readonly]:hover, .form__item[readonly]:active, .checkbox__content [readonly].checkbox__label:active, .selectbox__content select[readonly]:active, .form__item[readonly].active, .checkbox__content [readonly].active.checkbox__label, .selectbox__content select[readonly].active {
      background-color: #cccccc;
      color: #919191; }
  .form__item.-success, .checkbox__content .-success.checkbox__label, .selectbox__content select.-success {
    background-color: #1aad12;
    color: #0f630a; }
    .form__item.-success:focus, .checkbox__content .-success.checkbox__label:focus, .selectbox__content select.-success:focus, .form__item.-success:hover, .checkbox__content .-success.checkbox__label:hover, .selectbox__content select.-success:hover, .form__item.-success:active, .checkbox__content .-success.checkbox__label:active, .selectbox__content select.-success:active, .form__item.-success.active, .checkbox__content .-success.active.checkbox__label, .selectbox__content select.-success.active {
      background-color: #14880e;
      color: #093a06; }
  .form__item.-info, .checkbox__content .-info.checkbox__label, .selectbox__content select.-info {
    background-color: #14467e;
    color: #091f38; }
    .form__item.-info:focus, .checkbox__content .-info.checkbox__label:focus, .selectbox__content select.-info:focus, .form__item.-info:hover, .checkbox__content .-info.checkbox__label:hover, .selectbox__content select.-info:hover, .form__item.-info:active, .checkbox__content .-info.checkbox__label:active, .selectbox__content select.-info:active, .form__item.-info.active, .checkbox__content .-info.active.checkbox__label, .selectbox__content select.-info.active {
      background-color: #0e325b;
      color: #030910; }
  .form__item.-warning, .checkbox__content .-warning.checkbox__label, .selectbox__content select.-warning {
    background-color: #ffc800;
    color: #ad8800; }
    .form__item.-warning:focus, .checkbox__content .-warning.checkbox__label:focus, .selectbox__content select.-warning:focus, .form__item.-warning:hover, .checkbox__content .-warning.checkbox__label:hover, .selectbox__content select.-warning:hover, .form__item.-warning:active, .checkbox__content .-warning.checkbox__label:active, .selectbox__content select.-warning:active, .form__item.-warning.active, .checkbox__content .-warning.active.checkbox__label, .selectbox__content select.-warning.active {
      background-color: #d6a800;
      color: #806400; }
  .form__item.-danger, .checkbox__content .-danger.checkbox__label, .selectbox__content select.-danger {
    background-color: #d4161c;
    color: #8a0e12; }
    .form__item.-danger:focus, .checkbox__content .-danger.checkbox__label:focus, .selectbox__content select.-danger:focus, .form__item.-danger:hover, .checkbox__content .-danger.checkbox__label:hover, .selectbox__content select.-danger:hover, .form__item.-danger:active, .checkbox__content .-danger.checkbox__label:active, .selectbox__content select.-danger:active, .form__item.-danger.active, .checkbox__content .-danger.active.checkbox__label, .selectbox__content select.-danger.active {
      background-color: #af1217;
      color: #600a0d; }

.form__description {
  padding-top: 17.25px;
  color: #4d4d4d;
  font-size: 11.9px; }

.form__inline.form__bordered {
  padding: 0; }

.form__inline label {
  padding-right: 0;
  display: inline-block;
  vertical-align: middle; }

.form__inline .row {
  display: inline-block;
  vertical-align: middle; }

.form__inline .form__item, .form__inline .checkbox__content .checkbox__label, .checkbox__content .form__inline .checkbox__label, .form__inline .selectbox__content select, .selectbox__content .form__inline select {
  width: auto;
  max-width: 100%;
  margin-right: 0;
  display: inline-block; }

.alert {
  background-color: #1a1a1a;
  color: #fff;
  margin: 10px 0 10px 0;
  padding: 10px 45px 10px 15px;
  position: relative;
  line-height: 1.5;
  font-weight: bold; }
  .alert a {
    color: #fff; }
  .alert.-success {
    background-color: #1aad12;
    color: #fff; }
    .alert.-success a {
      color: #fff; }
  .alert.-info {
    background-color: #14467e;
    color: #fff; }
    .alert.-info a {
      color: #fff; }
  .alert.-warning {
    background-color: #ffc800;
    color: #fff; }
    .alert.-warning a {
      color: #fff; }
  .alert.-danger {
    background-color: #d4161c;
    color: #fff; }
    .alert.-danger a {
      color: #fff; }

.button {
  background-color: #1a1a1a;
  color: #cccccc;
  min-width: 70px;
  height: 46px;
  padding: 0 25px 0 25px;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  text-align: center; }
  .button:focus, .button:hover, .button:active, .button.-active {
    background-color: #333333;
    color: #e6e6e6; }
  .button.-success {
    background-color: #1aad12;
    color: #0f630a; }
    .button.-success:focus, .button.-success:hover, .button.-success:active, .button.-success.-active {
      background-color: #14880e;
      color: #093a06; }
  .button.-info {
    background-color: #14467e;
    color: #091f38; }
    .button.-info:focus, .button.-info:hover, .button.-info:active, .button.-info.-active {
      background-color: #0e325b;
      color: #030910; }
  .button.-warning {
    background-color: #ffc800;
    color: #ad8800; }
    .button.-warning:focus, .button.-warning:hover, .button.-warning:active, .button.-warning.-active {
      background-color: #d6a800;
      color: #806400; }
  .button.-danger {
    background-color: #d4161c;
    color: #8a0e12; }
    .button.-danger:focus, .button.-danger:hover, .button.-danger:active, .button.-danger.-active {
      background-color: #af1217;
      color: #600a0d; }
  .button.-disabled, .button.-disabled:focus, .button.-disabled:hover, .button.-disabled:active, .button.-disabled.-active {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .5; }
  .button.-block {
    width: 100%;
    display: block; }

a.button {
  line-height: 46px;
  text-decoration: none; }

.visuallyhidden {
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: none; }

._color-green, .color-green {
  color: #1aad12 !important; }

._color-blue, .color-blue {
  color: #14467e !important; }

._color-yellow, .color-yellow {
  color: #ffc800 !important; }

._color-red, .color-red {
  color: #d4161c !important; }

._color-white, .color-white {
  color: #fff !important; }

._color-black, .color-black {
  color: #000 !important; }

._background-green {
  background-color: #1aad12 !important; }

._background-blue {
  background-color: #14467e !important; }

._background-yellow {
  background-color: #ffc800 !important; }

._background-red {
  background-color: #d4161c !important; }

._background-white {
  background-color: #fff !important; }

._background-black {
  background-color: #000 !important; }

.text-italic {
  font-style: italic; }

.button.-blue {
  background-color: #14467e;
  color: #fff; }
  .button.-blue:focus, .button.-blue:hover, .button.-blue:active, .-active.button.-blue {
    background-color: #0e325b;
    color: #fff; }

.button.-yellow {
  background-color: #ffc800;
  color: #fff; }
  .button.-yellow:focus, .button.-yellow:hover, .button.-yellow:active, .-active.button.-yellow {
    background-color: #d6a800;
    color: #fff; }

.button.-green, .button.-red {
  background-color: #d4161c;
  color: #fff; }
  .button.-green:focus, .button.-red:focus, .button.-green:hover, .button.-red:hover, .button.-green:active, .button.-red:active, .-active.button.-green, .-active.button.-red {
    background-color: #af1217;
    color: #fff; }

.button.-gray-red {
  background-color: #efefef;
  color: #d4161c; }
  .button.-gray-red:focus, .button.-gray-red:hover, .button.-gray-red:active, .-active.button.-gray-red {
    background-color: #dbdbdb;
    color: #d4161c; }

.button.-gray-blue {
  background-color: #efefef;
  color: #14467e; }
  .button.-gray-blue:focus, .button.-gray-blue:hover, .button.-gray-blue:active, .-active.button.-gray-blue {
    background-color: #dbdbdb;
    color: #14467e; }

.icon.-triangle-right-white, .icon.-triangle-left-white, .lang__item::before, .section__phone-content::before, .document .our-team__lang::before, .icon {
  background-image: resolve("../images/ac-dodavky/sprites.png"); }

.loader, .reference__single-item .reference__single-item-image, .catalog .car__image, .slideshow, .slideshow-car, .map {
  background-image: resolve("ac-dodavky/loader.svg"); }

.icon.-triangle-right-white {
  width: 18px;
  height: 25px;
  background-position: -52px 0px; }

.icon.-triangle-left-white {
  width: 18px;
  height: 25px;
  background-position: 0px -40px; }

.button.-arrow-right-white {
  background-image: resolve("ac-dodavky/icon_arrow-right-white.png"); }

.button.-arrow-left-white {
  background-image: resolve("ac-dodavky/icon_arrow-left-white.png"); }

.-arrow-right-blue {
  background-repeat: no-repeat; }

.-arrow-left-blue {
  background-repeat: no-repeat; }

.-arrow-right-blue, .detail .car__link {
  background-image: resolve("ac-dodavky/icon_arrow-right-blue.png"); }

.-arrow-left-blue {
  background-image: resolve("ac-dodavky/icon_arrow-left-blue.png"); }

.collapse__button {
  background-image: resolve("ac-dodavky/icon_arrow-bottom-blue.png"); }

.-arrow-right-red {
  background-repeat: no-repeat; }

.-arrow-left-red {
  background-repeat: no-repeat; }

.-arrow-right-red, .button.-arrow-right-red {
  background-image: resolve("ac-dodavky/icon_arrow-right-red.png"); }

.-arrow-left-red, .button.-arrow-left-red {
  background-image: resolve("ac-dodavky/icon_arrow-left-red.png"); }

.document .our-team__lang.-bg::before {
  width: 22px;
  height: 16px;
  background-position: -92px -64px; }

.document .our-team__lang.-cro::before {
  width: 22px;
  height: 16px;
  background-position: -22px -65px; }

.lang__item.-cs::before, .document .our-team__lang.-cs::before {
  width: 22px;
  height: 16px;
  background-position: 0px -81px; }

.lang__item.-de::before, .document .our-team__lang.-de::before {
  width: 22px;
  height: 16px;
  background-position: -18px -40px; }

.lang__item.-en::before, .document .our-team__lang.-en::before {
  width: 22px;
  height: 16px;
  background-position: -40px -40px; }

.lang__item.-es::before, .document .our-team__lang.-es::before {
  width: 22px;
  height: 16px;
  background-position: -70px 0px; }

.lang__item.-fr::before, .document .our-team__lang.-fr::before {
  width: 22px;
  height: 16px;
  background-position: -70px -16px; }

.lang__item.-hu::before, .document .our-team__lang.-hu::before {
  width: 22px;
  height: 16px;
  background-position: -70px -32px; }

.lang__item.-it::before, .document .our-team__lang.-it::before {
  width: 22px;
  height: 16px;
  background-position: -70px -48px; }

.document .our-team__lang.-mk::before {
  width: 22px;
  height: 16px;
  background-position: 0px -65px; }

.document .our-team__lang.-mne::before {
  width: 22px;
  height: 16px;
  background-position: -22px -81px; }

.lang__item.-pl::before, .document .our-team__lang.-pl::before {
  width: 22px;
  height: 16px;
  background-position: -44px -65px; }

.lang__item.-ro::before, .document .our-team__lang.-ro::before {
  width: 22px;
  height: 16px;
  background-position: -66px -65px; }

.lang__item.-ru::before, .document .our-team__lang.-ru::before {
  width: 22px;
  height: 16px;
  background-position: -92px 0px; }

.lang__item.-sk::before, .document .our-team__lang.-sk::before {
  width: 22px;
  height: 16px;
  background-position: -92px -16px; }

.document .our-team__lang.-slo::before {
  width: 22px;
  height: 16px;
  background-position: -92px -32px; }

.document .our-team__lang.-srb::before {
  width: 22px;
  height: 16px;
  background-position: -92px -48px; }

._m-b-10 {
  margin-bottom: 10px; }

._m-b-15 {
  margin-bottom: 15px; }

._m-b-20 {
  margin-bottom: 20px; }

._m-b-30 {
  margin-bottom: 30px; }

._m-b-40 {
  margin-bottom: 40px; }

._m-t-10 {
  margin-top: 10px; }

._m-t-15 {
  margin-top: 15px; }

._m-t-20 {
  margin-top: 20px; }

._m-t-30 {
  margin-top: 30px; }

._m-t-40 {
  margin-top: 40px; }

._p-b-10 {
  padding-bottom: 10px; }

._p-b-15 {
  padding-bottom: 15px; }

._p-b-20 {
  padding-bottom: 20px; }

._p-b-30 {
  padding-bottom: 30px; }

._p-b-40 {
  padding-bottom: 40px; }

._p-t-10 {
  padding-top: 10px; }

._p-t-15 {
  padding-top: 15px; }

._p-t-20 {
  padding-top: 20px; }

._p-t-30 {
  padding-top: 30px; }

._p-t-40 {
  padding-top: 40px; }

@keyframes load {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes catalog {
  0% {
    opacity: 0;
    transform: translateY(10%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes catalog-pulse {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes modal-content-fade-in {
  0% {
    transform: translate(-50%, -200px); }
  100% {
    transform: translate(-50%, 0); } }

@keyframes modal-fade-in {
  0% {
    display: none;
    opacity: 0; }
  100% {
    display: block;
    opacity: 1; } }

@keyframes fadeOut {
  100% {
    visibility: hidden;
    opacity: 0; } }

.heading__2, .document .block__heading {
  width: 100%;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 1em; }
  .content-container .heading__2, .content-container .document .block__heading, .document .content-container .block__heading {
    color: #d4161c; }

.heading__3, .document h3, .detail .car__description h3 {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase; }
  .content-container .heading__3, .content-container .document h3, .document .content-container h3, .content-container .detail .car__description h3, .detail .car__description .content-container h3,
  .newsletter__container .heading__3,
  .newsletter__container .document h3, .document
  .newsletter__container h3,
  .newsletter__container .detail .car__description h3, .detail .car__description
  .newsletter__container h3 {
    color: #14467e; }
  .-section .heading__3, .-section .document h3, .document .-section h3, .-section .detail .car__description h3, .detail .car__description .-section h3 {
    margin-bottom: 0.75rem; }
  .footer .heading__3, .footer .document h3, .document .footer h3, .footer .detail .car__description h3, .detail .car__description .footer h3 {
    color: #fff; }

.heading__4, .heading__5, .document h5,
.heading__6, .document h6, .document h4 {
  width: 100%;
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold; }
  .-section .heading__4, .-section .heading__5, .-section .document h5, .document .-section h5, .-section
  .heading__6, .-section .document h6, .document .-section h6, .-section .document h4, .document .-section h4 {
    margin-bottom: 0.3rem; }

body,
html {
  height: auto; }

body {
  font-family: "Open Sans", Tahoma, Nimbus Sans L, Free Sans, sans-serif;
  overflow-y: scroll;
  background-color: #efefef;
  color: #5c5a5a;
  font-size: 14px;
  line-height: 1.5;
  –webkit-overflow-scrolling: touch; }
  @media (min-width: 300px) {
    body::after {
      display: none;
      content: "xs-device"; } }
  @media (min-width: 600px) {
    body::after {
      display: none;
      content: "sm-device"; } }
  @media (min-width: 900px) {
    body::after {
      display: none;
      content: "md-device"; } }
  @media (min-width: 1200px) {
    body::after {
      display: none;
      content: "lg-device"; } }
  @media (min-width: 1500px) {
    body::after {
      display: none;
      content: "xl-device"; } }
  @media (min-width: 1800px) {
    body::after {
      display: none;
      content: "xxl-device"; } }

::-moz-placeholder {
  opacity: 1;
  color: #919191; }

:-ms-input-placeholder {
  opacity: 1;
  color: #919191; }

::-webkit-input-placeholder {
  opacity: 1;
  color: #919191; }

a {
  color: #5c5a5a;
  text-decoration: none;
  border-bottom: 1px solid; }
  a:focus, a:hover {
    cursor: pointer;
    border-bottom: none; }

@media (min-width: 301px) {
  .content-container.-section {
    padding: 13.33333px; }
    .page__nas-tym .content-container.-section {
      padding-top: 0;
      padding-bottom: 0; } }

@media (min-width: 601px) {
  .content-container.-section {
    padding: 20px 40px; } }

@media (min-width: 901px) {
  .content-container.-section {
    padding: 20px 80px; } }

@media (min-width: 1201px) {
  .content-container.-section {
    padding: 40px 80px; } }

@media (max-width: 300px) {
  .content-container.-mobile-fullwidth, .content-container.-xs-fullwidth {
    width: 97%; } }

@media (max-width: 600px) {
  .content-container.-mobile-fullwidth, .content-container.-sm-fullwidth {
    width: 96%; } }

.content-container.-filter {
  transition: padding 0.5s ease-in-out; }

.content-container + .newsletter__container {
  padding-top: 20px; }

.content {
  width: 100%;
  position: relative;
  z-index: 14;
  overflow: hidden;
  background-color: #fff; }

.sticky {
  position: sticky;
  z-index: 1000; }
  .sticky::before, .sticky::after {
    display: table;
    content: ''; }

.header {
  width: 100%;
  z-index: 13; }

.header__container {
  width: 100%;
  height: 55px;
  top: 0;
  background: #13467d; }

.header__content {
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }
  @media (max-width: 600px) {
    .header__content {
      width: 88%; } }

.header__logo {
  height: 55px;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: none; }
  .navigation__container .header__logo {
    float: left;
    visibility: visible;
    opacity: 1; }
  @media (min-width: 601px) {
    .header__logo {
      margin: 0 30px; } }

.header__logo-image {
  max-width: 120px;
  max-height: 55px; }

.footer {
  width: 100%;
  padding: 60px 0;
  background: #14467e;
  -webkit-backface-visibility: hidden; }
  @media (max-width: 900px) {
    .footer {
      padding: 20px 5%; } }
  .footer p {
    -webkit-backface-visibility: hidden; }

.footer__content {
  color: #fff;
  font-size: 1em; }

.footer__contact-content {
  padding-left: 40px; }
  .footer__contact-content a {
    color: #fff; }

.footer__list {
  margin-top: -17px;
  margin-bottom: 40px;
  font-size: 29px;
  font-weight: bold; }
  @media (max-width: 900px) {
    .footer__list {
      margin-top: 0;
      margin-left: 8px;
      font-size: 22px; } }
  .footer__list li {
    margin: 11px 0;
    padding-left: 30px;
    position: relative;
    line-height: 1.2em;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.4));
    -webkit-backface-visibility: hidden; }
    .footer__list li::before {
      width: 12px;
      height: 12px;
      margin-right: 18px;
      position: absolute;
      top: 12px;
      left: 0;
      display: inline-block;
      content: '';
      background: #fff;
      border-radius: 50%; }
      @media (max-width: 900px) {
        .footer__list li::before {
          top: 9px; } }

.footer__banner {
  max-width: 100%;
  height: auto;
  margin: 0 auto 40px;
  padding-right: 10px;
  display: block; }

.footer__copy {
  margin-top: 20px;
  color: #4071a9;
  font-size: 11px; }
  .footer__copy a {
    color: #4071a9; }

form fieldset {
  border: none; }

form .row {
  align-items: flex-end; }

form .required {
  position: absolute;
  left: -10px;
  color: #d4161c; }

form [class*='col-'] {
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px; }

.form {
  padding: 4% 2% 2%;
  background-color: #faf8f9;
  border: 3px solid #efefef; }

.form__item, .checkbox__content .checkbox__label, .selectbox__content select {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 2px; }
  .form__item.-error, .checkbox__content .-error.checkbox__label, .selectbox__content select.-error {
    box-shadow: 0 0 6px rgba(212, 22, 28, 0.2);
    border-color: rgba(212, 22, 28, 0.4); }
  .form__item.-valid, .checkbox__content .-valid.checkbox__label, .selectbox__content select.-valid, .form__item.-valid:focus, .checkbox__content .-valid.checkbox__label:focus, .selectbox__content select.-valid:focus {
    border-color: rgba(26, 173, 18, 0.4); }
  .form__item.form__image, .checkbox__content .form__image.checkbox__label, .selectbox__content select.form__image {
    height: auto;
    padding: 0;
    background: none;
    border: none; }
  .calculator-container .form__item, .calculator-container .checkbox__content .checkbox__label, .checkbox__content .calculator-container .checkbox__label, .calculator-container .selectbox__content select, .selectbox__content .calculator-container select {
    color: #5c5a5a;
    font-weight: bold;
    text-align: center; }
  .slider__input .form__item, .slider__input .checkbox__content .checkbox__label, .checkbox__content .slider__input .checkbox__label, .slider__input .selectbox__content select, .selectbox__content .slider__input select {
    max-width: 70%;
    display: inline-block; }

.form__label {
  position: relative;
  font-size: 0.92857em;
  line-height: 20px;
  text-transform: uppercase; }
  .slider__input .form__label {
    display: inline-block;
    font-weight: bold; }

.form__item-label {
  color: #5c5a5a;
  font-size: 0.92857em;
  font-weight: bold;
  text-transform: uppercase; }

.form__item-message {
  width: calc(100% - 30px);
  padding: 5px 10px;
  position: absolute;
  top: auto;
  left: 15px;
  z-index: 50;
  display: inline-block;
  opacity: .75;
  color: #fff;
  font-size: 0.85714em;
  line-height: 1.4;
  transition: opacity 0.5s ease-in-out;
  border-radius: 2px; }
  .form__item-message:focus, .form__item-message:hover {
    opacity: 1;
    transition: opacity 0.5s ease-in-out; }
  .form__item-message.-success {
    background-color: #1aad12; }
  .form__item-message.-info {
    background-color: #14467e; }
  .form__item-message.-warning {
    background-color: #ffc800; }
  .form__item-message.-danger {
    background-color: #d4161c; }

.checkbox__content .checkbox {
  display: none;
  text-align: center; }
  .checkbox__content .checkbox:checked ~ .checkbox__label {
    background-color: #14467e;
    color: #fff;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out; }

.checkbox__content .checkbox__label {
  padding: 6px 9px;
  background-color: transparent;
  color: #14467e;
  text-transform: uppercase;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out, border-color 0.5s ease-in-out; }
  .checkbox__content .checkbox__label:focus, .checkbox__content .checkbox__label:hover {
    background-color: #14467e;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out, border-color 0.5s ease-in-out; }

.checkbox__group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  @media (min-width: 301px) {
    .checkbox__group {
      flex-direction: column; } }
  @media (min-width: 601px) {
    .checkbox__group {
      flex-direction: row; } }
  .checkbox__group.-centered {
    justify-content: center; }
  .checkbox__group .checkbox__content:first-child .checkbox__label {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px; }
  .checkbox__group .checkbox__content:last-child .checkbox__label {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }
  .checkbox__group .checkbox__content + .checkbox__content {
    margin-left: -1px; }
  .checkbox__group .checkbox__content .checkbox__label {
    border-radius: 0; }

.selectbox__content {
  position: relative;
  text-transform: uppercase; }
  .selectbox__content::before {
    width: 40px;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    content: '';
    background: #fff;
    font-size: 30px;
    line-height: 35px !important;
    pointer-events: none; }
  .selectbox__content select {
    width: 100%;
    height: 36px;
    margin: 0;
    padding: 7px 15px;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    appearance: none;
    color: #14467e;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: none;
    /* Since we removed the default focus styles, we have to add our own */ }
    .selectbox__content select:focus {
      outline: none;
      border-color: #14467e; }
  .selectbox__content select::-ms-expand {
    display: none;
    /* remove ms arrow in IE */ }
  .selectbox__content .selectbox__label {
    display: none; }

/* for Webkit's CSS-only solution */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .selectbox__content select {
    padding-right: 25px; } }

.no-pointer-events .selectbox__content select {
  -webkit-appearance: menulist-button; }

.no-pointer-events .selectbox__content::after {
  content: none; }

.no-pointer-events .selectbox__content select::-ms-expand {
  display: inline-block; }

button,
input[type=submit] {
  line-height: 1.1em; }

.button {
  white-space: normal;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 46px;
  border-bottom: none; }
  .button.-brands {
    display: none; }
  .button.-arrow-right-white {
    padding-right: 35px;
    background-repeat: no-repeat;
    background-position: right 19px center;
    transition: background-position 0.05s ease-in-out; }
    .button.-arrow-right-white:focus, .button.-arrow-right-white:hover {
      background-position: right 17px center;
      transition: background-position 0.05s ease-in-out; }
  .button.-arrow-left-white {
    padding-left: 35px;
    background-repeat: no-repeat;
    background-position: 19px center;
    transition: background-position 0.05s ease-in-out; }
    .button.-arrow-left-white:focus, .button.-arrow-left-white:hover {
      background-position: 17px center;
      transition: background-position 0.05s ease-in-out; }
  .button.-arrow-right-red {
    padding-right: 35px;
    background-repeat: no-repeat;
    background-position: right 19px center;
    transition: background-position 0.05s ease-in-out; }
    .button.-arrow-right-red:focus, .button.-arrow-right-red:hover {
      background-position: right 17px center;
      transition: background-position 0.05s ease-in-out; }
  .button.-arrow-left-red {
    padding-left: 35px;
    background-repeat: no-repeat;
    background-position: 19px center;
    transition: background-position 0.05s ease-in-out; }
    .button.-arrow-left-red:focus, .button.-arrow-left-red:hover {
      background-position: 17px center;
      transition: background-position 0.05s ease-in-out; }

.button__text {
  width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.table-item__price {
  text-align: right; }

.alert {
  width: 100%;
  border-radius: 2px; }
  .alert .shape-times {
    position: relative; }
    .alert .shape-times::after, .alert .shape-times::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      content: '';
      background: #fff;
      transform: translate(-50%, -50%) rotate(-45deg); }
    .alert .shape-times::after {
      width: 2px;
      height: 20px; }
    .alert .shape-times::before {
      width: 20px;
      height: 2px; }
  form .alert {
    width: calc(100% - 30px);
    margin-right: 15px;
    margin-left: 15px; }
  .alert .alert__close {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out; }
    .alert .alert__close:focus, .alert .alert__close:hover {
      transform: rotate(90deg);
      transition: transform 0.3s ease-in-out; }

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: none;
  background-color: rgba(255, 255, 255, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10%;
  background-clip: content-box; }
  .loader.-active {
    display: block; }

.collapse-container {
  position: relative; }
  .collapse-container.-active {
    height: 55px;
    overflow: hidden; }
    .collapse-container.-active .collapse__content {
      visibility: hidden;
      opacity: 0; }
  .collapse-container .collapse__content {
    visibility: visible;
    opacity: 1; }

.collapse__trigger {
  width: 46px;
  height: 46px;
  position: absolute;
  right: 20px; }
  .-active .collapse__trigger {
    width: 90px;
    height: auto; }
  .filter__content .collapse__trigger {
    width: 100%;
    max-width: 280px;
    padding-right: 56px;
    right: auto;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%); }

.collapse__button {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  width: 46px;
  height: 46px;
  position: absolute;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
  border-radius: 50%; }
  .-active .collapse__button {
    transform: rotate(0);
    transition: transform 0.5s ease-in-out; }
  .collapse__button:focus, .collapse__button:hover {
    background-color: #efefef;
    transition: background-color 0.5s ease-in-out; }
    .navigation__content .collapse__button:focus, .navigation__content .collapse__button:hover {
      background-color: #fff; }

.collapse__text {
  display: none;
  font-size: 1em;
  line-height: 46px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase; }
  .-active .collapse__text, .-active .collapse__text.-closed {
    display: block; }
  .-active .collapse__text.-opened {
    display: none; }
  .collapse__text.-opened {
    display: block; }
  .collapse__text.-closed {
    display: none; }

.modal__container .modal {
  display: none; }
  .modal__container .modal.-active {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: block;
    content: '';
    background: rgba(0, 0, 0, 0.9);
    animation: modal-fade-in .3s ease-in-out;
    animation-fill-mode: forwards; }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
      .modal__container .modal.-active {
        height: 1024px; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
      .modal__container .modal.-active {
        height: 768px; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
      .modal__container .modal.-active {
        height: 480px; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
      .modal__container .modal.-active {
        height: 320px; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
      .modal__container .modal.-active {
        height: 568px; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
      .modal__container .modal.-active {
        height: 320px; } }
    .modal__container .modal.-active .modal__content {
      width: 75%;
      min-width: 300px;
      max-width: 600px;
      margin: 0;
      position: absolute;
      top: 65px;
      left: 50%;
      animation: modal-content-fade-in .3s ease-in-out;
      animation-fill-mode: forwards;
      transform: translateX(-50%); }
      .modal__container .modal.-active .modal__content .heading__2, .modal__container .modal.-active .modal__content .document .block__heading, .document .modal__container .modal.-active .modal__content .block__heading,
      .modal__container .modal.-active .modal__content .heading__3,
      .modal__container .modal.-active .modal__content .document h3, .document
      .modal__container .modal.-active .modal__content h3,
      .modal__container .modal.-active .modal__content .detail .car__description h3, .detail .car__description
      .modal__container .modal.-active .modal__content h3 {
        max-width: calc(100% - 46px - 4px);
        color: #faf8f9; }
      .modal__container .modal.-active .modal__content .form {
        max-height: 80vh;
        overflow: auto;
        –webkit-overflow-scrolling: touch; }
        @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
          .modal__container .modal.-active .modal__content .form {
            max-height: 819.2px; } }
        @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
          .modal__container .modal.-active .modal__content .form {
            max-height: 614.4px; } }
        @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
          .modal__container .modal.-active .modal__content .form {
            max-height: 384px; } }
        @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
          .modal__container .modal.-active .modal__content .form {
            max-height: 256px; } }
        @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
          .modal__container .modal.-active .modal__content .form {
            max-height: 454.4px; } }
        @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
          .modal__container .modal.-active .modal__content .form {
            max-height: 256px; } }
    .modal__container .modal.-active .modal__close {
      display: inline-block;
      color: #fff; }
      .modal__container .modal.-active .modal__close .shape-times {
        position: relative; }
        .modal__container .modal.-active .modal__close .shape-times::after, .modal__container .modal.-active .modal__close .shape-times::before {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          content: '';
          background: #fff;
          transform: translate(-50%, -50%) rotate(-45deg); }
        .modal__container .modal.-active .modal__close .shape-times::after {
          width: 2px;
          height: 32px; }
        .modal__container .modal.-active .modal__close .shape-times::before {
          width: 32px;
          height: 2px; }
      .modal__container .modal.-active .modal__close span {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        width: 46px;
        height: 46px;
        display: block;
        transform: rotate(0);
        will-change: transform;
        transition: transform 0.3s ease-in-out; }
        .modal__container .modal.-active .modal__close span:focus, .modal__container .modal.-active .modal__close span:hover {
          transform: rotate(90deg);
          will-change: transform;
          transition: transform 0.3s ease-in-out; }

.modal__close {
  display: none; }

.navigation__container {
  width: 100%; }
  .navigation__container.-main {
    top: 0;
    z-index: 1001;
    background: #13467d; }
    @media (min-width: 301px) {
      .navigation__container.-main {
        height: 110px; } }
    @media (min-width: 601px) {
      .navigation__container.-main {
        height: 55px; } }
    .navigation__container.-main .navigation__collapse {
      width: 55px;
      height: 55px;
      padding: 0 10px;
      position: relative;
      display: none;
      line-height: 50px;
      cursor: pointer; }
      .navigation__container.-main .navigation__collapse.-active {
        display: inline-block; }
      .navigation__container.-main .navigation__collapse.-clicked .navigation__nested {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out; }
    .navigation__container.-main .navigation__nested {
      width: 20vw;
      min-width: 100px;
      max-width: 200px;
      position: absolute;
      top: 55px;
      right: 0;
      float: none;
      visibility: hidden;
      opacity: 0;
      text-align: right;
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
        .navigation__container.-main .navigation__nested {
          width: 153.6px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
        .navigation__container.-main .navigation__nested {
          width: 204.8px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
        .navigation__container.-main .navigation__nested {
          width: 64px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
        .navigation__container.-main .navigation__nested {
          width: 96px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
        .navigation__container.-main .navigation__nested {
          width: 64px; } }
      @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
        .navigation__container.-main .navigation__nested {
          width: 113.6px; } }
      .navigation__container.-main .navigation__nested li {
        float: none; }
      .navigation__container.-main .navigation__nested span {
        border: none; }
  .navigation__container.-categories {
    position: relative;
    top: 0;
    opacity: 0;
    will-change: opacity;
    background-color: #efefef;
    animation: load 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation-fill-mode: forwards; }
    .navigation__container.-categories[style*="fixed"] {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); }
    .ie9 .navigation__container.-categories {
      opacity: 1; }

.-main .navigation__content {
  position: relative;
  float: left;
  visibility: hidden; }
  .-main .navigation__content.-active {
    visibility: visible; }

.-categories .navigation__content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 60px;
  position: relative;
  overflow: hidden; }
  @media (max-width: 600px) {
    .-categories .navigation__content {
      padding: 0 10px; } }

.navigation {
  white-space: nowrap; }
  .navigation.-header {
    overflow: hidden; }
    .navigation.-header ul {
      display: inline-block; }
  .navigation.-main {
    height: 55px; }
    .navigation.-main ul {
      min-width: 180px;
      position: absolute;
      top: 55px;
      right: -80px;
      z-index: 1001;
      display: block;
      background: #14467e;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); }
      .navigation.-main ul li {
        display: block;
        float: none; }
      @media (min-width: 901px) {
        .navigation.-main ul {
          width: auto;
          position: relative;
          top: auto;
          right: auto;
          float: left;
          box-shadow: none; }
          .navigation.-main ul li {
            float: left; } }
    .navigation.-main li:first-child span {
      border: none; }
  .navigation.-carousel {
    width: 100%;
    height: 180px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    line-height: 0;
    text-align: center; }
    .navigation.-carousel li {
      margin-left: -4px;
      position: relative;
      float: none; }
      @media (max-width: 600px) {
        .navigation.-carousel li {
          width: calc(50% - 20px);
          margin-left: 0;
          display: inline-block; } }
    @media (max-width: 600px) {
      .navigation.-carousel {
        height: auto;
        overflow: visible;
        white-space: normal; } }
  .navigation ul::before, .navigation ul::after {
    display: table;
    content: ''; }
  .navigation ul::after {
    clear: both; }
  .navigation li {
    float: left; }

.-carousel .navigation__list {
  min-width: 100%;
  display: inline-flex;
  vertical-align: top; }

.navigation__link {
  display: block;
  color: #fff;
  font-size: 0.92857em;
  line-height: 40px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.05s ease-in-out;
  border-bottom: none; }
  .navigation__link:focus, .navigation__link:hover {
    background: #0d3767;
    transition: background-color 0.5s ease-in-out; }
    .navigation__link:focus span, .navigation__link:hover span {
      transition: border-color 0.5s ease-in-out;
      border-color: #0d3767; }
  .navigation__link:visited {
    color: whitesmoke; }
  .navigation__link.-active {
    background: #fff;
    color: #d4161c; }
    .navigation__link.-active span {
      border-color: #fff; }
  .navigation__link span {
    width: 100%;
    padding: 0 20px; }
    @media (min-width: 901px) {
      .navigation__link span {
        transition: border-color 0.05s ease-in-out;
        border-left: 1px solid #23568d; } }
  @media (min-width: 901px) {
    .navigation__link {
      height: 55px;
      line-height: 55px; } }

.menu {
  float: left; }

.navigation__rest {
  position: relative; }
  @media (min-width: 601px) {
    .navigation__rest {
      padding-right: 70px;
      float: right; } }
  @media (min-width: 901px) {
    .navigation__rest {
      padding-left: 20px; } }

.navigation__dot {
  width: 4px;
  height: 4px;
  float: left;
  background: #fff;
  transform: translate(0, 25px);
  transition: transform 0.2s ease-in-out;
  border-radius: 50%; }
  .navigation__dot + .navigation__dot {
    margin-left: 5px; }
  .-filled .navigation__dot:first-child {
    transform: translate(1px, 23px); }
  .-filled .navigation__dot:nth-child(2) {
    transform: translate(0, 28px); }
  .-filled .navigation__dot:nth-child(3) {
    transform: translate(-1px, 23px); }
  .-clicked .navigation__dot {
    transition: transform 0.2s ease-in-out; }
    .-clicked .navigation__dot:first-child {
      transform: translate(9px, 15px); }
    .-clicked .navigation__dot:nth-child(2) {
      transform: translate(0, 25px); }
    .-clicked .navigation__dot:nth-child(3) {
      transform: translate(-9px, 35px); }

.category__item {
  padding: 40px 15px;
  display: inline-flex;
  justify-content: center;
  vertical-align: top;
  transition: background-color 0.4s ease-in-out; }
  .category__item.-active, .category__item.-active:focus, .category__item.-active:hover {
    background-color: #fff; }
    @media (max-width: 900px) {
      .category__item.-active, .category__item.-active:focus, .category__item.-active:hover {
        background: none;
        color: #14467e;
        font-weight: bold; } }
  .category__item:focus, .category__item:hover {
    background-color: #f4f4f4;
    transition: background-color 0.1s ease-in-out; }
  @media (min-width: 901px) {
    .category__item {
      height: 180px; } }
  @media (max-width: 900px) {
    .category__item {
      padding-right: 10px;
      padding-left: 10px; } }
  .navigation .category__item + .category__item .category__name {
    border: none; }

.category__link {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  border: none; }
  .category__link:focus .category__name, .category__link:hover .category__name {
    text-decoration: underline; }
  .-active .category__link:focus:focus .category__name,
  .-active .category__link:focus:hover .category__name, .-active .category__link:hover:focus .category__name,
  .-active .category__link:hover:hover .category__name {
    text-decoration: none; }

.category__image {
  max-width: 100%; }

.category__name {
  width: 100%;
  margin-top: 10px;
  display: inline-block;
  line-height: 20px;
  text-decoration: none; }

.carousel__navigation {
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  visibility: hidden;
  opacity: 0;
  background-color: #efefef;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out; }
  .-enabled .carousel__navigation {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out; }
  .carousel__navigation.-prev.-inside {
    left: 0; }
  .carousel__navigation.-prev.-outside {
    left: -60px; }
  .carousel__navigation.-next.-inside {
    right: 0; }
  .carousel__navigation.-next.-outside {
    right: -60px; }
  @media (max-width: 300px) {
    .carousel__navigation {
      display: none; } }

.menu__toggle,
.menu__toggle-trigger {
  display: none; }

@media (max-width: 900px) {
  .menu__toggle:checked ~ ul {
    height: auto; } }

.menu__toggle-trigger {
  margin-top: 5px;
  position: relative;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  line-height: 1.3em;
  text-align: center; }
  @media (max-width: 900px) {
    .menu__toggle-trigger {
      display: block; }
      .menu__toggle-trigger ~ ul {
        height: 0;
        overflow: hidden; } }

.menu__toggle-title {
  display: block; }

.lang {
  width: 70px;
  height: 55px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
  overflow: hidden;
  transition: height 0.5s ease-in-out; }
  .lang:focus, .lang:hover {
    height: auto;
    transition: height 0.5s ease-in-out; }
    .lang:focus li:first-child::before, .lang:hover li:first-child::before {
      transform: rotate(90deg);
      transition: transform 0.25s ease-in-out; }
  .lang li {
    z-index: 1001; }
    .lang li:first-child::before {
      position: absolute;
      top: 15px;
      right: 5px;
      z-index: 1;
      font-size: 25px;
      transition: transform 0.5s ease-in-out; }

.lang__item {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  width: 70px;
  height: 55px;
  position: relative;
  display: block;
  background-color: #0d3767;
  line-height: 0;
  transition: background-color 0.5s ease-in-out;
  border: none; }
  .lang__item:focus, .lang__item:hover {
    background-color: #14467e;
    transition: background-color 0.05s ease-in-out; }
  .lang__item::before {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 15px;
    display: block;
    content: '';
    transform: translateY(-50%); }

.slider {
  width: 100%;
  height: 6px;
  margin: 20px auto 40px; }
  @media (max-width: 900px) {
    .slider.-range {
      display: none; } }

.slider__content {
  height: 100%;
  padding: 0 50px 0 0;
  display: flex;
  flex-direction: column; }
  .slider__content + .slider__content {
    padding: 0 0 0 50px; }

@media (min-width: 901px) {
  .slider__input {
    display: none; } }

.slider__target,
.slider__target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
  user-select: none; }

.slider__horizontal {
  position: relative;
  background-color: #d0d0d0;
  border-radius: 6px; }

.slider__base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  transform: translate3d(0, 0, 0);
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px; }
  .-single .slider__base {
    background-color: #14467e; }
  @media (max-width: 600px) {
    .slider__base {
      width: 90%; } }

.slider__origin {
  height: 6px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #d0d0d0; }

.slider__connect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #14467e; }

.slider__handle {
  position: relative;
  left: -10px;
  z-index: 1;
  background-color: #fff;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  transition: box-shadow 0.5s ease-in-out, background-color 0.5s ease-in-out;
  border-radius: 50%;
  border: 4px solid #14467e; }

.slider__active {
  background-color: #14467e;
  box-shadow: 0 0 10px rgba(20, 70, 126, 0.75); }

.slider__handle-lower {
  width: 20px;
  height: 20px;
  top: -7px; }
  .slider__handle-lower .slider__tooltip {
    top: 21px;
    left: -30px; }

.slider__handle-upper {
  width: 26px;
  height: 26px;
  top: -10px; }
  .slider__handle-upper .slider__tooltip {
    top: 24px;
    left: -27px; }

.slider__tooltip {
  width: 80px;
  position: absolute;
  left: -20px;
  color: #14467e;
  font-size: 1em;
  font-weight: bold;
  text-align: center; }

@media (min-width: 301px) {
  .section__container {
    margin-bottom: 40px; } }

@media (min-width: 901px) {
  .section__container {
    margin-bottom: 80px; } }

.section__container:last-child {
  margin-bottom: 0; }

.section__container address,
.section__container p {
  margin-bottom: 0.75rem; }

.section__content {
  width: 100%;
  margin-left: 40px; }
  .contact__container .section__content {
    padding-right: 50px; }
    .contact__container .section__content .row {
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0; }

.section__logo {
  height: 150px;
  margin-top: -1px;
  margin-left: -1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid #efefef; }
  .section__logo img {
    max-width: 100%;
    height: auto; }

.section__phone, .section__email {
  padding: 2.25rem 0;
  text-align: center; }
  p + .section__phone, p + .section__email {
    padding: 0.75rem 0 2.25rem; }
  .section__phone + .section__email, .section__email + .section__email {
    padding-top: 0.75rem; }

.section__phone-content,
.section__email-content {
  padding-left: 70px;
  position: relative;
  display: inline-block;
  color: #d4161c;
  text-align: center; }
  @media (min-width: 301px) {
    .section__phone-content,
    .section__email-content {
      font-size: 1.42857em; } }
  @media (min-width: 601px) {
    .section__phone-content,
    .section__email-content {
      font-size: 2.28571em; } }
  .section__phone-content::before,
  .section__email-content::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }

.section__phone-content::before {
  width: 52px;
  height: 40px;
  background-position: 0px 0px;
  content: ''; }

.section__email-content a {
  color: #d4161c; }

.section__email-content::before {
  content: '\e901';
  color: #14467e;
  font-family: 'icomoon' !important;
  font-size: 44px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.section__button-container {
  display: flex;
  justify-content: center; }

.document {
  margin-bottom: 40px; }
  .document p,
  .document ul {
    margin-bottom: 1.5rem; }
    .document p:last-child,
    .document ul:last-child {
      margin-bottom: 0; }
  .document span,
  .document strong {
    margin-bottom: 0.75rem;
    display: inline-block; }
    .document span:last-child,
    .document strong:last-child {
      margin-bottom: 0; }
  .document li {
    padding-left: 18px;
    position: relative; }
    .document li::before {
      width: 6px;
      height: 6px;
      position: absolute;
      top: 8px;
      left: 0;
      display: block;
      content: '';
      background-color: #d4161c;
      border-radius: 50%; }
  .document .row {
    margin-right: -10px;
    margin-bottom: 3rem;
    margin-left: -10px; }
    .document .row:last-child {
      margin-bottom: 0; }
    .page__nas-tym .document .row {
      margin-right: 0;
      margin-left: 0; }
  .document .block {
    height: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column; }
    .document .block.-bordered, .document form.block.-rounded {
      padding: 20px 40px;
      border: 3px solid #efefef; }
  .document .block__heading {
    width: 100%;
    font-weight: bold; }
  .document [class*='col-'] {
    padding: 0 10px; }
  @media (max-width: 1200px) {
    .page__vykup-vozu .document {
      padding-top: 40px;
      clear: both; } }
  @media (min-width: 1201px) {
    .page__vykup-vozu .document {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (max-width: 1200px) {
    .page__financovani .document {
      padding-top: 40px;
      clear: both; } }
  .page__nas-tym .document {
    margin-bottom: 0; }
  .document .our-team {
    padding: 0;
    position: relative;
    overflow: hidden;
    line-height: 0; }
    .document .our-team:focus .-secondary, .document .our-team:hover .-secondary {
      transform: translateY(0);
      transition: transform 0.4s ease-in-out; }
    .document .our-team img {
      width: 100%;
      max-width: 100%;
      height: auto;
      line-height: 0; }
  .document .our-team__detail.-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    @media (min-width: 301px) {
      .document .our-team__detail.-main {
        padding: 10%; } }
    @media (min-width: 601px) {
      .document .our-team__detail.-main {
        padding: 5%; } }
    @media (min-width: 1201px) {
      .document .our-team__detail.-main {
        padding: 3%; } }
    .document .our-team__detail.-main .-name {
      margin-bottom: 1.5rem;
      font-size: 1.71429em; }
    .document .our-team__detail.-main .-name,
    .document .our-team__detail.-main .-e-mail {
      color: #d4161c; }
      .document .our-team__detail.-main .-name a,
      .document .our-team__detail.-main .-e-mail a {
        color: #d4161c; }
    .document .our-team__detail.-main .-phone {
      font-size: 1.14286em; }
    .document .our-team__detail.-main .-position {
      color: #14467e; }
  .document .our-team__detail.-secondary {
    width: 100%;
    height: 100%;
    padding: 20px 10px 40px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: rgba(0, 85, 150, 0.9);
    color: #fff;
    text-align: center;
    transform: translateY(100%);
    transition: transform 0.2s ease-in-out; }
    .document .our-team__detail.-secondary .-name {
      margin-bottom: 0.75rem;
      font-size: 1.57143em;
      font-weight: bold; }
    .document .our-team__detail.-secondary .-e-mail {
      margin-bottom: 2.25rem; }
      .document .our-team__detail.-secondary .-e-mail a {
        color: #7ab2ef; }
  .document .our-team__description {
    margin-bottom: 0;
    display: block;
    font-size: 1em;
    line-height: 1.2; }
    .document .our-team__description.-position {
      margin-bottom: 1.5rem;
      font-weight: bold;
      text-transform: uppercase; }
    .document .our-team__description.-text {
      margin-bottom: 1.5rem; }
  .document .our-team__languages li {
    padding-left: 0;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.85714em; }
    .document .our-team__languages li::before {
      display: none; }
  .document .our-team__lang {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    width: 22px;
    height: 16px;
    position: relative;
    display: block;
    line-height: 0; }
    .document .our-team__lang::before {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: ''; }

.link__list {
  font-weight: bold;
  text-transform: uppercase; }
  .link__list li {
    display: flex;
    align-items: flex-start; }
  .detail .link__list {
    text-align: right; }
    .detail .link__list li {
      margin-bottom: 8px;
      justify-content: flex-end; }
  .link__list .link__list-item {
    padding-right: 24px;
    background-position: right 4px top 4px;
    line-height: 20px;
    text-decoration: underline;
    text-transform: uppercase;
    transition: background-position 0.5s ease-in-out;
    border: none; }
    .link__list .link__list-item:focus, .link__list .link__list-item:hover {
      background-position: right top 4px;
      transition: background-position 0.5s ease-in-out; }

.contact__container {
  margin-bottom: 1.5rem;
  position: relative; }
  @media (max-width: 600px) {
    .contact__container {
      padding: 10px; } }
  @media (min-width: 901px) {
    .contact__container:nth-child(odd)::before {
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: calc(100% - 40px);
      display: flex;
      content: '';
      background-color: #efefef; }
    .contact__container[class*='col-'],
    .contact__container [class*='col-'] {
      padding: 0; } }
  @media (min-width: 1201px) {
    .contact__container:nth-last-child(n+2)::before {
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: calc(100% - 40px);
      display: flex;
      content: '';
      background-color: #efefef; } }

@media (min-width: 301px) {
  .form__purchase-container,
  .form__loan-container {
    width: 100%;
    margin-bottom: 1.5rem; } }

@media (min-width: 1201px) {
  .form__purchase-container,
  .form__loan-container {
    width: 45%;
    margin-left: 5%; } }

.page__error .-section {
  min-height: 600px; }

.reference__single-item {
  margin: 0.75rem 0;
  padding: 20px 0; }
  .reference__single-item .reference__single-item-image {
    width: 140px;
    height: 140px;
    margin: 0 auto 15px;
    background-color: #efefef;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    text-align: center;
    border-radius: 50%; }
  .reference__single-item .reference__single-item-text {
    font-size: 0.85714em;
    font-style: italic; }
  .reference__single-item .reference__single-item-referer,
  .reference__single-item .reference__single-item-company {
    margin-bottom: 0;
    font-size: 0.92857em; }

.catalog {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background: #efefef; }
  @media (min-width: 301px) {
    .catalog {
      padding: 20px 1%; } }
  @media (min-width: 601px) {
    .catalog {
      padding-top: 30px;
      padding-bottom: 60px; } }
  @media (min-width: 1201px) {
    .catalog {
      padding-top: 40px;
      padding-bottom: 80px; } }
  @media (min-width: 1501px) {
    .catalog {
      padding-top: 50px;
      padding-bottom: 100px; } }
  .catalog.-carousel {
    flex-flow: column wrap;
    align-items: center; }
    @media (min-width: 301px) {
      .catalog.-carousel {
        padding: 0; } }
    .catalog.-carousel h3 {
      text-align: center; }
  @media (min-width: 301px) {
    .catalog .similar {
      padding: 20px 0; } }
  .catalog .car {
    width: 280px;
    height: 450px;
    margin-bottom: 20px;
    padding: 20px 10px 0;
    z-index: 4;
    opacity: 0;
    will-change: opacity, transform;
    animation: catalog 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation-fill-mode: forwards;
    transition: padding 0.5s ease-in-out; }
    .catalog .car.-anchored .car__content::before {
      will-change: opacity;
      animation: catalog-pulse 3s ease-in-out 1s;
      animation-fill-mode: forwards; }
    @media (min-width: 601px) {
      .catalog .car:focus, .catalog .car:hover {
        padding-top: 0;
        z-index: 5;
        transition: padding 0.5s ease-in-out; }
        .catalog .car:focus .car__heading, .catalog .car:hover .car__heading {
          height: 80px;
          color: #14467e;
          transition: color 0.5s ease-in-out, height 0.5s ease-in-out; }
        .catalog .car:focus .car__content, .catalog .car:hover .car__content {
          width: calc(100% - 20px);
          height: 515px;
          position: absolute;
          transition: height 0.5s ease-in-out; }
          .catalog .car:focus .car__content::before, .catalog .car:hover .car__content::before {
            opacity: 1;
            transition: opacity 0.5s ease-in-out; }
          .catalog .car:focus .car__content .button, .catalog .car:hover .car__content .button {
            opacity: 1;
            transition: opacity 0.5s ease-in-out 0.2s; }
            .catalog .car:focus .car__content .button:focus, .catalog .car:focus .car__content .button:hover, .catalog .car:hover .car__content .button:focus, .catalog .car:hover .car__content .button:hover {
              transition: background-position 0.05s ease-in-out; }
        .catalog .car:focus .car__image img, .catalog .car:hover .car__image img {
          transform: scale(1.05, 1.05);
          transition: transform 0.5s ease-in-out; }
      .catalog .car:nth-child(7) {
        animation-delay: .1s; }
      .catalog .car:nth-child(6) {
        animation-delay: .15s; }
      .catalog .car:nth-child(3) {
        animation-delay: .2s; }
      .catalog .car:nth-child(4) {
        animation-delay: .25s; }
      .catalog .car:nth-child(10) {
        animation-delay: .3s; }
      .catalog .car:nth-child(8) {
        animation-delay: .35s; }
      .catalog .car:nth-child(2) {
        animation-delay: .4s; }
      .catalog .car:nth-child(9) {
        animation-delay: .45s; }
      .catalog .car:nth-child(5) {
        animation-delay: .5s; } }
    @media (max-width: 600px) {
      .catalog .car {
        width: 100%;
        height: auto;
        padding-right: 20px;
        padding-left: 20px; } }
    .ie9 .catalog .car {
      opacity: 1; }
  .catalog .car__content {
    width: 100%;
    height: 450px;
    position: relative;
    background: #fff;
    transition: height 0.5s ease-in-out;
    cursor: pointer; }
    .catalog .car__content::before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      content: '';
      transition: opacity 0.5s ease-in-out;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.3); }
    .catalog .car__content .button {
      width: calc(100% - (2 * 20px));
      max-width: 200px;
      margin-top: 20px;
      position: absolute;
      bottom: 20px;
      left: 50%;
      opacity: 0;
      line-height: 46px;
      transform: translateX(-50%);
      transition: opacity 0.25s ease-in-out; }
      @media (max-width: 600px) {
        .catalog .car__content .button {
          width: calc(100% - 20px);
          height: 42px;
          margin-top: 5px;
          margin-bottom: 10px;
          position: relative;
          bottom: 0;
          opacity: 1;
          font-size: 0.78571em;
          line-height: 41px; } }
    @media (max-width: 600px) {
      .catalog .car__content {
        height: auto;
        padding-bottom: 15px; } }
  .catalog .car__heading {
    width: 100%;
    height: 60px;
    padding: 0 10px 0 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    line-height: 1.2;
    font-weight: bold;
    transition: color 0.5s ease-in-out, height 0.5s ease-in-out; }
    @media (max-width: 600px) {
      .catalog .car__heading {
        padding: 0 5px 0 10px; } }
  .catalog .car__brand-logo {
    max-width: 24px;
    max-height: 24px;
    margin-right: 20px; }
    @media (max-width: 600px) {
      .catalog .car__brand-logo {
        margin-right: 10px; } }
  .catalog .car__image {
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25%;
    text-align: center; }
    .catalog .car__image a {
      width: 100%;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border: none; }
      @media (max-width: 600px) {
        .catalog .car__image a {
          height: auto; } }
    .catalog .car__image img {
      max-width: 100%;
      max-height: 170px;
      transform: scale(1, 1);
      transition: transform 0.5s ease-in-out; }
      @media (max-width: 600px) {
        .catalog .car__image img {
          max-height: none; } }
    @media (max-width: 600px) {
      .catalog .car__image {
        margin-bottom: 15px; } }
  .catalog .car__description {
    width: 100%;
    margin-bottom: 20px; }
    @media (max-width: 600px) {
      .catalog .car__description {
        margin-bottom: 10px; } }
  .catalog .car__description-title {
    padding-right: 5px;
    color: #919191;
    text-align: right; }
  .catalog .car__description-value {
    padding-left: 5px;
    color: #000; }
  .catalog .car__price {
    margin-bottom: 5px;
    color: #14467e;
    font-size: 24px;
    line-height: 1;
    font-weight: bold;
    text-align: center; }
    @media (max-width: 600px) {
      .catalog .car__price {
        margin-bottom: 0;
        font-size: 22px; } }
  .catalog .car__price-info {
    margin-bottom: 10px;
    color: #919191;
    font-size: .9em;
    text-align: center; }

.detail {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background: #efefef; }
  @media (min-width: 601px) {
    .detail {
      padding-top: 0; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .detail .car {
      margin-left: 0; } }
  .detail .car__heading {
    padding: 22px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    background-color: #2a2829;
    color: #fff;
    font-size: 1.42857em;
    line-height: 1.2;
    font-weight: bold; }
    @media (max-width: 300px) {
      .detail .car__heading {
        height: 70px;
        font-size: 1.14286em; } }
    @media (min-width: 301px) {
      .detail .car__heading {
        flex-direction: column; } }
    @media (min-width: 601px) {
      .detail .car__heading {
        padding-right: 20px;
        padding-left: 20px; } }
    @media (min-width: 901px) {
      .detail .car__heading {
        padding-right: 40px;
        padding-left: 40px;
        flex-direction: row; } }
    @media (min-width: 1201px) {
      .detail .car__heading {
        padding-right: 50px;
        padding-left: 50px; } }
  .detail .car__heading-text {
    width: 100%; }
    @media (min-width: 301px) {
      .detail .car__heading-text {
        margin-bottom: 20px;
        font-size: 14px; } }
    @media (min-width: 901px) {
      .detail .car__heading-text {
        max-width: 50%;
        margin-bottom: 0;
        font-size: 18px; } }
  .detail .car__heading-options {
    display: flex;
    align-items: center; }
    @media (min-width: 301px) {
      .detail .car__heading-options {
        align-self: flex-end; } }
    @media (min-width: 901px) {
      .detail .car__heading-options {
        align-self: center; } }
  .detail .car__heading-counter {
    min-height: 36px;
    padding: 5px 10px;
    display: inline-block;
    background: #d4161c; }
    .detail .car__heading-counter strong {
      margin-left: 5px; }
    @media (min-width: 301px) {
      .detail .car__heading-counter {
        font-size: 12px; }
        .detail .car__heading-counter strong {
          font-size: 16px; } }
    @media (min-width: 901px) {
      .detail .car__heading-counter {
        font-size: 14px; }
        .detail .car__heading-counter strong {
          font-size: 20px; } }
  .detail .car__backlink {
    position: relative;
    right: -12px;
    color: #fff;
    font-size: 180%;
    border-bottom: none; }
    .detail .car__backlink .shape-times {
      position: relative; }
      .detail .car__backlink .shape-times::after, .detail .car__backlink .shape-times::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        content: '';
        background: #fff;
        transform: translate(-50%, -50%) rotate(-45deg); }
      .detail .car__backlink .shape-times::after {
        width: 2px;
        height: 32px; }
      .detail .car__backlink .shape-times::before {
        width: 32px;
        height: 2px; }
  .detail .car__backlink-sign {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    width: 46px;
    height: 46px;
    display: block;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out; }
    .detail .car__backlink-sign:focus, .detail .car__backlink-sign:hover {
      transform: rotate(90deg);
      transition: transform 0.3s ease-in-out; }
  .detail .car__section {
    background-color: #fff;
    border-top: 10px solid #efefef; }
    @media (min-width: 301px) {
      .detail .car__section {
        padding: 20px 20px 0; } }
    @media (min-width: 901px) {
      .detail .car__section {
        padding: 30px 40px 10px; }
        .detail .car__section:nth-child(2) {
          border-left: 10px solid #efefef; } }
    @media (min-width: 1201px) {
      .detail .car__section {
        padding: 40px 50px 20px; } }
    @media (min-width: 1501px) {
      .detail .car__section + .car__section {
        border-left: 10px solid #efefef; } }
    .detail .car__section.-no-bordered {
      border-left: none !important; }
    .detail .car__section.-direction-middle {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .detail .car__section > .row + .row {
      margin-bottom: 20px; }
    .detail .car__section p {
      margin-bottom: 20px; }
    .detail .car__section .-bordered-top {
      padding-top: 20px;
      position: relative; }
      .detail .car__section .-bordered-top::before {
        height: 1px;
        position: absolute;
        top: 0;
        content: '';
        background-color: #efefef; }
        @media (min-width: 301px) {
          .detail .car__section .-bordered-top::before {
            width: calc(100% + 20px);
            left: -10px; } }
        @media (min-width: 601px) {
          .detail .car__section .-bordered-top::before {
            width: calc(100% + 50px);
            left: -25px; } }
        @media (min-width: 901px) {
          .detail .car__section .-bordered-top::before {
            width: calc(100% + 100px);
            left: -50px; } }
  .detail .car__description {
    line-height: 20px; }
  .detail .car__description-title {
    padding-right: 10px;
    color: #919191; }
    @media (min-width: 301px) {
      .detail .car__description-title {
        font-size: 11px; } }
    @media (min-width: 601px) {
      .detail .car__description-title {
        font-size: 12px; } }
    @media (min-width: 1201px) {
      .detail .car__description-title {
        font-size: 14px; } }
  .detail .car__description-value {
    padding-left: 10px; }
    @media (min-width: 301px) {
      .detail .car__description-value {
        font-size: 11px; } }
    @media (min-width: 601px) {
      .detail .car__description-value {
        font-size: 12px; } }
    @media (min-width: 1201px) {
      .detail .car__description-value {
        font-size: 14px; } }
  .detail .car__price {
    height: 70px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d4161c;
    color: #fff;
    text-align: center; }
  .detail .car__price-content {
    font-size: 1em;
    line-height: 1.6em; }
    .detail .car__price-content strong {
      margin-right: 12px;
      margin-left: 12px;
      white-space: nowrap;
      font-size: 2em; }
  .detail .car__link {
    background-repeat: no-repeat;
    color: #14467e; }
  .detail .car__vendor {
    margin-bottom: 20px;
    padding-top: 10px;
    font-size: 1.07143em;
    text-align: center; }
    .detail .car__vendor p {
      margin-bottom: 10px; }
      .detail .car__vendor p strong {
        font-size: 1.5em; }
    .detail .car__vendor .car__vendor-phone {
      font-size: 1.28571em;
      line-height: 1.4; }
  .detail .car__vendor-image {
    width: 110px;
    height: 110px;
    margin: 0 auto 20px; }
    .detail .car__vendor-image img {
      width: 100%;
      max-width: 110px;
      border-radius: 50%; }
    @media (min-width: 601px) {
      .detail .car__vendor-image {
        float: left; } }
  @media (min-width: 601px) {
    .detail .car__vendor-data {
      margin-left: 130px;
      text-align: left; } }
  .detail .car__vendor-operative-times {
    padding-top: 15px;
    text-align: left; }
  .detail .car__vendor-location {
    min-width: 70px;
    padding: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 6;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    border-radius: 2px; }
    .detail .car__vendor-location:empty {
      display: none; }
  .detail .car__claim {
    padding: 20px;
    display: block;
    text-align: center;
    font-style: italic; }

.car__backlink-bottom {
  padding: 30px 0;
  background: #efefef;
  text-align: center; }

.accessories__list {
  color: #919191; }
  .accessories__list li {
    margin-bottom: 8px; }
    @media (max-width: 1500px) {
      .accessories__list li {
        display: inline-block; }
        .accessories__list li::before {
          content: ' ~ ';
          color: #000; } }

.similar {
  position: relative; }
  @media (min-width: 301px) {
    .similar {
      width: 100%; } }
  @media (min-width: 601px) {
    .similar {
      width: 560px; } }
  @media (min-width: 901px) {
    .similar {
      width: 840px; } }
  @media (min-width: 1201px) {
    .similar {
      width: 1120px; } }
  @media (min-width: 1501px) {
    .similar {
      width: 1400px; } }
  @media (min-width: 1801px) {
    .similar {
      width: 1680px; } }

.similar__content {
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden; }
  .similar__content li {
    position: relative; }

.similar__list {
  display: inline-flex;
  vertical-align: top; }
  @media (max-width: 600px) {
    .similar__list {
      display: inline-block; } }

.filter__container {
  width: 100%;
  top: 55px;
  z-index: 1000;
  background-color: #fff; }
  .filter__container[style*="fixed"] {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); }

.filter__content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  opacity: 0;
  will-change: opacity;
  animation: load 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-fill-mode: forwards; }
  .ie9 .filter__content {
    opacity: 1; }
  @media (min-width: 301px) {
    .filter__content {
      padding: 66px 20px 75px; }
      .filter__content.-active {
        padding: 20px; } }
  @media (min-width: 601px) {
    .filter__content {
      padding-top: 20px;
      padding-right: 80px;
      padding-left: 40px; } }
  @media (min-width: 901px) {
    .filter__content {
      padding-left: 80px; } }

.search {
  height: 55px;
  position: relative;
  float: left;
  background: #d4161c;
  color: #fff; }
  @media (min-width: 301px) {
    .search {
      width: 100%; } }
  @media (min-width: 601px) {
    .search {
      width: 280px; } }

.search__field {
  width: calc(100% - 55px);
  height: 55px;
  padding: 0 10px;
  float: left;
  font-size: 0.85714em;
  line-height: 40px;
  font-weight: bold;
  text-transform: uppercase; }
  .search__field, .search__field::placeholder {
    color: #fff; }

.search__button {
  width: 55px;
  height: 55px;
  float: left; }
  .search__button .search__button-icon {
    font-size: 20px; }

.whisperer {
  max-height: 75vh;
  width: 100%;
  height: 0;
  position: absolute;
  top: 55px;
  left: 0;
  overflow-y: auto;
  background: #d4161c; }
  @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    .whisperer {
      max-height: 768px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
    .whisperer {
      max-height: 576px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
    .whisperer {
      max-height: 360px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
    .whisperer {
      max-height: 240px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
    .whisperer {
      max-height: 426px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
    .whisperer {
      max-height: 240px; } }
  .whisperer.-active {
    height: auto; }

.whisperer__list {
  list-style-type: none; }
  .whisperer__list li + li {
    border-top: 1px solid #e6181e; }

.whisperer__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  transition: background 0.5s ease-in-out;
  border-bottom: none; }
  .whisperer__item:focus, .whisperer__item:hover {
    background: #e92a30;
    transition: background 0.05s ease-in-out; }

.whisperer__image {
  width: 80px;
  height: 55px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  text-align: center; }
  .whisperer__image img {
    width: 100%;
    max-width: 80px;
    max-height: 55px; }

.whisperer__title {
  width: calc(100% - 80px);
  padding: 5px;
  display: inline-flex; }

.whisperer__empty-message {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase; }

.slideshow, .slideshow-car {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10%;
  text-align: center; }

.slideshow {
  width: 100%;
  position: relative;
  z-index: 1; }
  .slideshow:focus .slideshow__navigation, .slideshow:hover .slideshow__navigation {
    visibility: visible; }
  .slideshow .slideshow__list {
    width: 100%;
    position: fixed;
    left: 0;
    overflow: hidden;
    line-height: 0; }
    @media (min-width: 301px) {
      .slideshow .slideshow__list {
        top: 110px; } }
    @media (min-width: 601px) {
      .slideshow .slideshow__list {
        top: 55px; } }
  .slideshow .slideshow__item {
    background-color: #efefef; }
    @media (max-width: 600px) {
      .slideshow .slideshow__item {
        left: 0; } }
  .slideshow .slideshow__scene {
    height: 100%; }
  .slideshow .slideshow__layer {
    width: 100%;
    height: 100%;
    min-height: 100%; }
  .slideshow .slideshow__item-description {
    font-size: 4vw;
    position: absolute;
    top: 20%;
    left: 10%;
    color: #fff;
    line-height: 1.1;
    font-weight: bold;
    text-align: left; }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
      .slideshow .slideshow__item-description {
        font-size: 30.72px; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
      .slideshow .slideshow__item-description {
        font-size: 40.96px; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: portrait) {
      .slideshow .slideshow__item-description {
        font-size: 12.8px; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 480px) and (orientation: landscape) {
      .slideshow .slideshow__item-description {
        font-size: 19.2px; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: portrait) {
      .slideshow .slideshow__item-description {
        font-size: 12.8px; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (device-width: 320px) and (device-height: 568px) and (orientation: landscape) {
      .slideshow .slideshow__item-description {
        font-size: 22.72px; } }
  .slideshow .slideshow__parallax-background {
    width: auto;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
    .slideshow .slideshow__parallax-background img {
      width: 105%;
      height: auto;
      position: absolute;
      bottom: 0;
      left: -2.5%; }
  .slideshow .slideshow__background {
    width: 100%;
    height: auto; }
  .slideshow .slideshow__navigation {
    width: 5%;
    height: calc(100vh - (55px * 2) - 180px);
    position: fixed;
    top: 55px;
    z-index: 2;
    visibility: hidden; }
    .slideshow .slideshow__navigation.-prev {
      left: 0; }
    .slideshow .slideshow__navigation.-next {
      right: 0; }

.slideshow__item {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  visibility: hidden; }

.slideshow__current {
  position: relative;
  visibility: visible; }

.slideshow__item-hide-prev,
.slideshow__item-hide-next {
  z-index: 2;
  visibility: visible;
  will-change: opacity, visibility;
  animation: fadeOut 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both; }

.slideshow__item-show-prev,
.slideshow__item-show-next {
  z-index: 1; }

.slideshow__scene .slideshow__image {
  position: absolute;
  bottom: 0;
  left: 0; }

.slideshow-car {
  position: relative; }
  @media (min-width: 301px) {
    .slideshow-car {
      min-height: 130px; } }
  @media (min-width: 601px) {
    .slideshow-car {
      min-height: 230px; } }
  @media (min-width: 901px) {
    .slideshow-car {
      min-height: 390px; } }
  @media (min-width: 1201px) {
    .slideshow-car {
      min-height: 550px; } }
  @media (min-width: 1501px) {
    .slideshow-car {
      min-height: 720px; } }
  @media (min-width: 1801px) {
    .slideshow-car {
      min-height: 810px; } }
  .slideshow-car .slideshow__list {
    width: 100%;
    height: 100%;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    line-height: 0; }
  .slideshow-car .slideshow__item {
    position: absolute;
    right: 0;
    bottom: 0; }
    .slideshow-car .slideshow__item img {
      width: 100%; }
  .slideshow-car .slideshow__navigation-container {
    width: 100%;
    height: 25px;
    padding: 0 36px;
    position: relative;
    bottom: 50px;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%); }
  .slideshow-car .slideshow__navigation {
    width: 30px;
    height: 100%;
    display: inline-block; }
    .slideshow-car .slideshow__navigation.-overlay {
      width: 50px;
      position: absolute;
      top: 0;
      z-index: 7;
      opacity: .5;
      transition: opacity 0.5s ease-in-out; }
      .slideshow-car .slideshow__navigation.-overlay:focus, .slideshow-car .slideshow__navigation.-overlay:hover {
        opacity: 1;
        transition: opacity 0.05s ease-in-out; }
    .slideshow-car .slideshow__navigation.-prev {
      left: 0; }
    .slideshow-car .slideshow__navigation.-next {
      right: 0; }
  .slideshow-car .slideshow__goto {
    width: 20px;
    height: 20px;
    margin: 0 2px;
    padding: 5px;
    border-radius: 50%;
    border: solid 3px #fff; }
    .slideshow-car .slideshow__goto.-active {
      background-color: #d4161c; }
    @media (min-width: 301px) {
      .slideshow-car .slideshow__goto {
        display: none; } }
    @media (min-width: 1201px) {
      .slideshow-car .slideshow__goto {
        display: inline-block; } }

.newsletter__container {
  padding: 0 0 30px;
  background-color: #efefef; }
  .newsletter__container [class*='col-'] {
    padding-bottom: 15px; }
  .newsletter__container .button {
    white-space: nowrap; }
    @media (max-width: 600px) {
      .newsletter__container .button {
        margin: 0 auto;
        display: block; } }
    @media (min-width: 601px) {
      .newsletter__container .button {
        margin-left: 15px; } }

.newsletter__text,
.newsletter__field {
  margin-bottom: 20px; }

.newsletter__button {
  margin-top: 15px; }

@media (min-width: 601px) {
  .newsletter__form {
    display: flex; }
  .newsletter__button {
    margin-top: 0; } }

@media (min-width: 1201px) {
  .newsletter {
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    align-items: flex-end; }
  .newsletter__text {
    margin-bottom: 0; } }

.map {
  height: 500px;
  background-color: #faf8f9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10%;
  background-clip: content-box;
  border: 3px solid #efefef; }

.lazyload {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0; }

.lazyloaded {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
  transition: opacity 0.4s ease-in-out; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?7d1jtb");
  src: url("../fonts/icomoon.eot?7d1jtb#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?7d1jtb") format("truetype"), url("../fonts/icomoon.woff?7d1jtb") format("woff"), url("../fonts/icomoon.svg?7d1jtb#icomoon") format("svg"); }

[class^='v-icon-'],
[class*=' v-icon-'],
.selectbox__content::before,
.lang li:first-child::before {
  color: #fff;
  font-family: 'icomoon' !important;
  speak: none;
  line-height: 1;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .page__kontakt .-section [class^='v-icon-'], .page__kontakt .-section
  [class*=' v-icon-'],
  .page__kontakt .-section .selectbox__content::before,
  .page__kontakt .-section .lang li:first-child::before,
  .lang .page__kontakt .-section li:first-child::before {
    color: #14467e; }

[class^='v-icon-'],
[class*=' v-icon-'] {
  font-size: 30px; }

.v-icon-search {
  color: #fff; }
  .v-icon-search::before {
    content: '\e900'; }

.v-icon-envelope {
  color: #d4161c;
  font-size: 26px; }
  .v-icon-envelope::before {
    content: '\e901'; }

.v-icon-pencil::before {
  content: '\e902'; }

.v-icon-phone::before {
  content: '\e903'; }

.v-icon-clock::before {
  content: '\e904'; }

.v-icon-check::before {
  content: '\e905'; }

.v-icon-location::before {
  content: '\e906'; }

.v-icon-pin::before {
  content: '\e907'; }

.v-icon-arrow-up::before, .v-icon-arrow-up-red::before, .v-icon-arrow-up-white::before, .v-icon-arrow-up-blue::before {
  content: '\e908'; }

.v-icon-arrow-right::before, .v-icon-arrow-right-red::before, .v-icon-arrow-right-white::before, .lang li:first-child::before, .v-icon-arrow-right-blue::before {
  content: '\e909'; }

.v-icon-arrow-left::before, .v-icon-arrow-left-red::before, .v-icon-arrow-left-white::before, .v-icon-arrow-left-blue::before {
  content: '\e90a'; }

.v-icon-arrow-down::before, .v-icon-arrow-down-red::before, .v-icon-arrow-down-white::before, .v-icon-arrow-down-blue::before, .selectbox__content::before {
  content: '\e90b'; }

.v-icon-arrow-up-red::before {
  color: #d4161c; }

.v-icon-arrow-up-white::before {
  color: #fff; }

.v-icon-arrow-up-blue::before {
  color: #14467e; }

.v-icon-arrow-right-red::before {
  color: #d4161c; }

.v-icon-arrow-right-white::before, .lang li:first-child::before {
  color: #fff; }

.v-icon-arrow-right-blue::before {
  color: #14467e; }

.v-icon-arrow-left-red::before {
  color: #d4161c; }

.v-icon-arrow-left-white::before {
  color: #fff; }

.v-icon-arrow-left-blue::before {
  color: #14467e; }

.v-icon-arrow-down-red::before {
  color: #d4161c; }

.v-icon-arrow-down-white::before {
  color: #fff; }

.v-icon-arrow-down-blue::before, .selectbox__content::before {
  color: #14467e; }

.icon__container {
  padding-left: 40px;
  position: relative; }
  .icon__container .v-icon {
    width: 30px;
    position: absolute;
    top: -4px;
    left: 0;
    text-align: center; }

.icon {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  line-height: 1;
  border: none; }
  .icon__container .icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }

.elevator {
  position: fixed;
  right: 8px;
  bottom: 8px;
  z-index: 9998;
  opacity: 0;
  background-color: #d4161c;
  cursor: pointer;
  border-radius: 5px; }
  .elevator .v-icon {
    color: #f5f5f5;
    font-size: 48px; }

.content__articles .-article {
  padding: 5px 0 5px 17px;
  border-left: solid 5px #d4161c; }
  .content__articles .-article .-article-heading {
    margin-bottom: 4px;
    color: #d4161c;
    font-size: 21px; }

::-moz-selection {
  background: #14467e;
  color: #fff; }

::selection {
  background: #14467e;
  color: #fff; }
