// Buttons
button,
input[type=submit] {
    line-height: 1.1em;
}

.button {
    white-space: normal;
    font-size: 13px;
    text-transform: uppercase;
    border-radius: $button_height;
    border-bottom: none;

    &.-green {@extend %button-red;}
    &.-blue {@extend %button-blue;}
    &.-yellow {@extend %button-yellow;}
    &.-red {@extend %button-red;}
    &.-gray-red {@extend %button-gray-red;}
    &.-gray-blue {@extend %button-gray-blue;}

    &.-brands {display: none;}

    &.-arrow-right-white {
        @extend %image_arrow-right-white;
        padding-right: $button_padding-right + 10px;
        background-repeat: no-repeat;
        background-position: right 19px center;
        transition: background-position $base_transition-duration-out $base_transition-timing;

        &:focus,
        &:hover {
            background-position: right 17px center;
            transition: background-position $base_transition-duration-out $base_transition-timing;
        }
    }

    &.-arrow-left-white {
        @extend %image_arrow-left-white;
        padding-left: $button_padding-right + 10px;
        background-repeat: no-repeat;
        background-position: 19px center;
        transition: background-position $base_transition-duration-out $base_transition-timing;

        &:focus,
        &:hover {
            background-position: 17px center;
            transition: background-position $base_transition-duration-out $base_transition-timing;
        }
    }

    &.-arrow-right-red {
        @extend %image_arrow-right-red;
        padding-right: $button_padding-right + 10px;
        background-repeat: no-repeat;
        background-position: right 19px center;
        transition: background-position $base_transition-duration-out $base_transition-timing;

        &:focus,
        &:hover {
            background-position: right 17px center;
            transition: background-position $base_transition-duration-out $base_transition-timing;
        }
    }

    &.-arrow-left-red {
        @extend %image_arrow-left-red;
        padding-left: $button_padding-right + 10px;
        background-repeat: no-repeat;
        background-position: 19px center;
        transition: background-position $base_transition-duration-out $base_transition-timing;

        &:focus,
        &:hover {
            background-position: 17px center;
            transition: background-position $base_transition-duration-out $base_transition-timing;
        }
    }
}

.button__text {
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
