// Helpers
.visuallyhidden {@include visually-hidden;}

// Colors
%color-green {color: $color-green !important;}
%color-blue {color: $color-blue !important;}
%color-yellow {color: $color-yellow !important;}
%color-red {color: $color-red !important;}
%color-white {color: $color-white !important;}
%color-black {color: $color-black !important;}

.color-green {@extend %color-green;}
.color-blue {@extend %color-blue;}
.color-yellow {@extend %color-yellow;}
.color-red {@extend %color-red;}
.color-white {@extend %color-white;}
.color-black {@extend %color-black;}

// Backgrounds
%background-green {background-color: $color-green !important;}
%background-blue {background-color: $color-blue !important;}
%background-yellow {background-color: $color-yellow !important;}
%background-red {background-color: $color-red !important;}
%background-white {background-color: $color-white !important;}
%background-black {background-color: $color-black !important;}

// Text
.text-italic {@extend %text-italic;}

%text-italic {font-style: italic;}

// Buttons
%button-green {@include _button-variant($color-green,darken($color-green,8%),$color-white,$color-white);}
%button-blue {@include _button-variant($color-blue,darken($color-blue,8%),$color-white,$color-white);}
%button-yellow {@include _button-variant($color-yellow,darken($color-yellow,8%),$color-white,$color-white);}
%button-red {@include _button-variant($color-red,darken($color-red,8%),$color-white,$color-white);}
%button-gray-red {@include _button-variant($color-light-gray,darken($color-light-gray,8%),$color-red,$color-red);}
%button-gray-blue {@include _button-variant($color-light-gray,darken($color-light-gray,8%),$color-blue,$color-blue);}

// Images
%sprite-image {background-image: resolve($spritesheet-image);}
%loader-image {background-image: resolve('#{$theme_name}/loader.svg');}

%triangle-right-white {
    @extend %sprite-image;
    @include sprite-width($icon-triangle-right-white);
    @include sprite-height($icon-triangle-right-white);
    @include sprite-position($icon-triangle-right-white);
}

%triangle-left-white {
    @extend %sprite-image;
    @include sprite-width($icon-triangle-left-white);
    @include sprite-height($icon-triangle-left-white);
    @include sprite-position($icon-triangle-left-white);
}

%image_arrow-right-white {background-image: resolve('#{$theme_name}/icon_arrow-right-white.png');}
%image_arrow-left-white {background-image: resolve('#{$theme_name}/icon_arrow-left-white.png');}
%image_arrow-bottom-white {background-image: resolve('#{$theme_name}/icon_arrow-bottom-white.png');}

.-arrow-right-blue {
    @extend %image_arrow-right-blue;
    background-repeat: no-repeat;
}

.-arrow-left-blue {
    @extend %image_arrow-left-blue;
    background-repeat: no-repeat;
}

%image_arrow-right-blue {background-image: resolve('#{$theme_name}/icon_arrow-right-blue.png');}
%image_arrow-left-blue {background-image: resolve('#{$theme_name}/icon_arrow-left-blue.png');}
%image_arrow-bottom-blue {background-image: resolve('#{$theme_name}/icon_arrow-bottom-blue.png');}

.-arrow-right-red {
    @extend %image_arrow-right-red;
    background-repeat: no-repeat;
}

.-arrow-left-red {
    @extend %image_arrow-left-red;
    background-repeat: no-repeat;
}

%image_arrow-right-red {background-image: resolve('#{$theme_name}/icon_arrow-right-red.png');}
%image_arrow-left-red {background-image: resolve('#{$theme_name}/icon_arrow-left-red.png');}
%image_arrow-bottom-red {background-image: resolve('#{$theme_name}/icon_arrow-bottom-red.png');}

// Lang icons
%image_lang-bg {
    @include sprite-width($icon-lang-bg);
    @include sprite-height($icon-lang-bg);
    @include sprite-position($icon-lang-bg);
}

%image_lang-cro {
    @include sprite-width($icon-lang-cro);
    @include sprite-height($icon-lang-cro);
    @include sprite-position($icon-lang-cro);
}

%image_lang-cs {
    @include sprite-width($icon-lang-cs);
    @include sprite-height($icon-lang-cs);
    @include sprite-position($icon-lang-cs);
}

%image_lang-de {
    @include sprite-width($icon-lang-de);
    @include sprite-height($icon-lang-de);
    @include sprite-position($icon-lang-de);
}

%image_lang-en {
    @include sprite-width($icon-lang-en);
    @include sprite-height($icon-lang-en);
    @include sprite-position($icon-lang-en);
}

%image_lang-es {
    @include sprite-width($icon-lang-es);
    @include sprite-height($icon-lang-es);
    @include sprite-position($icon-lang-es);
}

%image_lang-fr {
    @include sprite-width($icon-lang-fr);
    @include sprite-height($icon-lang-fr);
    @include sprite-position($icon-lang-fr);
}

%image_lang-hu {
    @include sprite-width($icon-lang-hu);
    @include sprite-height($icon-lang-hu);
    @include sprite-position($icon-lang-hu);
}

%image_lang-it {
    @include sprite-width($icon-lang-it);
    @include sprite-height($icon-lang-it);
    @include sprite-position($icon-lang-it);
}

%image_lang-mk {
    @include sprite-width($icon-lang-mk);
    @include sprite-height($icon-lang-mk);
    @include sprite-position($icon-lang-mk);
}

%image_lang-mne {
    @include sprite-width($icon-lang-mne);
    @include sprite-height($icon-lang-mne);
    @include sprite-position($icon-lang-mne);
}

%image_lang-pl {
    @include sprite-width($icon-lang-pl);
    @include sprite-height($icon-lang-pl);
    @include sprite-position($icon-lang-pl);
}

%image_lang-ro {
    @include sprite-width($icon-lang-ro);
    @include sprite-height($icon-lang-ro);
    @include sprite-position($icon-lang-ro);
}

%image_lang-ru {
    @include sprite-width($icon-lang-ru);
    @include sprite-height($icon-lang-ru);
    @include sprite-position($icon-lang-ru);
}

%image_lang-sk {
    @include sprite-width($icon-lang-sk);
    @include sprite-height($icon-lang-sk);
    @include sprite-position($icon-lang-sk);
}

%image_lang-slo {
    @include sprite-width($icon-lang-slo);
    @include sprite-height($icon-lang-slo);
    @include sprite-position($icon-lang-slo);
}

%image_lang-srb {
    @include sprite-width($icon-lang-srb);
    @include sprite-height($icon-lang-srb);
    @include sprite-position($icon-lang-srb);
}

// Layout
._m-b-10 {margin-bottom: 10px;}
._m-b-15 {margin-bottom: 15px;}
._m-b-20 {margin-bottom: 20px;}
._m-b-30 {margin-bottom: 30px;}
._m-b-40 {margin-bottom: 40px;}

._m-t-10 {margin-top: 10px;}
._m-t-15 {margin-top: 15px;}
._m-t-20 {margin-top: 20px;}
._m-t-30 {margin-top: 30px;}
._m-t-40 {margin-top: 40px;}

._p-b-10 {padding-bottom: 10px;}
._p-b-15 {padding-bottom: 15px;}
._p-b-20 {padding-bottom: 20px;}
._p-b-30 {padding-bottom: 30px;}
._p-b-40 {padding-bottom: 40px;}

._p-t-10 {padding-top: 10px;}
._p-t-15 {padding-top: 15px;}
._p-t-20 {padding-top: 20px;}
._p-t-30 {padding-top: 30px;}
._p-t-40 {padding-top: 40px;}
