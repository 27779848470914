// Loader
.loader {
    @extend %loader-image !optional;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex_loader;
    display: none;
    background-color: rgba(255,255,255,.5);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10%;
    background-clip: content-box;

    &.-active {
        display: block;
    }
}
