// Modals
.modal__container {

    .modal {
        display: none;

        &.-active {
            @include viewport-unit(height, 100vh);
            width: 100%;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: $zindex_modal;
            display: block;
            content: '';
            background: rgba(0,0,0,.9);
            animation: modal-fade-in .3s ease-in-out;
            animation-fill-mode: forwards;

            .modal__content {
                width: 75%;
                min-width: 300px;
                max-width: 600px;
                margin: 0;
                position: absolute;
                top: $header_height + 10px;
                left: 50%;
                animation: modal-content-fade-in .3s ease-in-out;
                animation-fill-mode: forwards;
                transform: translateX(-50%);

                .heading__2,
                .heading__3 {
                    max-width: calc(100% - #{$button_height} - 4px);
                    color: #faf8f9;
                }

                .form {
                    @include viewport-unit(max-height, 80vh);
                    overflow: auto;
                    –webkit-overflow-scrolling: touch;
                }
            }

            .modal__close {
                @include shape-times(2px, 32px, $color-white);
                display: inline-block;
                color: $color-white;

                span {
                    @include hide-text;
                    width: $button_height;
                    height: $button_height;
                    display: block;
                    transform: rotate(0);
                    will-change: transform;
                    transition: transform .3s $base_transition-timing;

                    &:focus,
                    &:hover {
                        transform: rotate(90deg);
                        will-change: transform;
                        transition: transform .3s $base_transition-timing;
                    }
                }
            }
        }
    }
}

.modal__close {
    display: none;
}
