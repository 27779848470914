// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-lang-bg-name: 'icon_lang-bg';
$icon-lang-bg-x: 92px;
$icon-lang-bg-y: 64px;
$icon-lang-bg-offset-x: -92px;
$icon-lang-bg-offset-y: -64px;
$icon-lang-bg-width: 22px;
$icon-lang-bg-height: 16px;
$icon-lang-bg-total-width: 114px;
$icon-lang-bg-total-height: 97px;
$icon-lang-bg-image: '../images/ac-dodavky/sprites.png';
$icon-lang-bg: (92px, 64px, -92px, -64px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-bg', );
$icon-lang-cro-name: 'icon_lang-cro';
$icon-lang-cro-x: 22px;
$icon-lang-cro-y: 65px;
$icon-lang-cro-offset-x: -22px;
$icon-lang-cro-offset-y: -65px;
$icon-lang-cro-width: 22px;
$icon-lang-cro-height: 16px;
$icon-lang-cro-total-width: 114px;
$icon-lang-cro-total-height: 97px;
$icon-lang-cro-image: '../images/ac-dodavky/sprites.png';
$icon-lang-cro: (22px, 65px, -22px, -65px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-cro', );
$icon-lang-cs-name: 'icon_lang-cs';
$icon-lang-cs-x: 0px;
$icon-lang-cs-y: 81px;
$icon-lang-cs-offset-x: 0px;
$icon-lang-cs-offset-y: -81px;
$icon-lang-cs-width: 22px;
$icon-lang-cs-height: 16px;
$icon-lang-cs-total-width: 114px;
$icon-lang-cs-total-height: 97px;
$icon-lang-cs-image: '../images/ac-dodavky/sprites.png';
$icon-lang-cs: (0px, 81px, 0px, -81px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-cs', );
$icon-lang-de-name: 'icon_lang-de';
$icon-lang-de-x: 18px;
$icon-lang-de-y: 40px;
$icon-lang-de-offset-x: -18px;
$icon-lang-de-offset-y: -40px;
$icon-lang-de-width: 22px;
$icon-lang-de-height: 16px;
$icon-lang-de-total-width: 114px;
$icon-lang-de-total-height: 97px;
$icon-lang-de-image: '../images/ac-dodavky/sprites.png';
$icon-lang-de: (18px, 40px, -18px, -40px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-de', );
$icon-lang-en-name: 'icon_lang-en';
$icon-lang-en-x: 40px;
$icon-lang-en-y: 40px;
$icon-lang-en-offset-x: -40px;
$icon-lang-en-offset-y: -40px;
$icon-lang-en-width: 22px;
$icon-lang-en-height: 16px;
$icon-lang-en-total-width: 114px;
$icon-lang-en-total-height: 97px;
$icon-lang-en-image: '../images/ac-dodavky/sprites.png';
$icon-lang-en: (40px, 40px, -40px, -40px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-en', );
$icon-lang-es-name: 'icon_lang-es';
$icon-lang-es-x: 70px;
$icon-lang-es-y: 0px;
$icon-lang-es-offset-x: -70px;
$icon-lang-es-offset-y: 0px;
$icon-lang-es-width: 22px;
$icon-lang-es-height: 16px;
$icon-lang-es-total-width: 114px;
$icon-lang-es-total-height: 97px;
$icon-lang-es-image: '../images/ac-dodavky/sprites.png';
$icon-lang-es: (70px, 0px, -70px, 0px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-es', );
$icon-lang-fr-name: 'icon_lang-fr';
$icon-lang-fr-x: 70px;
$icon-lang-fr-y: 16px;
$icon-lang-fr-offset-x: -70px;
$icon-lang-fr-offset-y: -16px;
$icon-lang-fr-width: 22px;
$icon-lang-fr-height: 16px;
$icon-lang-fr-total-width: 114px;
$icon-lang-fr-total-height: 97px;
$icon-lang-fr-image: '../images/ac-dodavky/sprites.png';
$icon-lang-fr: (70px, 16px, -70px, -16px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-fr', );
$icon-lang-hu-name: 'icon_lang-hu';
$icon-lang-hu-x: 70px;
$icon-lang-hu-y: 32px;
$icon-lang-hu-offset-x: -70px;
$icon-lang-hu-offset-y: -32px;
$icon-lang-hu-width: 22px;
$icon-lang-hu-height: 16px;
$icon-lang-hu-total-width: 114px;
$icon-lang-hu-total-height: 97px;
$icon-lang-hu-image: '../images/ac-dodavky/sprites.png';
$icon-lang-hu: (70px, 32px, -70px, -32px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-hu', );
$icon-lang-it-name: 'icon_lang-it';
$icon-lang-it-x: 70px;
$icon-lang-it-y: 48px;
$icon-lang-it-offset-x: -70px;
$icon-lang-it-offset-y: -48px;
$icon-lang-it-width: 22px;
$icon-lang-it-height: 16px;
$icon-lang-it-total-width: 114px;
$icon-lang-it-total-height: 97px;
$icon-lang-it-image: '../images/ac-dodavky/sprites.png';
$icon-lang-it: (70px, 48px, -70px, -48px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-it', );
$icon-lang-mk-name: 'icon_lang-mk';
$icon-lang-mk-x: 0px;
$icon-lang-mk-y: 65px;
$icon-lang-mk-offset-x: 0px;
$icon-lang-mk-offset-y: -65px;
$icon-lang-mk-width: 22px;
$icon-lang-mk-height: 16px;
$icon-lang-mk-total-width: 114px;
$icon-lang-mk-total-height: 97px;
$icon-lang-mk-image: '../images/ac-dodavky/sprites.png';
$icon-lang-mk: (0px, 65px, 0px, -65px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-mk', );
$icon-lang-mne-name: 'icon_lang-mne';
$icon-lang-mne-x: 22px;
$icon-lang-mne-y: 81px;
$icon-lang-mne-offset-x: -22px;
$icon-lang-mne-offset-y: -81px;
$icon-lang-mne-width: 22px;
$icon-lang-mne-height: 16px;
$icon-lang-mne-total-width: 114px;
$icon-lang-mne-total-height: 97px;
$icon-lang-mne-image: '../images/ac-dodavky/sprites.png';
$icon-lang-mne: (22px, 81px, -22px, -81px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-mne', );
$icon-lang-pl-name: 'icon_lang-pl';
$icon-lang-pl-x: 44px;
$icon-lang-pl-y: 65px;
$icon-lang-pl-offset-x: -44px;
$icon-lang-pl-offset-y: -65px;
$icon-lang-pl-width: 22px;
$icon-lang-pl-height: 16px;
$icon-lang-pl-total-width: 114px;
$icon-lang-pl-total-height: 97px;
$icon-lang-pl-image: '../images/ac-dodavky/sprites.png';
$icon-lang-pl: (44px, 65px, -44px, -65px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-pl', );
$icon-lang-ro-name: 'icon_lang-ro';
$icon-lang-ro-x: 66px;
$icon-lang-ro-y: 65px;
$icon-lang-ro-offset-x: -66px;
$icon-lang-ro-offset-y: -65px;
$icon-lang-ro-width: 22px;
$icon-lang-ro-height: 16px;
$icon-lang-ro-total-width: 114px;
$icon-lang-ro-total-height: 97px;
$icon-lang-ro-image: '../images/ac-dodavky/sprites.png';
$icon-lang-ro: (66px, 65px, -66px, -65px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-ro', );
$icon-lang-ru-name: 'icon_lang-ru';
$icon-lang-ru-x: 92px;
$icon-lang-ru-y: 0px;
$icon-lang-ru-offset-x: -92px;
$icon-lang-ru-offset-y: 0px;
$icon-lang-ru-width: 22px;
$icon-lang-ru-height: 16px;
$icon-lang-ru-total-width: 114px;
$icon-lang-ru-total-height: 97px;
$icon-lang-ru-image: '../images/ac-dodavky/sprites.png';
$icon-lang-ru: (92px, 0px, -92px, 0px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-ru', );
$icon-lang-sk-name: 'icon_lang-sk';
$icon-lang-sk-x: 92px;
$icon-lang-sk-y: 16px;
$icon-lang-sk-offset-x: -92px;
$icon-lang-sk-offset-y: -16px;
$icon-lang-sk-width: 22px;
$icon-lang-sk-height: 16px;
$icon-lang-sk-total-width: 114px;
$icon-lang-sk-total-height: 97px;
$icon-lang-sk-image: '../images/ac-dodavky/sprites.png';
$icon-lang-sk: (92px, 16px, -92px, -16px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-sk', );
$icon-lang-slo-name: 'icon_lang-slo';
$icon-lang-slo-x: 92px;
$icon-lang-slo-y: 32px;
$icon-lang-slo-offset-x: -92px;
$icon-lang-slo-offset-y: -32px;
$icon-lang-slo-width: 22px;
$icon-lang-slo-height: 16px;
$icon-lang-slo-total-width: 114px;
$icon-lang-slo-total-height: 97px;
$icon-lang-slo-image: '../images/ac-dodavky/sprites.png';
$icon-lang-slo: (92px, 32px, -92px, -32px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-slo', );
$icon-lang-srb-name: 'icon_lang-srb';
$icon-lang-srb-x: 92px;
$icon-lang-srb-y: 48px;
$icon-lang-srb-offset-x: -92px;
$icon-lang-srb-offset-y: -48px;
$icon-lang-srb-width: 22px;
$icon-lang-srb-height: 16px;
$icon-lang-srb-total-width: 114px;
$icon-lang-srb-total-height: 97px;
$icon-lang-srb-image: '../images/ac-dodavky/sprites.png';
$icon-lang-srb: (92px, 48px, -92px, -48px, 22px, 16px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_lang-srb', );
$icon-phone-blue-name: 'icon_phone-blue';
$icon-phone-blue-x: 0px;
$icon-phone-blue-y: 0px;
$icon-phone-blue-offset-x: 0px;
$icon-phone-blue-offset-y: 0px;
$icon-phone-blue-width: 52px;
$icon-phone-blue-height: 40px;
$icon-phone-blue-total-width: 114px;
$icon-phone-blue-total-height: 97px;
$icon-phone-blue-image: '../images/ac-dodavky/sprites.png';
$icon-phone-blue: (0px, 0px, 0px, 0px, 52px, 40px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_phone-blue', );
$icon-triangle-left-white-name: 'icon_triangle-left-white';
$icon-triangle-left-white-x: 0px;
$icon-triangle-left-white-y: 40px;
$icon-triangle-left-white-offset-x: 0px;
$icon-triangle-left-white-offset-y: -40px;
$icon-triangle-left-white-width: 18px;
$icon-triangle-left-white-height: 25px;
$icon-triangle-left-white-total-width: 114px;
$icon-triangle-left-white-total-height: 97px;
$icon-triangle-left-white-image: '../images/ac-dodavky/sprites.png';
$icon-triangle-left-white: (0px, 40px, 0px, -40px, 18px, 25px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_triangle-left-white', );
$icon-triangle-right-white-name: 'icon_triangle-right-white';
$icon-triangle-right-white-x: 52px;
$icon-triangle-right-white-y: 0px;
$icon-triangle-right-white-offset-x: -52px;
$icon-triangle-right-white-offset-y: 0px;
$icon-triangle-right-white-width: 18px;
$icon-triangle-right-white-height: 25px;
$icon-triangle-right-white-total-width: 114px;
$icon-triangle-right-white-total-height: 97px;
$icon-triangle-right-white-image: '../images/ac-dodavky/sprites.png';
$icon-triangle-right-white: (52px, 0px, -52px, 0px, 18px, 25px, 114px, 97px, '../images/ac-dodavky/sprites.png', 'icon_triangle-right-white', );
$spritesheet-width: 114px;
$spritesheet-height: 97px;
$spritesheet-image: '../images/ac-dodavky/sprites.png';
$spritesheet-sprites: ($icon-lang-bg, $icon-lang-cro, $icon-lang-cs, $icon-lang-de, $icon-lang-en, $icon-lang-es, $icon-lang-fr, $icon-lang-hu, $icon-lang-it, $icon-lang-mk, $icon-lang-mne, $icon-lang-pl, $icon-lang-ro, $icon-lang-ru, $icon-lang-sk, $icon-lang-slo, $icon-lang-srb, $icon-phone-blue, $icon-triangle-left-white, $icon-triangle-right-white, );
$spritesheet: (114px, 97px, '../images/ac-dodavky/sprites.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
