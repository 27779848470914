// Buttons
//
//      file: modules/button.scss
//
// Styles for **link**, **button** or **input** to looks like button.
//
// You can define class name in variable: **`$button_name`**.
//
// Variants are defined in base variable: `$base_variants` (defaults are: `success`, `info`, `warning` and `danger`)
//
// Default class: **`.btn`**.
//
// .-success - success style variant ($color-green)
// .-info - info style variant ($color-blue)
// .-warning - warning style variant ($color-yellow)
// .-danger - danger style variant ($color-red)
//
// Markup:
// <a href="#" title="" class="btn {{modifier_class}}">Link button</a>
// <button class="btn {{modifier_class}}">Button</button>
//
// styleguide 5.3

// Block buttons
//
// Best usage with grid system
//
// .-block - block button style
//
// Markup:
//  <div class="row">
//      <div class="col-xs-4"><button class="btn {{modifier_class}}">Button</button></div>
//  </div>
//  <div class="row">
//      <div class="col-xs-6"><button class="btn {{modifier_class}}">Button</button></div>
// </div>
//
// styleguide 5.3.1

// Disabled buttons
//
// Markup:
// <button class="btn -disabled">Button</button>
// <button class="btn -success -disabled">Button</button>
// <button class="btn -info -disabled">Button</button>
// <button class="btn -warning -disabled">Button</button>
// <button class="btn -danger -disabled">Button</button>
//
// styleguide 5.3.2

// Default variables
$button_name: 'btn' !default;
$button_width: 70px !default;
$button_height: if(unitless($base_line-height), ($base_font-size * $base_line-height * 2), ($base_line-height * 2)) !default;
$button_padding-top: 0 !default;
$button_padding-right: floor($grid_gutter / 2) !default;
$button_padding-bottom: 0 !default;
$button_padding-left: floor($grid_gutter / 2) !default;
$button_border-size: 0 !default;
$button_font-weight: bold !default;
$button_background-default: $color-black-90 !default;
$button_background-default-hover: $color-black-80 !default;
$button_color-default: $color-black-20 !default;
$button_color-default-hover: $color-black-10 !default;

@mixin button() {

    @if $button_trigger == true {

        .#{$button_name} {
            @include _button-variant($button_background-default,$button_background-default-hover,$button_color-default,$button_color-default-hover);
            min-width: $button_width;
            height: $button_height;
            padding: $button_padding-top $button_padding-right $button_padding-bottom $button_padding-left;
            display: inline-block;
            vertical-align: middle;
            font-weight: $button_font-weight;
            text-align: center;
            @if $base_border-radius > 0 {border-radius: $base_border-radius;}

            @each $key, $value in $base_variants {

                &.-#{$key} {@include _button-variant($value,darken($value,8%),darken($value,16%),darken($value,25%));}

                @if $badge_trigger == true {.#{$badge_name} {color: $value;}}
            }

            &.-disabled {

                &,
                &:focus,
                &:hover,
                &:active,
                &.-active {
                    @include disabled;
                    opacity: .5;
                }
            }

            &.-block {
                width: 100%;
                display: block;
            }

            @if $badge_trigger == true {

                .#{$badge_name} {
                    background-color: #fff;
                    color: $color-black-70;
                }
            }
        }

        a.#{$button_name} {
            line-height: ($button_height - ($button_border-size * 2));
            text-decoration: none;
        }
    }
}

@mixin _button-variant(
    $background,
    $background-hover,
    $color,
    $color-hover
) {
    $error-message: 'Arguments are required, it needs to be a colors.';

    @if type-of($background) == color and type-of($background-hover) == color and type-of($color) == color and type-of($color-hover) == color {
        background-color: $background;
        color: $color;
        @if $button_border-size > 0 {border: $button_border-size solid darken($background,4%);}

        &:focus,
        &:hover,
        &:active,
        &.-active {
            background-color: $background-hover;
            color: $color-hover;
            @if $button_border-size > 0 {border: $button_border-size solid darken($background-hover,4%);}
        }
    } @else {
        @error #{$error-message};
    }
}

@include button;
