.content__articles {

    .-article {
        padding: 5px 0 5px 17px;
        border-left: solid 5px $color-red;

        .-article-heading {
            margin-bottom: 4px;
            color: $color-red;
            font-size: 21px;
        }
    }
}
