// Sections
.section__container {

    @media #{$xs-min} {
        margin-bottom: ($section_indentation / 2);
    }

    @media #{$md-min} {
        margin-bottom: $section_indentation;
    }

    &:last-child {
        margin-bottom: 0;
    }

    address,
    p {
        margin-bottom: vr(.5);
    }
}

.section__content {
    width: 100%;
    margin-left: $icons_width;

    .contact__container & {
        padding-right: ($icons_width + 10px);

        .row {
            margin-right: 0;
            margin-bottom: 0;
            margin-left: 0;
        }
    }
}

.section__logo {
    height: 150px;
    margin-top: -1px;
    margin-left: -1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid $color-light-gray;

    img {
        max-width: 100%;
        height: auto;
    }
}

.section__phone {
    padding: vr(1.5) 0;
    text-align: center;

    p + & {
        padding: vr(.5) 0 vr(1.5);
    }

    + .section__email {padding-top: vr(.5);}
}

.section__email {
    @extend .section__phone;
}

.section__phone-content,
.section__email-content {
    padding-left: 70px;
    position: relative;
    display: inline-block;
    color: $color-red;
    text-align: center;

    @media #{$xs-min} {
        font-size: em(20px);
    }

    @media #{$sm-min} {
        font-size: em(32px);
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.section__phone-content {

    &::before {
        @extend %sprite-image;
        @include sprite-width($icon-phone-blue);
        @include sprite-height($icon-phone-blue);
        @include sprite-position($icon-phone-blue);
        content: '';
    }
}

.section__email-content {

    a {
        color: $color-red;
    }

    &::before {
        content: '\e901';
        color: $color-blue;
        font-family: 'icomoon' !important;
        font-size: 44px;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.section__button-container {
    display: flex;
    justify-content: center;
}

// Documents
.document {
    margin-bottom: 40px;

    h3 {@extend .heading__3;}
    h4 {@extend .heading__4;}
    h5 {@extend .heading__5;}
    h6 {@extend .heading__6;}

    p,
    ul {
        margin-bottom: vr(1);

        &:last-child {
            margin-bottom: 0;
        }
    }

    span,
    strong {
        margin-bottom: vr(.5);
        display: inline-block;

        &:last-child {
            margin-bottom: 0;
        }
    }

    li {
        padding-left: 18px;
        position: relative;

        &::before {
            width: 6px;
            height: 6px;
            position: absolute;
            top: 8px;
            left: 0;
            display: block;
            content: '';
            background-color: $color-red;
            border-radius: 50%;
        }
    }

    .row {
        margin-right: -$document_grid-gutter;
        margin-bottom: vr(2);
        margin-left: -$document_grid-gutter;

        &:last-child {
            margin-bottom: 0;
        }

        .page__nas-tym & {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .block {
        height: 100%;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;

        &.-bordered {
            padding: 20px 40px;
            border: 3px solid $color-light-gray;
        }
    }

    .block__heading {
        @extend .heading__2;
        width: 100%;
        font-weight: bold;
    }

    [class*='#{$grid_column-name}-'] {
        padding: 0 $document_grid-gutter;
    }

    // Page Vykup vozu
    .page__vykup-vozu & {

        @media #{$lg-max} {
            padding-top: 40px;
            clear: both;
        }

        @media #{$lg-min} {
            max-width: 50%;
            flex-basis: 50%;
        }
    }

    // Page financovani
    .page__financovani & {

        @media #{$lg-max} {
            padding-top: 40px;
            clear: both;
        }
    }

    // Page our team
    .page__nas-tym & {
        margin-bottom: 0;
    }

    .our-team {
        padding: 0;
        position: relative;
        overflow: hidden;
        line-height: 0;

        &:focus,
        &:hover {

            .-secondary {
                transform: translateY(0);
                transition: transform .4s $base_transition-timing;
            }
        }

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
            line-height: 0;
        }
    }

    .our-team__detail {

        &.-main {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            @media #{$xs-min} {
                padding: 10%;
            }

            @media #{$sm-min} {
                padding: 5%;
            }

            @media #{$lg-min} {
                padding: 3%;
            }

            .-name {
                margin-bottom: vr(1);
                font-size: em(24px);
            }

            .-name,
            .-e-mail {
                color: $color-red;

                a {
                    color: $color-red;
                }
            }

            .-phone {
                font-size: em(16px);
            }

            .-position {
                color: $color-blue;
            }
        }

        &.-secondary {
            width: 100%;
            height: 100%;
            padding: 20px 10px 40px;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            background: $our-team_background;
            color: $color-white;
            text-align: center;
            transform: translateY(100%);
            transition: transform .2s $base_transition-timing;

            .-name {
                margin-bottom: vr(.5);
                font-size: em(22px);
                font-weight: bold;
            }

            .-e-mail {
                margin-bottom: vr(1.5);

                a {
                    color: #7ab2ef;
                }
            }
        }
    }

    .our-team__description {
        margin-bottom: 0;
        display: block;
        font-size: em(14px);
        line-height: 1.2;

        &.-position {
            margin-bottom: vr(1);
            font-weight: bold;
            text-transform: uppercase;
        }

        &.-text {
            margin-bottom: vr(1);
        }
    }

    .our-team__languages {

        li {
            padding-left: 0;
            display: inline-block;
            vertical-align: middle;
            font-size: em(12px);

            &::before {
                display: none;
            }
        }
    }

    .our-team__lang {
        @include hide-text;
        width: 22px;
        height: 16px;
        position: relative;
        display: block;
        line-height: 0;

        &::before {
            @extend %sprite-image;
            @include hide-text;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            content: '';
        }

        &.-bg::before {@extend %image_lang-bg;}
        &.-cro::before {@extend %image_lang-cro;}
        &.-cs::before {@extend %image_lang-cs;}
        &.-de::before {@extend %image_lang-de;}
        &.-en::before {@extend %image_lang-en;}
        &.-es::before {@extend %image_lang-es;}
        &.-fr::before {@extend %image_lang-fr;}
        &.-hu::before {@extend %image_lang-hu;}
        &.-it::before {@extend %image_lang-it;}
        &.-mk::before {@extend %image_lang-mk;}
        &.-mne::before {@extend %image_lang-mne;}
        &.-pl::before {@extend %image_lang-pl;}
        &.-ro::before {@extend %image_lang-ro;}
        &.-ru::before {@extend %image_lang-ru;}
        &.-sk::before {@extend %image_lang-sk;}
        &.-slo::before {@extend %image_lang-slo;}
        &.-srb::before {@extend %image_lang-srb;}
    }
}

// List of links
.link__list {
    font-weight: bold;
    text-transform: uppercase;

    li {
        display: flex;
        align-items: flex-start;
    }

    .detail & {
        text-align: right;

        li {
            margin-bottom: 8px;
            justify-content: flex-end;
        }
    }

    .link__list-item {
        padding-right: 24px;
        background-position: right 4px top 4px;
        line-height: 20px;
        text-decoration: underline;
        text-transform: uppercase;
        transition: background-position $base_transition-duration-in $base_transition-timing;
        border: none;

        &:focus,
        &:hover {
            background-position: right top 4px;
            transition: background-position $base_transition-duration-in $base_transition-timing;
        }
    }
}

// Contact
.contact__container {
    margin-bottom: vr(1);
    position: relative;

    @media #{$sm_max} {
        padding: 10px;
    }

    @media #{$md-min} {

        &:nth-child(odd) {

            &::before {
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                left: calc(100% - 40px);
                display: flex;
                content: '';
                background-color: $color-light-gray;
            }
        }

        &[class*='#{$grid_column-name}-'],
        [class*='#{$grid_column-name}-'] {
            padding: 0;
        }
    }

    @media #{$lg-min} {

        &:nth-last-child(n+2) {

            &::before {
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                left: calc(100% - 40px);
                display: flex;
                content: '';
                background-color: $color-light-gray;
            }
        }
    }
}

// Loan/Purchase section form
.form__purchase-container,
.form__loan-container {

    @media #{$xs-min} {
        width: 100%;
        margin-bottom: vr(1);
    }

    @media #{$lg-min} {
        width: 45%;
        margin-left: 5%;
    }
}

// Error pages
.-section {

    .page__error & {
        min-height: 600px;
    }
}
