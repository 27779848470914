// Newsletter
.newsletter__container {
    padding: 0 0 30px;
    background-color: $color-light-gray;

    [class*='#{$grid_column-name}-'] {
        padding-bottom: 15px;
    }

    .button {
        white-space: nowrap;

        @media #{$sm-max} {
            margin: 0 auto;
            display: block;
        }

        @media #{$sm-min} {
            margin-left: 15px;
        }
    }
}

.newsletter__text,
.newsletter__field {
    margin-bottom: 20px;
}

.newsletter__button {
    margin-top: 15px;
}

@media #{$sm-min} {

    .newsletter__form {
        display: flex;
    }

    .newsletter__button {
        margin-top: 0;
    }
}

@media #{$lg-min} {

    .newsletter {
        max-width: 960px;
        margin: 0 auto;
        display: flex;
        align-items: flex-end;
    }

    .newsletter__text {
        margin-bottom: 0;
    }
}
