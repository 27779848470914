// Languages
.lang {
    width: $lang-item_width;
    height: $header_height;
    position: absolute;
    top: 0;
    right: 0;
    z-index: $zindex_languages;
    overflow: hidden;
    transition: height $base_transition-duration-in $base_transition-timing;

    &:focus,
    &:hover {
        height: auto;
        transition: height $base_transition-duration-in $base_transition-timing;

        li:first-child {

            &::before {
                transform: rotate(90deg);
                transition: transform ($base_transition-duration-in / 2) $base_transition-timing;
            }
        }
    }

    li {
        z-index: $zindex_languages;

        &:first-child {

            &::before {
                @extend %icomoon, .v-icon-arrow-right-white::before;
                position: absolute;
                top: (($header_height - 25px) / 2);
                right: 5px;
                z-index: 1;
                font-size: 25px;
                transition: transform $base_transition-duration-in $base_transition-timing;
            }
        }
    }
}

.lang__item {
    @include hide-text;
    width: $lang-item_width;
    height: $header_height;
    position: relative;
    display: block;
    background-color: $lang-item_background;
    line-height: 0;
    transition: background-color $base_transition-duration-in $base_transition-timing;
    border: none;

    &:focus,
    &:hover {
        background-color: $lang-item_background-hover;
        transition: background-color $base_transition-duration-out $base_transition-timing;
    }

    &::before {
        @extend %sprite-image;
        @include hide-text;
        position: absolute;
        top: 50%;
        left: 15px;
        display: block;
        content: '';
        transform: translateY(-50%);
    }

    &.-cs::before {@extend %image_lang-cs;}
    &.-de::before {@extend %image_lang-de;}
    &.-en::before {@extend %image_lang-en;}
    &.-es::before {@extend %image_lang-es;}
    &.-fr::before {@extend %image_lang-fr;}
    &.-hu::before {@extend %image_lang-hu;}
    &.-it::before {@extend %image_lang-it;}
    &.-pl::before {@extend %image_lang-pl;}
    &.-ro::before {@extend %image_lang-ro;}
    &.-ru::before {@extend %image_lang-ru;}
    &.-sk::before {@extend %image_lang-sk;}
}
