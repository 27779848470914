// Form slider
.slider {
    width: 100%;
    height: 6px;
    margin: 20px auto 40px;

    @media #{$md-max} {

        &.-range {
            display: none;
        }
    }
}

.slider__content {
    height: 100%;
    padding: 0 50px 0 0;
    display: flex;
    flex-direction: column;

    + .slider__content {
        padding: 0 0 0 50px;
    }
}

.slider__input {

    @media #{$md-min} {
        display: none;
    }
}

// Slider (3rd party plugin noUiSlider)
.slider__target {

    &,
    * {
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        touch-action: none;
        user-select: none;
    }
}

.slider__horizontal {
    position: relative;
    background-color: #d0d0d0;
    border-radius: 6px;
}

.slider__base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    transform: translate3d(0,0,0);
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;

    .-single & {
        background-color: $slider_background;
    }

    @media #{$sm-max} {
        width: 90%;
    }
}

.slider__origin {
    height: 6px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #d0d0d0;
}

.slider__connect {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $slider_background;
}

.slider__handle {
    position: relative;
    left: -10px;
    z-index: 1;
    background-color: $color-white;
    cursor: pointer;
    transform: translate3d(0,0,0);
    transition: box-shadow $base_transition-duration-in $base_transition-timing, background-color $base_transition-duration-in $base_transition-timing;
    border-radius: 50%;
    border: 4px solid $slider_background;
}

.slider__active {
    background-color: $slider_background;
    box-shadow: 0 0 10px rgba(20,70,126,.75);
}

.slider__handle-lower {
    width: 20px;
    height: 20px;
    top: -7px;

    .slider__tooltip {
        top: 21px;
        left: -30px;
    }
}

.slider__handle-upper {
    width: 26px;
    height: 26px;
    top: -10px;

    .slider__tooltip {
        top: 24px;
        left: -27px;
    }
}

.slider__tooltip {
    width: 80px;
    position: absolute;
    left: -20px;
    color: $slider_background;
    font-size: em(14px);
    font-weight: bold;
    text-align: center;
}
