// Functions
//
//      file: base/functions.scss
//
// Sass funkce
//
// **`em`** - convert pixels to em, use: em(12px)
//
// **`lightness-color`** - return color depending on ligthness of color value, use: lightness-color($background, $color)
//
// **`delay`** - delay function used in loaders (calculate animation delay depend on count)
//
// **`map-get-next`** - function to get next map item in sass maps
//
// styleguide 2.3

// PX to EM
@function em($pixels, $context: $base_font-size) {
    @if (unitless($pixels)) {$pixels: $pixels * 1px;}
    @if (unitless($context)) {$context: $context * 1px;}

    @return $pixels / $context * 1em;
}

// Set color depending on ligthness
@function lightness-color($color, $color-return) {
    @if (lightness($color) > 60) {
        @return $color-return;
    } @else {
        @return $color-white;
    }
}

// Function used in loaders delay
@function delay($interval, $count, $index) {
    @return ($index * $interval) - ($interval * $count);
}

// Function to get next map item
// returns next map item or fallback value if map, key or next item does not exist
//
// @author Simon Koch (https://gist.github.com/agorilla/9df052eb1e15d8aea446)
//
// @access public
//
// @param {Map} $map - Sass list map
// @param {String} $key - List map key
// @param {String} $fallback (false) - Fallback value if map, key or next item does not exist

@function map-get-next($map, $key, $fallback: false) {
    // Check if map is valid
    @if type_of($map) == map {
        // Check if key exists in map
        @if map_has_key($map, $key) {
            // Init index counter variable
            $i: 0;
            // Init key index
            $key-index: false;
            // Traverse map for key
            @each $map-key, $map-value in $map {
                // Update index
                $i: $i + 1;
                // If map key found, set key index
                @if $map-key == $key {$key-index: $i;}
                // If next index return next value
                @if $i == $key-index + 1 {@return $map-value;}
                // If last entry return false
                @if $i == length($map) {@return $fallback;}
            }
            @warn 'No next map item for key #{$key}';
            @return $fallback;
        }
        @warn 'No valid key #{$key} in map';
        @return $fallback;
    }
    @warn 'No valid map';
    @return $fallback;
}

// Function to merge multiple maps
// returns map collection
// http://stackoverflow.com/questions/27740063/merge-multiple-sass-maps#answer-30740182
//
// @author biggles (http://stackoverflow.com/users/664904/biggles)
//
// @access public
//
// @param {Map} $maps - Sass maps
@function map-collect($maps...) {
    $collection: ();

    @each $map in $maps {
        $collection: map-merge($collection, $map);
    }

    @return $collection;
}
