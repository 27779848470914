// Footer
.footer {
    width: 100%;
    padding: 60px 0;
    background: $footer_background;
    -webkit-backface-visibility: hidden;

    @media #{$md-max} {
        padding: 20px 5%;
    }

    p {
        -webkit-backface-visibility: hidden;
    }
}

.footer__content {
    color: $footer_color-content;
    font-size: em(14px);
}

.footer__contact-content {
    padding-left: $icons_width;

    a {
        color: $footer_color-content;
    }
}

.footer__list {
    margin-top: -17px;
    margin-bottom: 40px;
    font-size: 29px;
    font-weight: bold;

    @media #{$md-max} {
        margin-top: 0;
        margin-left: 8px;
        font-size: 22px;
    }

    li {
        margin: 11px 0;
        padding-left: 30px;
        position: relative;
        line-height: 1.2em;
        filter: drop-shadow(2px 2px 3px rgba(0,0,0,.4)); // this cause error in csslint -> Fatal error, cannot continue: String.charCodeAt is not a function
        -webkit-backface-visibility: hidden;

        &::before {
            width: 12px;
            height: 12px;
            margin-right: 18px;
            position: absolute;
            top: 12px;
            left: 0;
            display: inline-block;
            content: '';
            background: $color-white;
            border-radius: 50%;

            @media #{$md-max} {
                top: 9px;
            }
        }
    }
}

.footer__banner {
    max-width: 100%;
    height: auto;
    margin: 0 auto 40px;
    padding-right: 10px;
    display: block;
}

.footer__copy {
    margin-top: 20px;
    color: $footer_color-copy;
    font-size: 11px;

    a {
        color: $footer_color-copy;
    }
}
