// Slideshow
%slideshow-loader {
    @extend %loader-image !optional;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10%;
    text-align: center;
}

.slideshow {
    @extend %slideshow-loader;
    width: 100%;
    position: relative;
    z-index: $zindex_slideshow;

    &:focus,
    &:hover {

        .slideshow__navigation {
            visibility: visible;
        }
    }

    .slideshow__list {
        width: 100%;
        position: fixed;
        left: 0;
        overflow: hidden;
        line-height: 0;

        @media #{$xs-min} {
            top: $header_height * 2;
        }

        @media #{$sm-min} {
            top: $header_height;
        }
    }

    .slideshow__item {
        background-color: $color-light-gray;

        @media #{$sm-max} {
            left: 0;
        }
    }

    .slideshow__scene {
        height: 100%;
    }

    .slideshow__layer {
        width: 100%;
        height: 100%;
        min-height: 100%;
    }

    .slideshow__item-description {
        @include viewport-unit(font-size, 4vw);
        position: absolute;
        top: 20%;
        left: 10%;
        color: $color-white;
        line-height: 1.1;
        font-weight: bold;
        text-align: left;
    }

    .slideshow__parallax-background {
        width: auto;
        min-width: 100%;
        height: 100%;
        min-height: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        img {
            width: 105%;
            height: auto;
            position: absolute;
            bottom: 0;
            left: -2.5%;
        }
    }

    .slideshow__background {
        width: 100%;
        height: auto;
    }

    .slideshow__navigation {
        width: 5%;
        height: calc(100vh - (#{$header_height} * 2) - #{$navigation_categories-height});
        position: fixed;
        top: $header_height;
        z-index: $zindex_slideshow + 1;
        visibility: hidden;

        &.-prev {
            left: 0;
        }

        &.-next {
            right: 0;
        }
    }
}

.slideshow__item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    visibility: hidden;
}

.slideshow__current {
    position: relative;
    visibility: visible;
}

.slideshow__item-hide-prev,
.slideshow__item-hide-next {
    z-index: 2;
    visibility: visible;
    will-change: opacity, visibility;
    animation: fadeOut 450ms cubic-bezier(.455, .03, .515, .955) both;
}

.slideshow__item-show-prev,
.slideshow__item-show-next {
    z-index: 1;
}

.slideshow__image {

    .slideshow__scene & {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

// Car detail slideshow
.slideshow-car {
    @extend %slideshow-loader;
    position: relative;

    @media #{$xs-min} {min-height: 130px;}
    @media #{$sm-min} {min-height: 230px;}
    @media #{$md-min} {min-height: 390px;}
    @media #{$lg-min} {min-height: 550px;}
    @media #{$xl-min} {min-height: 720px;}
    @media #{$xxl-min} {min-height: 810px;}

    .slideshow__list {
        width: 100%;
        height: 100%;
        padding-top: 56.25%; // ratio 16:9
        position: relative;
        overflow: hidden;
        line-height: 0;
    }

    .slideshow__item {
        position: absolute;
        right: 0;
        bottom: 0;

        img {width: 100%;}
    }

    .slideshow__navigation-container {
        width: 100%;
        height: 25px;
        padding: 0 36px;
        position: relative;
        bottom: 50px;
        left: 50%;
        z-index: $zindex_slideshow + 1;
        transform: translateX(-50%);
    }

    .slideshow__navigation {
        width: 30px;
        height: 100%;
        display: inline-block;

        &.-overlay {
            width: 50px;
            position: absolute;
            top: 0;
            z-index: $zindex_slideshow-car-navigation;
            opacity: .5;
            transition: opacity $base_transition-duration-in $base_transition-timing;

            &:focus,
            &:hover {
                opacity: 1;
                transition: opacity $base_transition-duration-out $base_transition-timing;
            }
        }

        &.-prev {
            left: 0;
        }

        &.-next {
            right: 0;
        }
    }

    .slideshow__goto {
        width: 20px;
        height: 20px;
        margin: 0 2px;
        padding: 5px;
        border-radius: 50%;
        border: solid 3px $color-white;

        &.-active {background-color: $detail_car-slideshow-active;}

        @media #{$xs-min} {display: none;}
        @media #{$lg-min} {display: inline-block;}
    }
}
