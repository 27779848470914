// Alerts
//
//      file: modules/alert.scss
//
// Styles for **alert** messages.
//
// You can define class name in variable: **`$alert_name`**.
//
// Variants are defined in base variable: `$base_variants` (defaults are: `success`, `info`, `warning` and `danger`)
//
// Default class: **`.alert`**.
//
// .-success   - success style variant (green)
// .-info      - info style variant (blue)
// .-warning   - warning style variant (yellow)
// .-danger    - danger style variant (red)
//
// Markup:
// <div class="alert {{modifier_class}}"><p>Alert message</p></div>
// <div class="alert {{modifier_class}}"><p>Alert message with <a href="#" title="Alert link">alert link</a></p></div>
//
// styleguide 5.1

// Default variables
$alert_name: 'alert' !default;
$alert_margin-top: floor($grid_gutter / 2) !default;
$alert_margin-right: 0 !default;
$alert_margin-bottom: floor($grid_gutter / 2) !default;
$alert_margin-left: 0 !default;
$alert_padding-top: floor($grid_gutter / 2) !default;
$alert_padding-right: floor($grid_gutter / 2) !default;
$alert_padding-bottom: floor($grid_gutter / 2) !default;
$alert_padding-left: floor($grid_gutter / 2) !default;
$alert_font-weight: bold !default;
$alert_background-default: $color-black-90 !default;
$alert_color-default: $color-black-30 !default;

@mixin alert() {

    @if $alert_trigger == true {

        .#{$alert_name} {
            @include _alert-variant($alert_background-default, $alert_color-default);
            margin: $alert_margin-top $alert_margin-right $alert_margin-bottom $alert_margin-left;
            padding: $alert_padding-top $alert_padding-right $alert_padding-bottom $alert_padding-left;
            position: relative;
            line-height: $base_line-height;
            font-weight: $alert_font-weight;
            @if $base_border-radius > 0 {border-radius: $base_border-radius;}

            @each $key, $value in $base_variants {

                &.-#{$key} {@include _alert-variant($value,darken($value,16%));}
            }
        }
    }
}

@mixin _alert-variant(
    $background,
    $color
) {
    $error-message: 'Arguments are required, it needs to be a colors.';

    @if type-of($background) == color and type-of($color) == color {
        background-color: $background;
        color: lightness-color($background,$color);

        a {color: lightness-color($background,$color);}
    } @else {
        @error #{$error-message};
    }
}

@include alert;
