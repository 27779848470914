// Navigation
.navigation__container {
    width: 100%;

    &.-main {
        top: 0;
        z-index: $zindex_sticky + 1;
        background: $section_background;

        @media #{$xs-min} {
            height: $header_height * 2;
        }

        @media #{$sm-min} {
            height: $header_height;
        }

        .navigation__collapse {
            width: $header_height;
            height: $header_height;
            padding: 0 10px;
            position: relative;
            display: none;
            line-height: 50px;
            cursor: pointer;

            &.-active {
                display: inline-block;
            }

            &.-clicked {

                .navigation__nested {
                    visibility: visible;
                    opacity: 1;
                    transition: opacity $base_transition-duration-in $base_transition-timing, visibility $base_transition-duration-in $base_transition-timing;
                }
            }
        }

        .navigation__nested {
            @include viewport-unit(width, 20vw);
            min-width: 100px;
            max-width: 200px;
            position: absolute;
            top: $header_height;
            right: 0;
            float: none;
            visibility: hidden;
            opacity: 0;
            text-align: right;
            transition: opacity $base_transition-duration-in $base_transition-timing, visibility $base_transition-duration-in $base_transition-timing;

            li {
                float: none;
            }

            span {
                border: none;
            }
        }
    }

    &.-categories {
        position: relative;
        top: 0;
        opacity: 0;
        will-change: opacity;
        background-color: $color-light-gray;
        animation: load 1s $ease-in-out-back;
        animation-fill-mode: forwards;

        &[style*="fixed"] {
            box-shadow: 0 10px 20px rgba(0, 0, 0, .15);
        }

        .ie9 & {
            opacity: 1;
        }
    }
}

.navigation__content {

    .-main & {
        position: relative;
        float: left;
        visibility: hidden;

        &.-active {
            visibility: visible;
        }
    }

    .-categories & {
        width: 100%;
        max-width: map-get(map-get($grid, xxl), container);
        margin: 0 auto;
        padding: 0 60px;
        position: relative;
        overflow: hidden;

        @media #{$sm-max} {
            padding: 0 10px;
        }
    }
}

.navigation {
    white-space: nowrap;

    &.-header {
        overflow: hidden;

        ul {
            display: inline-block;
        }
    }

    &.-main {
        height: $header_height;

        ul {
            min-width: 180px;
            position: absolute;
            top: $header_height;
            right: -80px;
            z-index: $zindex_languages;
            display: block;
            background: $navigation_background;
            box-shadow: 0 4px 12px rgba(0, 0, 0, .2);

            li {
                display: block;
                float: none;
            }

            @media #{$md-min} {
                width: auto;
                position: relative;
                top: auto;
                right: auto;
                float: left;
                box-shadow: none;

                li {
                    float: left;
                }
            }
        }

        li:first-child {

            span {
                border: none;
            }
        }
    }

    &.-carousel {
        width: 100%;
        height: $navigation_categories-height;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        line-height: 0;
        text-align: center;

        li {
            margin-left: -4px;
            position: relative;
            float: none;

            @media #{$sm-max} {
                width: calc(50% - 20px);
                margin-left: 0;
                display: inline-block;
            }
        }

        @media #{$sm-max} {
            height: auto;
            overflow: visible;
            white-space: normal;
        }
    }

    ul {
        @include clearfix;
    }

    li {
        float: left;
    }
}

.navigation__list {

    .-carousel & {
        min-width: 100%;
        display: inline-flex;
        vertical-align: top;
    }
}

.navigation__link {
    display: block;
    color: $color-white;
    font-size: em(13px);
    line-height: 40px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color $base_transition-duration-out $base_transition-timing;
    border-bottom: none;

    &:focus,
    &:hover {
        background: $navigation_item-background;
        transition: background-color $base_transition-duration-in $base_transition-timing;

        span {
            transition: border-color $base_transition-duration-in $base_transition-timing;
            border-color: $navigation_item-background;
        }
    }

    &:visited {
        color: darken($color-white, 4%);
    }

    &.-active {
        background: $navigation_item-background-active;
        color: $color-red;

        span {
            border-color: $navigation_item-background-active;
        }
    }

    span {
        width: 100%;
        padding: 0 20px;

        @media #{$md-min} {
            transition: border-color $base_transition-duration-out $base_transition-timing;
            border-left: 1px solid $navigation_item-separator;
        }
    }

    @media #{$md-min} {
        height: $header_height;
        line-height: $header_height;
    }
}

.menu {
    float: left;
}

.navigation__rest {
    position: relative;

    @media #{$sm-min} {
        padding-right: $lang-item_width;
        float: right;
    }

    @media #{$md-min} {
        padding-left: 20px;
    }
}

.navigation__dot {
    $dot_position: ($header_height - 5px) / 2;
    width: 4px;
    height: 4px;
    float: left;
    background: $color-white;
    transform: translate(0,$dot_position);
    transition: transform .2s $base_transition-timing;
    border-radius: 50%;

    + .navigation__dot {
        margin-left: 5px;
    }

    .-filled & {

        &:first-child {
            transform: translate(1px,$dot_position - 2px);
        }

        &:nth-child(2) {
            transform: translate(0,$dot_position + 3px);
        }

        &:nth-child(3) {
            transform: translate(-1px,$dot_position - 2px);
        }
    }

    .-clicked & {
        transition: transform .2s $base_transition-timing;

        &:first-child {
            transform: translate(9px,$dot_position - 10px);
        }

        &:nth-child(2) {
            transform: translate(0,$dot_position);
        }

        &:nth-child(3) {
            transform: translate(-9px,$dot_position + 10px);
        }
    }
}

// Categories navigation
.category__item {
    padding: ($section_indentation / 2) 15px;
    display: inline-flex;
    justify-content: center;
    vertical-align: top;
    transition: background-color .4s $base_transition-timing;

    &.-active {

        &,
        &:focus,
        &:hover {
            background-color: $color-white;

            @media #{$md-max} {
                background: none;
                color: $color-blue;
                font-weight: bold;
            }
        }
    }

    &:focus,
    &:hover {
        background-color: lighten($color-light-gray, 2%);
        transition: background-color .1s $base_transition-timing;
    }

    @media #{$md-min} {
        height: $navigation_categories-height;
    }

    @media #{$md-max} {
        padding-right: 10px;
        padding-left: 10px;
    }

    .navigation & {

        + .category__item {

            .category__name {
                border: none;
            }
        }
    }
}

.category__link {
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    border: none;

    &:focus,
    &:hover {

        .category__name {
            text-decoration: underline;
        }

        .-active &:focus,
        .-active &:hover {

            .category__name {
                text-decoration: none;
            }
        }
    }
}

.category__image {
    max-width: 100%;
}

.category__name {
    width: 100%;
    margin-top: 10px;
    display: inline-block;
    line-height: 20px;
    text-decoration: none;
}

// Carousel navigation
.carousel__navigation {
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    opacity: 0;
    background-color: $color-light-gray;
    transition: opacity $base_transition-duration-in $base_transition-timing, visibility $base_transition-duration-in $base_transition-timing;

    .-enabled & {
        visibility: visible;
        opacity: 1;
        transition: opacity $base_transition-duration-in $base_transition-timing, visibility $base_transition-duration-in $base_transition-timing;
    }

    &.-prev {

        &.-inside {
            left: 0;
        }

        &.-outside {
            left: -60px;
        }
    }

    &.-next {

        &.-inside {
            right: 0;
        }

        &.-outside {
            right: -60px;
        }
    }

    @media #{$xs-max} {
        display: none;
    }
}

.menu__toggle,
.menu__toggle-trigger {
    display: none;
}

.menu__toggle {

    @media #{$md-max} {

        &:checked {

            ~ ul {
                height: auto;
            }
        }
    }
}

.menu__toggle-trigger {
    margin-top: 5px;
    position: relative;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    line-height: 1.3em;
    text-align: center;

    @media #{$md-max} {
        display: block;

        ~ ul {
            height: 0;
            overflow: hidden;
        }
    }
}

.menu__toggle-title {
    display: block;
}
