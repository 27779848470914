// Theme
$theme_name: 'ac-dodavky';

// Triggers
$grid_trigger: true;
$flex_trigger: true;
$button_trigger: true;
$form_trigger: true;
$alert_trigger: true;

// Layout
$base_border-radius: 0;

// Colors
$base_background: #efefef;
$base_color: #5c5a5a;

$color-green: #1aad12;
$color-blue: #14467e;
$color-yellow: #ffc800;
$color-red: #d4161c;
$color-black: #000;
$color-white: #fff;
$color-light-gray: #efefef;
$color-gray: #919191;
$color-dark-gray: $base_color;

// Text
$base_font-size: 14px;
$base_line-height: 1.5;

// Grid
$grid_gutter: 0;

$grid: (
    xs: (
        breakpoint: 300px,
        container: 288px
    ),
    sm: (
        breakpoint: 600px,
        container: 576px
    ),
    md: (
        breakpoint: 900px,
        container: 864px
    ),
    lg: (
        breakpoint: 1200px,
        container: 1152px
    ),
    xl: (
        breakpoint: 1500px,
        container: 1440px
    ),
    xxl: (
        breakpoint: 1800px,
        container: 1600px
    )
);

$xs-min: '(min-width: map-get(map-get(#{$grid}, xs), breakpoint) + 1)';
$sm-min: '(min-width: map-get(map-get(#{$grid}, sm), breakpoint) + 1)';
$md-min: '(min-width: map-get(map-get(#{$grid}, md), breakpoint) + 1)';
$lg-min: '(min-width: map-get(map-get(#{$grid}, lg), breakpoint) + 1)';
$xl-min: '(min-width: map-get(map-get(#{$grid}, xl), breakpoint) + 1)';
$xxl-min: '(min-width: map-get(map-get(#{$grid}, xxl), breakpoint) + 1)';

$xs-max: '(max-width: map-get(map-get(#{$grid}, xs), breakpoint))';
$sm-max: '(max-width: map-get(map-get(#{$grid}, sm), breakpoint))';
$md-max: '(max-width: map-get(map-get(#{$grid}, md), breakpoint))';
$lg-max: '(max-width: map-get(map-get(#{$grid}, lg), breakpoint))';
$xl-max: '(max-width: map-get(map-get(#{$grid}, xl), breakpoint))';
$xxl-max: '(max-width: map-get(map-get(#{$grid}, xxl), breakpoint))';

// Typi
$breakpoints: (
    xs: 300px,
    sm: 600px,
    md: 900px,
    lg: 1200px,
    xl: 1500px,
    xxl: 1800px
);

$typi: (
    null: ($base_font-size, $base_line-height),
    xs: ($base_font-size - 3px, $base_line-height),
    sm: ($base_font-size - 3px, $base_line-height),
    md: ($base_font-size - 2px, $base_line-height),
    lg: ($base_font-size - 2px, $base_line-height),
    xl: ($base_font-size - 1px, $base_line-height),
    xxl: ($base_font-size, $base_line-height)
);

// Buttons
$button_name: 'button';
$button_height: 46px;
$button_padding-right: 25px;
$button_padding-left: 25px;

// Forms
$form_background: transparent;
$form_background-hover: transparent;
$form_fieldset-padding-vertical: 0;
$form_fieldset-padding-horizontal: 0;
$form_input-height: 36px;
$form_input-padding-horizontal: 9px;
$form_input-background: $color-white;
$form_input-background-hover: $color-white;
$form_input-color: $color-gray;
$form_input-color-hover: $color-gray;
$form_textarea-min-height: $form_input-height;
$form_border-color: #c8c8c8;
$form_label-color: $color-dark-gray;
$form_fieldset-border-color: $color-light-gray;
$form_checkbox-background: $color-blue;
$form_checkbox-color: $color-blue;
$form_selectbox-color: $color-blue;
$form_selectbox-color-arrow: 'blue';

// Tables
$table_cell-padding-vertical: 4px;
$table_cell-padding-horizontal: 8px;
$table_border-radius: 2px;
$table_background-hover: darken($color-white, 4%);
$table_background-odd: darken($color-white, 2%);
$table_background-even: $color-white;
$table_border-color: $color-light-gray;
$table_thead-background: $color-light-gray;
$table_thead-color: $base_color;

// Alerts
$alert_margin-top: 10px;
$alert_margin-bottom: 10px;
$alert_padding-top: 10px;
$alert_padding-right: 45px;
$alert_padding-bottom: 10px;
$alert_padding-left: 15px;

// Z-index
$zindex_slideshow: 1;
$zindex_catalog-car: 4;
$zindex_catalog-car-hover: 5;
$zindex_detail-car-vendor: 6;
$zindex_slideshow-car-navigation: 7;
$zindex_header: 13;
$zindex_content: 14;
$zindex_form-message: 50;
$zindex_sticky: 1000;
$zindex_languages: 1001;
$zindex_elevator: 9998;
$zindex_modal: 9999;
$zindex_loader: 10000;

// Icons
$icons_width: 40px;
$icons_color: $color-white;
$icons_contact-color: $color-blue;

// Header
$header_height: 55px;

// Footer
$footer_background: $color-blue;
$footer_color-content: $color-white;
$footer_color-heading: $color-white;
$footer_color-copy: #4071a9;

// Content
$content_color-heading-2: $color-red;
$content_color-heading-3: $color-blue;

// Sections
$section_indentation: 80px;
$section_background: #13467d;

// Document sections
$document_grid-gutter: 10px;

// Navigation
$navigation_categories-height: 180px;
$navigation_item-background: #0d3767;
$navigation_item-background-active: $color-white;
$navigation_item-separator: #23568d;
$navigation_background: $color-blue;

// Languages
$lang-item_width: 70px;
$lang-item_background: #0d3767;
$lang-item_background-hover: $color-blue;

// Slideshow
$slideshow_image-width: 1920px;

// Catalog
$catalog_grid-gutter: 20px;
$catalog_car-width: 280px;
$catalog_car-height: 450px;
$catalog_car-color-heading: $color-blue;
$catalog_car-color-price: $color-blue;

// Detail
$detail_car-color-arrow: 'blue';
$detail_car-color-link: $color-blue;
$detail_car-slideshow-active: $color-red;

// Slider
$slider_background: $color-blue;

// Collapse
$collapse_color-arrow: 'blue';

// Search
$search_header-width: 280px;
$search_header-background: $color-red;

// Whisperer
$whisperer_background: $search_header-background;
$whisperer_color: $color-white;
$whisperer_item-width: 80px;
$whisperer_item-height: $header_height;

// Page Our team
$our-team_background: rgba(0,85,150,.9);
