// Catalog filter
.filter__container {
    width: 100%;
    top: $header_height;
    z-index: $zindex_sticky;
    background-color: $color-white;

    &[style*="fixed"] {
        box-shadow: 0 10px 20px rgba(0,0,0,.15);
    }
}

.filter__content {
    width: 100%;
    max-width: map-get(map-get($grid, xxl), container);
    margin: 0 auto;
    opacity: 0;
    will-change: opacity;
    animation: load 1s $ease-in-out-back;
    animation-fill-mode: forwards;

    .ie9 & {
        opacity: 1;
    }

    @media #{$xs-min} {
        padding: (($section_indentation / 4) + 46px) ($section_indentation / 4) 75px;

        &.-active {
            padding: ($section_indentation / 4);
        }
    }

    @media #{$sm-min} {
        padding-top: ($section_indentation / 4);
        padding-right: $section_indentation;
        padding-left: ($section_indentation / 2);
    }

    @media #{$md-min} {
        padding-left: $section_indentation;
    }
}
