// Catalog
.catalog {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background: $color-light-gray;

    @media #{$xs-min} {
        padding: $catalog_grid-gutter 1%;
    }

    @media #{$sm-min} {
        padding-top: 30px;
        padding-bottom: 60px;
    }

    @media #{$lg-min} {
        padding-top: 40px;
        padding-bottom: 80px;
    }

    @media #{$xl-min} {
        padding-top: 50px;
        padding-bottom: 100px;
    }

    &.-carousel {
        flex-flow: column wrap;
        align-items: center;

        @media #{$xs-min} {
            padding: 0;
        }

        h3 {
            text-align: center;
        }
    }

    .similar {

        @media #{$xs-min} {
            padding: $catalog_grid-gutter 0;
        }
    }

    .car {
        width: $catalog_car-width;
        height: $catalog_car-height;
        margin-bottom: $catalog_grid-gutter;
        padding: $catalog_grid-gutter ($catalog_grid-gutter / 2) 0;
        z-index: $zindex_catalog-car;
        opacity: 0;
        will-change: opacity, transform;
        animation: catalog 1s $ease-in-out-back;
        animation-fill-mode: forwards;
        transition: padding $base_transition-duration-in $base_transition-timing;

        &.-anchored {

            .car__content {

                &::before {
                    will-change: opacity;
                    animation: catalog-pulse 3s ease-in-out 1s;
                    animation-fill-mode: forwards;
                }
            }
        }

        @media #{$sm-min} {

            &:focus,
            &:hover {
                padding-top: 0;
                z-index: $zindex_catalog-car-hover;
                transition: padding $base_transition-duration-in $base_transition-timing;

                .car__heading {
                    height: 80px;
                    color: $catalog_car-color-heading;
                    transition: color $base_transition-duration-in $base_transition-timing, height $base_transition-duration-in $base_transition-timing;
                }

                .car__content {
                    width: calc(100% - 20px);
                    height: $catalog_car-height + 65px;
                    position: absolute;
                    transition: height $base_transition-duration-in $base_transition-timing;

                    &::before {
                        opacity: 1;
                        transition: opacity $base_transition-duration-in $base_transition-timing;
                    }

                    .button {
                        opacity: 1;
                        transition: opacity $base_transition-duration-in $base_transition-timing .2s;

                        &:focus,
                        &:hover {
                            transition: background-position $base_transition-duration-out $base_transition-timing;
                        }
                    }
                }

                .car__image {

                    img {
                        transform: scale(1.05,1.05);
                        transition: transform $base_transition-duration-in $base_transition-timing;
                    }
                }
            }

            &:nth-child(7) {animation-delay: .1s;}
            &:nth-child(6) {animation-delay: .15s;}
            &:nth-child(3) {animation-delay: .2s;}
            &:nth-child(4) {animation-delay: .25s;}
            &:nth-child(10) {animation-delay: .3s;}
            &:nth-child(8) {animation-delay: .35s;}
            &:nth-child(2) {animation-delay: .4s;}
            &:nth-child(9) {animation-delay: .45s;}
            &:nth-child(5) {animation-delay: .5s;}
        }

        @media #{$sm-max} {
            width: 100%;
            height: auto;
            padding-right: 20px;
            padding-left: 20px;
        }

        .ie9 & {
            opacity: 1;
        }
    }

    .car__content {
        width: 100%;
        height: $catalog_car-height;
        position: relative;
        background: $color-white;
        transition: height $base_transition-duration-in $base_transition-timing;
        cursor: pointer;

        &::before {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            content: '';
            transition: opacity $base_transition-duration-in $base_transition-timing;
            box-shadow: 0 0 30px rgba(0,0,0,.3);
        }

        .button {
            width: calc(100% - (2 * #{$catalog_grid-gutter}));
            max-width: 200px;
            margin-top: $catalog_grid-gutter;
            position: absolute;
            bottom: $catalog_grid-gutter;
            left: 50%;
            opacity: 0;
            line-height: $button_height;
            transform: translateX(-50%);
            transition: opacity ($base_transition-duration-in / 2) $base_transition-timing;

            @media #{$sm-max} {
                width: calc(100% - 20px);
                height: 42px;
                margin-top: 5px;
                margin-bottom: 10px;
                position: relative;
                bottom: 0;
                opacity: 1;
                font-size: em(11px);
                line-height: 41px;
            }
        }

        @media #{$sm-max} {
            height: auto;
            padding-bottom: 15px;
        }
    }

    .car__heading {
        width: 100%;
        height: 60px;
        padding: 0 10px 0 20px;
        display: flex;
        align-items: center;
        overflow: hidden;
        line-height: 1.2;
        font-weight: bold;
        transition: color $base_transition-duration-in $base_transition-timing, height $base_transition-duration-in $base_transition-timing;

        @media #{$sm-max} {
            padding: 0 5px 0 10px;
        }
    }

    .car__brand-logo {
        max-width: 24px;
        max-height: 24px;
        margin-right: 20px;

        @media #{$sm-max} {
            margin-right: 10px;
        }
    }

    .car__image {
        @extend %loader-image !optional;
        margin-bottom: $catalog_grid-gutter;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 25%;
        text-align: center;

        a {
            width: 100%;
            height: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border: none;

            @media #{$sm-max} {
                height: auto;
            }
        }

        img {
            max-width: 100%;
            max-height: 170px;
            transform: scale(1,1);
            transition: transform $base_transition-duration-in $base_transition-timing;

            @media #{$sm-max} {
                max-height: none;
            }
        }

        @media #{$sm-max} {
            margin-bottom: 15px;
        }
    }

    .car__description {
        width: 100%;
        margin-bottom: $catalog_grid-gutter;

        @media #{$sm-max} {
            margin-bottom: 10px;
        }
    }

    .car__description-title {
        padding-right: 5px;
        color: $color-gray;
        text-align: right;
    }

    .car__description-value {
        padding-left: 5px;
        color: $color-black;
    }

    .car__price {
        margin-bottom: 5px;
        color: $catalog_car-color-price;
        font-size: 24px;
        line-height: 1;
        font-weight: bold;
        text-align: center;

        @media #{$sm-max} {
            margin-bottom: 0;
            font-size: 22px;
        }
    }

    .car__price-info {
        margin-bottom: 10px;
        color: $color-gray;
        font-size: .9em;
        text-align: center;
    }
}
