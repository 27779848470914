// Car detail
.detail {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background: $color-light-gray;

    @media #{$sm-min} {
        padding-top: 0;
    }

    .car {

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-left: 0;
        }
    }

    .car__heading {
        padding: 22px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        background-color: #2a2829;
        color: $color-white;
        font-size: em(20px);
        line-height: 1.2;
        font-weight: bold;

        @media #{$xs-max} {
            height: 70px;
            font-size: em(16px);
        }

        @media #{$xs-min} {
            flex-direction: column;
        }

        @media #{$sm-min} {
            padding-right: 20px;
            padding-left: 20px;
        }

        @media #{$md-min} {
            padding-right: 40px;
            padding-left: 40px;
            flex-direction: row;
        }

        @media #{$lg-min} {
            padding-right: 50px;
            padding-left: 50px;
        }
    }

    .car__heading-text {
        width: 100%;

        @media #{$xs-min} {
            margin-bottom: 20px;
            font-size: 14px;
        }

        @media #{$md-min} {
            max-width: 50%;
            margin-bottom: 0;
            font-size: 18px;
        }
    }

    .car__heading-options {
        display: flex;
        align-items: center;

        @media #{$xs-min} {
            align-self: flex-end;
        }

        @media #{$md-min} {
            align-self: center;
        }
    }

    .car__heading-counter {
        min-height: $form_input-height;
        padding: 5px 10px;
        display: inline-block;
        background: $color-red;

        strong {
            margin-left: 5px;
        }

        @media #{$xs-min} {
            font-size: 12px;

            strong {
                font-size: 16px;
            }
        }

        @media #{$md-min} {
            font-size: 14px;

            strong {
                font-size: 20px;
            }
        }
    }

    .car__backlink {
        @include shape-times(2px, 32px, $color-white);
        position: relative;
        right: -12px;
        color: $color-white;
        font-size: 180%;
        border-bottom: none;
    }

    .car__backlink-sign {
        @include hide-text;
        width: $button_height;
        height: $button_height;
        display: block;
        transform: rotate(0);
        transition: transform .3s $base_transition-timing;

        &:focus,
        &:hover {
            transform: rotate(90deg);
            transition: transform .3s $base_transition-timing;
        }
    }

    .car__section {
        background-color: $color-white;
        border-top: 10px solid $color-light-gray;

        //@media #{$xs-min} {padding: 10px;}
        @media #{$xs-min} {
            padding: 20px 20px 0;
        }

        @media #{$md-min} {
            padding: 30px 40px 10px;

            &:nth-child(2) {
                border-left: 10px solid $color-light-gray;
            }
        }

        @media #{$lg-min} {
            padding: 40px 50px 20px;
        }

        @media #{$xl-min} {

            + .car__section {border-left: 10px solid $color-light-gray;}
        }

        &.-no-bordered {
            border-left: none !important;
        }

        &.-direction-middle {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        > .row {

            + .row {
                margin-bottom: 20px;
            }
        }

        p {
            margin-bottom: 20px;
        }

        .-bordered-top {
            padding-top: 20px;
            position: relative;

            &::before {
                height: 1px;
                position: absolute;
                top: 0;
                content: '';
                background-color: $color-light-gray;

                @media #{$xs-min} {
                    width: calc(100% + 20px);
                    left: -10px;
                }

                @media #{$sm-min} {
                    width: calc(100% + 50px);
                    left: -25px;
                }

                @media #{$md-min} {
                    width: calc(100% + 100px);
                    left: -50px;
                }
            }
        }
    }

    .car__description {
        line-height: 20px;

        h3 {
            @extend .heading__3;
        }
    }

    .car__description-title {
        padding-right: 10px;
        color: $color-gray;

        @media #{$xs-min} {
            font-size: 11px;
        }

        @media #{$sm-min} {
            font-size: 12px;
        }

        @media #{$lg-min} {
            font-size: 14px;
        }
    }

    .car__description-value {
        padding-left: 10px;

        @media #{$xs-min} {
            font-size: 11px;
        }

        @media #{$sm-min} {
            font-size: 12px;
        }

        @media #{$lg-min} {
            font-size: 14px;
        }
    }

    .car__price {
        height: 70px;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-red;
        color: $color-white;
        text-align: center;
    }

    .car__price-content {
        font-size: em(14px);
        line-height: 1.6em;

        strong {
            margin-right: 12px;
            margin-left: 12px;
            white-space: nowrap;
            font-size: em(28px);
        }
    }

    .car__link {
        @extend %image_arrow-right-#{$detail_car-color-arrow};
        background-repeat: no-repeat;
        color: $detail_car-color-link;
    }

    .car__vendor {
        margin-bottom: 20px;
        padding-top: 10px;
        font-size: em(15px);
        text-align: center;

        p {
            margin-bottom: 10px;

            strong {
                font-size: em(21px);
            }

        }

        .car__vendor-phone {
            font-size: em(18px);
            line-height: 1.4;
        }
    }

    .car__vendor-image {
        width: 110px;
        height: 110px;
        margin: 0 auto 20px;

        img {
            width: 100%;
            max-width: 110px;
            border-radius: 50%;
        }

        @media #{$sm-min} {
            float: left;
        }
    }

    .car__vendor-data {

        @media #{$sm-min} {
            margin-left: 130px;
            text-align: left;
        }
    }

    .car__vendor-operative-times {
        padding-top: 15px;
        text-align: left;
    }

    .car__vendor-location {
        min-width: 70px;
        padding: 10px;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: $zindex_detail-car-vendor;
        background-color: rgba(0,0,0,.4);
        color: $color-white;
        border-radius: 2px;

        &:empty {
            display: none;
        }
    }

    .car__claim {
        padding: 20px;
        display: block;
        text-align: center;
        font-style: italic;
    }

}

.car__backlink-bottom {
    padding: 30px 0;
    background: $base_background;
    text-align: center;
}

// Accesories list
.accessories__list {
    color: $color-gray;

    li {
        margin-bottom: 8px;

        @media #{$xl-max} {
            display: inline-block;

            &::before {
                content: ' ~ ';
                color: $color-black;
            }
        }
    }
}

// Similar cars list
.similar {
    position: relative;

    @media #{$xs-min} {
        width: 100%;
    }

    @media #{$sm-min} {
        width: $catalog_car-width * 2;
    }

    @media #{$md-min} {
        width: $catalog_car-width * 3;
    }

    @media #{$lg-min} {
        width: $catalog_car-width * 4;
    }

    @media #{$xl-min} {
        width: $catalog_car-width * 5;
    }

    @media #{$xxl-min} {
        width: $catalog_car-width * 6;
    }
}

.similar__content {
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 100px;
    position: relative;
    overflow: hidden;

    li {
        position: relative;
    }
}

.similar__list {
    display: inline-flex;
    vertical-align: top;

    @media #{$sm-max} {
        display: inline-block;
    }
}
