@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?7d1jtb');
    src: url('../fonts/icomoon.eot?7d1jtb#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?7d1jtb') format('truetype'),
    url('../fonts/icomoon.woff?7d1jtb') format('woff'),
    url('../fonts/icomoon.svg?7d1jtb#icomoon') format('svg');
}

[class^='v-icon-'],
[class*=' v-icon-'],
%icomoon {
    color: $icons_color;
    font-family: 'icomoon' !important;
    speak: none;
    line-height: 1;
    font-weight: normal;
    font-variant: normal;
    font-style: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .page__kontakt .-section & {
        color: $icons_contact-color;
    }
}

[class^='v-icon-'],
[class*=' v-icon-'] {
    font-size: 30px;
}

.v-icon-search {
    color: $color-white;

    &::before {
        content: '\e900';
    }
}

.v-icon-envelope {
    color: $color-red;
    font-size: 26px;

    &::before {
        content: '\e901';
    }
}

.v-icon-pencil::before {
    content: '\e902';
}

.v-icon-phone::before {
    content: '\e903';
}

.v-icon-clock::before {
    content: '\e904';
}

.v-icon-check::before {
    content: '\e905';
}

.v-icon-location::before {
    content: '\e906';
}

.v-icon-pin::before {
    content: '\e907';
}

.v-icon-arrow-up::before {
    content: '\e908';
}

.v-icon-arrow-right::before {
    content: '\e909';
}

.v-icon-arrow-left::before {
    content: '\e90a';
}

.v-icon-arrow-down::before {
    content: '\e90b';
}

.v-icon-arrow-up-red::before {
    @extend .v-icon-arrow-up::before;
    color: $color-red;
}

.v-icon-arrow-up-white::before {
    @extend .v-icon-arrow-up::before;
    color: $color-white;
}

.v-icon-arrow-up-blue::before {
    @extend .v-icon-arrow-up::before;
    color: $color-blue;
}

.v-icon-arrow-right-red::before {
    @extend .v-icon-arrow-right::before;
    color: $color-red;
}

.v-icon-arrow-right-white::before {
    @extend .v-icon-arrow-right::before;
    color: $color-white;
}

.v-icon-arrow-right-blue::before {
    @extend .v-icon-arrow-right::before;
    color: $color-blue;
}

.v-icon-arrow-left-red::before {
    @extend .v-icon-arrow-left::before;
    color: $color-red;
}

.v-icon-arrow-left-white::before {
    @extend .v-icon-arrow-left::before;
    color: $color-white;
}

.v-icon-arrow-left-blue::before {
    @extend .v-icon-arrow-left::before;
    color: $color-blue;
}

.v-icon-arrow-down-red::before {
    @extend .v-icon-arrow-down::before;
    color: $color-red;
}

.v-icon-arrow-down-white::before {
    @extend .v-icon-arrow-down::before;
    color: $color-white;
}

.v-icon-arrow-down-blue::before {
    @extend .v-icon-arrow-down::before;
    color: $color-blue;
}

.icon__container {
    padding-left: $icons_width;
    position: relative;

    .v-icon {
        width: 30px;
        position: absolute;
        top: -4px;
        left: 0;
        text-align: center;
    }
}

.icon {
    @extend %sprite-image;
    @include hide-text;
    display: inline-block;
    line-height: 1;
    border: none;

    .icon__container & {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    &.-triangle-right-white {@extend %triangle-right-white;}
    &.-triangle-left-white {@extend %triangle-left-white;}
}
