// Form
//
//      file: _form.scss
//
// Form styles
//
//
// styleguide Form
form {

    fieldset {
        border: none;
    }

    .row {
        align-items: flex-end;
    }

    .required {
        @extend sup;
        position: absolute;
        left: -10px;
        color: $color-red;
    }

    [class*='#{$grid_column-name}-'] {
        padding-right: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
}

.form {
    padding: 4% 2% 2%;
    background-color: #faf8f9;
    border: 3px solid $color-light-gray;
}

.form__item {
    margin-top: ($button_height - $form_input-height) / 2;
    margin-bottom: ($button_height - $form_input-height) / 2;
    border-radius: 2px;

    &.-error {
        box-shadow: 0 0 6px rgba(212,22,28,.2);
        border-color: rgba(212,22,28,.4);
    }

    &.-valid {

        &,
        &:focus {
            border-color: rgba(26,173,18,.4);
        }
    }

    &.form__image {
        height: auto;
        padding: 0;
        background: none;
        border: none;
    }

    .calculator-container & {
        color: $color-dark-gray;
        font-weight: bold;
        text-align: center;
    }

    .slider__input & {
        max-width: 70%;
        display: inline-block;
    }
}

.form__label {
    position: relative;
    font-size: em(13px);
    line-height: 20px;
    text-transform: uppercase;

    .slider__input & {
        display: inline-block;
        font-weight: bold;
    }
}

.form__item-label {
    color: $color-dark-gray;
    font-size: em(13px);
    font-weight: bold;
    text-transform: uppercase;
}

.form__item-message {
    width: calc(100% - 30px);
    padding: 5px 10px;
    position: absolute;
    top: auto;
    left: 15px;
    z-index: $zindex_form-message;
    display: inline-block;
    opacity: .75;
    color: $color-white;
    font-size: em(12px);
    line-height: 1.4;
    transition: opacity $base_transition-duration-in $base_transition-timing;
    border-radius: 2px;

    &:focus,
    &:hover {
        opacity: 1;
        transition: opacity $base_transition-duration-in $base_transition-timing;
    }

    &.-success {background-color: $color-green;}
    &.-info {background-color: $color-blue;}
    &.-warning {background-color: $color-yellow;}
    &.-danger {background-color: $color-red;}
}

// Checkbox
//
//      file: _form.scss
//
// Checkbox styles
// Styles for checkboxes looks like button and their group
// Used classes:
// **`checkbox`**
//
// **`checkbox__label`**
//
// **`checkbox__content`** - class for styling to looks like button
//
// ** `checkbox__group`** - class for styling group of checkboxes
//
// Markup:
//  <div class="checkbox__content">
//    <input name="checkbox_single" id="checkbox_single" type="checkbox" class="checkbox">
//    <label class="checkbox__label" for="checkbox_single">Checkbox single</label>
//  </div>
//  <div class="checkbox__group">
//    <div class="checkbox__content">
//      <input name="checkbox_1" id="checkbox_1" type="checkbox" class="checkbox">
//      <label class="checkbox__label" for="checkbox_1">Checkbox 1</label>
//    </div>
//    <div class="checkbox__content">
//      <input name="checkbox_2" id="checkbox_2" type="checkbox" class="checkbox">
//      <label class="checkbox__label" for="checkbox_2">Checkbox 2</label>
//    </div>
//  </div>
//
// styleguide Form.checkbox
.checkbox {

    .checkbox__content & {
        display: none;
        text-align: center;

        &:checked {

            ~ .checkbox__label {
                background-color: $form_checkbox-background;
                color: $color-white;
                transition: background-color $base_transition-duration-in $base_transition-timing, color $base_transition-duration-in $base_transition-timing;
            }
        }
    }
}

.checkbox__label {

    .checkbox__content & {
        @extend .form__item;
        padding: 6px $form_input-padding-vertical;
        background-color: transparent;
        color: $form_checkbox-color;
        text-transform: uppercase;
        transition: background-color $base_transition-duration-in $base_transition-timing, color $base_transition-duration-in $base_transition-timing, border-color $base_transition-duration-in $base_transition-timing;

        &:focus,
        &:hover {
            background-color: $form_checkbox-background;
            color: $color-white;
            cursor: pointer;
            transition: background-color $base_transition-duration-in $base_transition-timing, color $base_transition-duration-in $base_transition-timing, border-color $base_transition-duration-in $base_transition-timing;
        }
    }
}

.checkbox__group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media #{$xs-min} {
        flex-direction: column;
    }

    @media #{$sm-min} {
        flex-direction: row;
    }

    &.-centered {
        justify-content: center;
    }

    .checkbox__content {

        &:first-child {

            .checkbox__label {
                border-bottom-left-radius: 2px;
                border-top-left-radius: 2px;
            }
        }

        &:last-child {

            .checkbox__label {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
            }
        }

        + .checkbox__content {
            margin-left: -1px;
        }

        .checkbox__label {
            border-radius: 0;
        }
    }
}

// Selectbox
//
//      file: _form.scss
//
// Selectbox styles
// Styles for seletbox to looks better
//
// styleguide Form.selectbox
.selectbox__content {
    position: relative;
    text-transform: uppercase;

    &::before {
        @extend %icomoon, .v-icon-arrow-down-#{$form_selectbox-color-arrow}::before;
        width: 40px;
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        content: '';
        background: #fff;
        font-size: 30px;
        line-height: 35px !important;
        pointer-events: none;


    }

    select {
        @extend .form__item;
        width: 100%;
        height: 36px;
        margin: 0;
        padding: 7px 15px;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        appearance: none;
        color: $form_selectbox-color;
        font-weight: bold;
        text-transform: uppercase;
        box-shadow: none;

        /* Since we removed the default focus styles, we have to add our own */
        &:focus {
            outline: none;
            border-color: $color-blue;
        }
    }

    select::-ms-expand {
        display: none; /* remove ms arrow in IE */
    }

    .selectbox__label {
        display: none;
    }
}

/* for Webkit's CSS-only solution */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .selectbox__content select {
        padding-right: 25px;
    }
}

.no-pointer-events .selectbox__content {
    select {
        -webkit-appearance: menulist-button;
    }

    &::after {
        content: none;
    }

    select::-ms-expand {
        display: inline-block;
    }
}
