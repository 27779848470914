.reference__single-item {
    margin: vr(.5) 0;
    padding: 20px 0;

    .reference__single-item-image {
        @extend %loader-image !optional;
        width: 140px;
        height: 140px;
        margin: 0 auto 15px;
        background-color: $color-light-gray;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        text-align: center;
        border-radius: 50%;
    }

    .reference__single-item-text {
        font-size: em(12px);
        font-style: italic;
    }

    .reference__single-item-referer,
    .reference__single-item-company {
        margin-bottom: 0;
        font-size: em(13px);
    }
}
