// Base grid
//
// Grid system. It's possible use percentage grid system or flex system.
//
// Grid is defined by classes: **`$grid_row-name`** and **`$grid_column-name`**.
//
// Default class: **`.row`** for rows and **`.col`** for columns.
//
// Grid system is **`mobile first`** (@media (min-width)).
//
// .-no-gutter  - odstraneni mezer
//
// Markup:
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-12"><div class="kss-grid-helper">Col 12</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-11"><div class="kss-grid-helper">11</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">1</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-10"><div class="kss-grid-helper">10</div></div>
//      <div class="col-xs-2"><div class="kss-grid-helper">2</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-9"><div class="kss-grid-helper">9</div></div>
//      <div class="col-xs-3"><div class="kss-grid-helper">3</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-8"><div class="kss-grid-helper">8</div></div>
//      <div class="col-xs-4"><div class="kss-grid-helper">4</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-7"><div class="kss-grid-helper">7</div></div>
//      <div class="col-xs-5"><div class="kss-grid-helper">5</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-6"><div class="kss-grid-helper">6</div></div>
//      <div class="col-xs-6"><div class="kss-grid-helper">6</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-5"><div class="kss-grid-helper">5</div></div>
//      <div class="col-xs-7"><div class="kss-grid-helper">7</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-4"><div class="kss-grid-helper">4</div></div>
//      <div class="col-xs-8"><div class="kss-grid-helper">8</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-3"><div class="kss-grid-helper">3</div></div>
//      <div class="col-xs-9"><div class="kss-grid-helper">9</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-2"><div class="kss-grid-helper">2</div></div>
//      <div class="col-xs-10"><div class="kss-grid-helper">10</div></div>
//  </div>
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-1"><div class="kss-grid-helper">1</div></div>
//      <div class="col-xs-11"><div class="kss-grid-helper">11</div></div>
//  </div>
//
// styleguide 3.1.1

// Media grid
//
// Using block sizes for different devices based on defined breakpoints (media queries).
//
// Markup:
//  <div class="row">
//      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-6">
//          <div class="kss-grid-helper">Col xl-6 | lg-8 | md-10 | sm-12</div>
//      </div>
//  </div>
//  <div class="row">
//      <div class="col-md-6 col-lg-4 col-xl-4">
//          <div class="kss-grid-helper">Col md-6 | lg-4 | xl-4</div>
//      </div>
//      <div class="col-md-6 col-lg-6 col-lg-offset-2 col-xl-4 col-xl-offset-4">
//          <div class="kss-grid-helper">Col md-6 | lg-6 lg-offset-2 | xl-4 xl-offset-4</div>
//      </div>
//  </div>
//
// <p class="ciu_embed" data-feature="css-mediaqueries" data-periods="future_1,current,past_1,past_2,past_3">
//     <a href="http://caniuse.com/#feat=css-mediaqueries">Can I Use css-mediaqueries?</a> Data on support for the css-mediaqueries feature across the major browsers from caniuse.com.
// </p>
//
// styleguide 3.1.2

// Offset grid
//
// Markup:
//  <div class="row">
//      <div class="col-xs-4 col-xs-offset-4"><div class="kss-grid-helper">Col 4 Offset 4</div></div>
//  </div>
//  <div class="row">
//      <div class="col-xs-4"><div class="kss-grid-helper">Col 4</div></div>
//      <div class="col-xs-4 col-xs-offset-2"><div class="kss-grid-helper">Col 4 Offset 2</div></div>
//  </div>
//  <div class="row">
//      <div class="col-xs-2"><div class="kss-grid-helper">Col 2</div></div>
//      <div class="col-xs-2 col-xs-offset-2"><div class="kss-grid-helper">Col 2 Offset 2</div></div>
//      <div class="col-xs-2 col-xs-offset-2"><div class="kss-grid-helper">Col 2 Offset 2</div></div>
//  </div>
//
// styleguide 3.1.3

// Push | pull
//
// Markup:
//  <div class="row">
//      <div class="col-xs-4 col-xs-push-4"><div class="kss-grid-helper">Col 4 Push 4</div></div>
//  </div>
//  <div class="row">
//      <div class="col-xs-4 col-xs-push-7"><div class="kss-grid-helper">Col 4 Push 7</div></div>
//      <div class="col-xs-4 col-xs-pull-3"><div class="kss-grid-helper">Col 4 Pull 3</div></div>
//  </div>
//  <div class="row">
//      <div class="col-xs-2"><div class="kss-grid-helper">Col 2</div></div>
//      <div class="col-xs-2 col-lg-push-5"><div class="kss-grid-helper">Col 2 Push 5</div></div>
//      <div class="col-xs-2 col-md-push-8"><div class="kss-grid-helper">Col 2 Push 8</div></div>
//  </div>
//
// styleguide 3.1.4

// Nested grid
//
// Markup:
//  <div class="row">
//      <div class="col-xs-10 col-xs-offset-1">
//          <div class="kss-grid-helper">
//              Col 10 Offset 1
//              <div class="row">
//                  <div class="col-xs-6">
//                      <div class="kss-grid-helper">
//                          Col 6
//                          <div class="row">
//                              <div class="col-xs-9"><div class="kss-grid-helper">Col 9</div></div>
//                              <div class="col-xs-3"><div class="kss-grid-helper">Col 3</div></div>
//                          </div>
//                      </div>
//                  </div>
//                  <div class="col-xs-6"><div class="kss-grid-helper">Col 6</div></div>
//              </div>
//          </div>
//      </div>
//  </div>
//
// styleguide 3.1.5

// Flex base grid
//
// Writing is the same as the percentage grid system.
//
// Markup:
//  <div class="row">
//      <div class="col-xs-12"><div class="kss-grid-helper">Col 12</div></div>
//  </div>
//  <div class="row">
//      <div class="col-xs-6"><div class="kss-grid-helper">Col 6</div></div>
//      <div class="col-xs-6"><div class="kss-grid-helper">Col 6</div></div>
//  </div>
//  <div class="row">
//      <div class="col-xs-4"><div class="kss-grid-helper">Col 4</div></div>
//      <div class="col-xs-4"><div class="kss-grid-helper">Col 4</div></div>
//      <div class="col-xs-4"><div class="kss-grid-helper">Col 4</div></div>
//  </div>
//  <div class="row">
//      <div class="col-xs-2"><div class="kss-grid-helper">Col 2</div></div>
//      <div class="col-xs-2"><div class="kss-grid-helper">Col 2</div></div>
//      <div class="col-xs-2"><div class="kss-grid-helper">Col 2</div></div>
//      <div class="col-xs-2"><div class="kss-grid-helper">Col 2</div></div>
//      <div class="col-xs-2"><div class="kss-grid-helper">Col 2</div></div>
//      <div class="col-xs-2"><div class="kss-grid-helper">Col 2</div></div>
//  </div>
//  <div class="row">
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//      <div class="col-xs-1"><div class="kss-grid-helper">Col 1</div></div>
//  </div>
//
// styleguide 3.2.1

// Automatic flex grid
//
// Markup:
//  <div class="row">
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//  </div>
//  <div class="row">
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//  </div>
//  <div class="row">
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//  </div>
//  <div class="row">
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//      <div class="col col-xs-offset-2"><div class="kss-grid-helper">Col</div></div>
//  </div>
//  <div class="row">
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//      <div class="col col-xs-offset-2"><div class="kss-grid-helper">Col</div></div>
//      <div class="col"><div class="kss-grid-helper">Col</div></div>
//      <div class="col col-xs-offset-1"><div class="kss-grid-helper">Col</div></div>
//  </div>
//
// styleguide 3.2.2

// Flex grid items options
//
// .flex-items-center - align content center (horizontally)
// .flex-items-right - align content right (horizontally)
// .flex-items-top - align items top (vertically)
// .flex-items-middle - align items middle (vertically)
// .flex-items-bottom - align items bottom (vertically)
// .flex-items-stretch - align items stretch
// .flex-reverse - reverse content direction
// .flex-column - column content direction
// .flex-column-reverse - reverse column content direction
// .flex-equal-height - equal height columns
//
// Markup:
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-2"><div class="kss-grid-helper"><strong>1.</strong> Ribeye shankle drumstick ham hock t-bone turkey.</div></div>
//      <div class="col-xs-4"><div class="kss-grid-helper"><strong>2.</strong> Turkey ribeye prosciutto, drumstick ham pork shoulder porchetta shankle tongue kielbasa hamburger alcatra meatloaf tail. Pork chop meatball tail, flank jowl picanha salami turducken meatloaf ribeye bacon shankle capicola. Ball tip filet mignon flank leberkas. Porchetta drumstick pork chop cow pastrami tenderloin strip steak.</div></div>
//      <div class="col-xs-3"><div class="kss-grid-helper"><strong>3.</strong> Alcatra short ribs shank pork chop corned beef.</div></div>
//  </div>
//
// styleguide 3.2.3

// Flex grid content options
//
// .flex-content-top - align content top (vertically)
// .flex-content-middle - align content middle (vertically)
// .flex-content-bottom - align content bottom (vertically)
// .flex-content-stretch - (default) align content stretch
// .flex-content-space-between - content align with space between
// .flex-content-space-around - content align with same space around blocks
//
// Markup:
//  <div class="kss-flex-helper">
//      <div class="row -no-gutter -items-center {{modifier_class}}">
//          <div class="col-xs-3"><div class="kss-grid-helper no-radius">Col</div></div>
//          <div class="col-xs-6"><div class="kss-grid-helper no-radius">Col</div></div>
//          <div class="col-xs-4"><div class="kss-grid-helper no-radius">Col</div></div>
//          <div class="col-xs-5"><div class="kss-grid-helper no-radius">Col</div></div>
//          <div class="col-xs-7"><div class="kss-grid-helper no-radius">Col</div></div>
//          <div class="col-xs-2"><div class="kss-grid-helper no-radius">Col</div></div>
//          <div class="col-xs-5"><div class="kss-grid-helper no-radius">Col</div></div>
//          <div class="col-xs-4"><div class="kss-grid-helper no-radius">Col</div></div>
//          <div class="col-xs-9"><div class="kss-grid-helper no-radius">Col</div></div>
//      </div>
//  </div>
//
// styleguide 3.2.4

// Flex grid space options
//
// .flex-items-space-between - items align with space between
// .flex-items-space-around - items align with same space around blocks
//
// Markup:
//  <div class="row {{modifier_class}}">
//      <div class="col-xs-2"><div class="kss-grid-helper">Col</div></div>
//      <div class="col-xs-2"><div class="kss-grid-helper">Col</div></div>
//      <div class="col-xs-2"><div class="kss-grid-helper">Col</div></div>
//  </div>
//
// styleguide 3.2.5

@mixin grid() {

    @if $grid_trigger == true {
        $gutter: true;

        @if $grid_gutter < 1 {
            $gutter: false;
        }

        .content-container {
            @include clearfix;
            margin-right: auto;
            margin-left: auto;

            @if $gutter {
                padding-right: $grid_gutter;
                padding-left: $grid_gutter;
            }
        }

        .#{$grid_row-name} {
            @if $gutter {
                margin-right: floor($grid_gutter / -2);
                margin-left: ceil($grid_gutter / -2);
                padding-top: $grid_gutter;
            }

            @if $flex_trigger == true {
                display: flex;
                flex-flow: row wrap;
            } @else {
                @include clearfix;
            }

            &:first-child {padding-top: 0;}

            &.-no-gutter {
                margin-right: 0;
                margin-left: 0;
                padding-top: 0;

                [class*='#{$grid_column-name}-'] {
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            @if $flex_trigger == true {

                .flex-items-center {justify-content: center;}
                .flex-items-right {
                    margin-left: auto;
                    justify-content: flex-end;
                    align-self: flex-end;
                }

                .flex-items-top {align-items: flex-start;}
                .flex-items-middle {align-items: center;}
                .flex-items-bottom {align-items: flex-end;}
                .flex-items-stretch {align-items: stretch;}
                .flex-items-space-between {justify-content: space-between;}
                .flex-items-space-around {justify-content: space-around;}

                .flex-content-top {align-content: flex-start;}
                .flex-content-middle {align-content: center;}
                .flex-content-bottom {align-content: flex-end;}
                .flex-content-stretch {align-content: stretch;}
                .flex-content-space-between {align-content: space-between;}
                .flex-content-space-around {align-content: space-around;}

                .flex-row {flex-direction: row;}
                .flex-row-reverse {flex-direction: row-reverse;}

                .flex-column {
                    flex-direction: column;

                    [class*='#{$grid_column-name}-'] {
                        @if $gutter {
                        padding-top: $grid_gutter;
                        }
                        flex-basis: auto;

                        &:first-child {padding-top: 0;}
                    }
                }

                .flex-column-reverse {
                    flex-direction: column-reverse;

                    [class*='#{$grid_column-name}-'] {
                        @if $gutter {
                        padding-bottom: $grid_gutter;
                        }

                        &:first-child {padding-bottom: 0;}
                    }
                }

                .flex-equal-height {

                    [class*='#{$grid_column-name}-'] {display: flex;}
                }
            }
        }

        [class*='#{$grid_column-name}-'] {
            min-height: 1px;
            @if $gutter {
                padding-right: floor($grid_gutter / 2);
                padding-left: ceil($grid_gutter / 2);
            }
            position: relative;
            @if $flex_trigger == true {flex: 0 0 auto;}
        }

        @if $flex_trigger == true {
            .#{$grid_column-name} {
                flex: 1 1 0;

                .flex-top {align-self: flex-start;}
                .flex-middle {align-self: center;}
                .flex-bottom {align-self: flex-end;}

                .flex-first {order: -1;}
                .flex-last {order: 1;}
            }
        }

        $i: 0;
        @each $key, $value in $grid {
            $i: $i + 1;
            $min-width: map-get($value, breakpoint) + (2 * $grid_gutter);
            $max-width: 0;

            @if $i != length($grid) {
                $max-width: map-get(map-get-next($grid, $key), breakpoint) + (2 * $grid_gutter) - 1;
            }

            @media (min-width: $min-width) {
                @include make-column($key);

                .content-container {
                    @if map-has-key($value, container) {
                        width: map-get($value, container);
                    } @else {
                        width: 100%;
                    }
                }

                .#{$grid_column-name}-#{$key}-visible {display: block;}
                .#{$grid_column-name}-#{$key}-hidden {display: none;}
            }

            @for $index from 1 through $grid_columns {

                @if $min-width < $max-width {
                    @media (min-width: $min-width) and (max-width: $max-width) {

                        .#{$grid_column-name}-#{$key}-push-#{$index} {left: percentage($index / $grid_columns);}
                        .#{$grid_column-name}-#{$key}-pull-#{$index} {right: percentage($index / $grid_columns);}
                    }
                } @else {

                    @media (min-width: $min-width) {

                        .#{$grid_column-name}-#{$key}-push-#{$index} {left: percentage($index / $grid_columns);}
                        .#{$grid_column-name}-#{$key}-pull-#{$index} {right: percentage($index / $grid_columns);}
                    }
                }
            }
        }
    }
}

@mixin make-column($class) {

    @for $index from 1 through $grid_columns {

        @if $flex_trigger == true {
            .#{$grid_column-name}-#{$class}-#{$index} {
                max-width: percentage($index / $grid_columns);
                flex-basis: percentage($index / $grid_columns);
            }
        } @else {
            .#{$grid_column-name}-#{$class}-#{$index} {
                width: percentage($index / $grid_columns);
                float: left;
            }
        }

        .#{$grid_column-name}-#{$class}-offset-#{$index} {margin-left: percentage($index / $grid_columns);}
    }
}

// Media min-width mixin
@mixin min-width($class) {
    @media (min-width: map-get(map-get($grid, #{$class}), container)) {
        @content;
    }
}

// Media max-width mixin
@mixin max-width($class) {
    @media (max-width: map-get(map-get($grid, #{$class}), container) - 1) {
        @content;
    }
}

@include grid;
